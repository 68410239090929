import { nanoid } from "nanoid";
import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, FormControl, Grid, IconButton, InputLabel, ListItemText, MenuItem, Select, SelectChangeEvent, TextField, Checkbox} from "@mui/material";
import { RemoveCircle } from '@mui/icons-material';
import { Action, Actions, AccessNetwork, WanProfile, TrafficPolicy, ActionType, SelectedDeviceType, DevicesAction } from "../types";
import { AddCircle } from "@material-ui/icons";
import _ from "lodash";
import "./index.css"

interface GeoConfigActionsProps {
    accessNetworks?: AccessNetwork[];
    wanProfiles?: WanProfile[];
    trafficPolicies?: TrafficPolicy[];
    value: Action[];
    readOnly?: boolean;
    onChange?: (actions: Action[]) => void;
    isDefaultAction?: boolean;
    selectedDevices: SelectedDeviceType[];
}

function GeoConfigActions(props: GeoConfigActionsProps) {

    const { accessNetworks, wanProfiles, trafficPolicies, value, readOnly, onChange, isDefaultAction, selectedDevices} = props;
    const [geoConfigActions, setGeoConfigActions] = useState<Action[]>([])

    useEffect(()=>{
        if(!_.isEmpty(value)){
            let geoActions: Action[] = value
           for(var i=0;i<geoActions.length;i++){
               if(geoActions[i].action_type == 'assignTrafficPolicy'){
                    let devices = geoActions[i].devices || [];
                    for(var j=0;j<devices?.length;j++){
                       
                        devices[j].traffic_policy = {
                            id: devices[j].traffic_policy?.id || "",
                            name: devices[j].traffic_policy?.name,
                            access_networks: devices[j].traffic_policy?.access_networks,
                        }
                    }

                    geoActions[i].devices = devices
                    geoActions[i].id = geoActions[i].id
                    geoActions[i].geo_codes = geoActions[i].geo_codes
                    geoActions[i].configuration_type = geoActions[i].configuration_type
                    geoActions[i].action_type = geoActions[i].action_type

                } else if(geoActions[i].action_type == 'assignWanProfile'){
                    let devices = geoActions[i].devices || [];
                    for(var j=0;j<devices?.length;j++){

                        devices[j].wan_profile = {
                            id: devices[j].wan_profile?.id || "",
                            name: devices[j].wan_profile?.name,
                            access_networks: devices[j].wan_profile?.access_networks,
                        }
                    }

                    geoActions[i].devices = devices
                    geoActions[i].id = geoActions[i].id
                    geoActions[i].geo_codes = geoActions[i].geo_codes
                    geoActions[i].configuration_type = geoActions[i].configuration_type
                    geoActions[i].action_type = geoActions[i].action_type 
                }
           }
           setGeoConfigActions([...geoActions])    
        }
    }, [value])

    const addGeoConfigAction = () => {
        geoConfigActions.push({
            id: nanoid(),
            geo_codes: isDefaultAction ? ["000"] : [] ,
            action_type: Actions.ActionUndefined,
            configuration_type: isDefaultAction ? "default" : "custom",
            devices:[],
        })
        let _geoConfigActions = [...geoConfigActions]
        if (onChange) onChange(_geoConfigActions)
    }

    const deleteGeoConfigAction = (id: string) => () => {
        geoConfigActions.splice(geoConfigActions.findIndex(condition => condition.id === id), 1)
        let _geoConfigActions = [...geoConfigActions]
        if (onChange) onChange(_geoConfigActions)
    }

    const changeGeoConfigAction = (id: string) => (condition: Action) => {
        let _geoConfigActions = [...geoConfigActions]
        _geoConfigActions[_geoConfigActions.findIndex(condition => condition.id === id)] = condition
        if (onChange) onChange(_geoConfigActions)
    }

    return (
        <Fragment>
            {geoConfigActions.map(condition => <GeoConfigAction condition={condition} key={condition.id} accessNetworks={accessNetworks} wanProfiles={wanProfiles} trafficPolicies={trafficPolicies}
                onChange={changeGeoConfigAction(condition.id)} removeComp={
                    <IconButton aria-label="remove condition" onClick={deleteGeoConfigAction(condition.id)} disabled={readOnly}>
                        <RemoveCircle />
                    </IconButton>
                } readOnly={readOnly} selectedDevices={selectedDevices}/>)}
            {
                !readOnly && <Button variant="outlined" size="small" startIcon={<AddCircle />} className="geoConfig_management--button_outlined--primary" onClick={addGeoConfigAction} >
                    ADD ACTION
                </Button>
            }
        </Fragment>
    )
}

interface GeoConfigActionProps {
    condition: Action;
    removeComp: JSX.Element;
    accessNetworks?: AccessNetwork[];
    wanProfiles?: WanProfile[];
    trafficPolicies?: TrafficPolicy[];
    readOnly?: boolean;
    onChange?: (action: Action) => void;
    selectedDevices: SelectedDeviceType[];
}

function GeoConfigAction(props: GeoConfigActionProps) {
    const { condition, removeComp, accessNetworks, wanProfiles, trafficPolicies, readOnly, onChange, selectedDevices } = props;
    const [geoCodes, setGeoCodes] = useState<string>("")
    const [action, setAction] = useState<ActionType>(condition.action_type)
    const [accessNetworkNames, setAccessNetworkNames] = useState<string[]>([])
    const [wanProfileName, setWanProfileName] = useState<string>("")
    const [trafficPolicyName, setTrafficPolicyName] = useState<string>("")
    const [devicesAction, SetDevicesAction] = useState<DevicesAction[]>([])
    const [trafficPolicyCommonList, setTrafficPolicyCommonList] = useState<any>([])
    const [wanProfileCommonList, setWanProfileCommonList] = useState<any>([])
    const [accessNetworksCommonList, setAccessNetworksCommonList] = useState<any>([])

    useEffect(() => {
        setGeoCodes(condition?.geo_codes?.join(","))
    }, [condition?.geo_codes?.length])

    useEffect(() => {
        if(condition.action_type =='assignWanProfile'){
            let devices = condition?.devices || []
            if(devices.length > 0){
                let wanProfile = devices[0].wan_profile;
                let wanProfileName = devices[0].wan_profile?.name || "";

                let accessNetworks:any = wanProfile?.access_networks || [];
                let accessNetworkNames :string[] = []
                for(var i=0;i<accessNetworks.length;i++){
                    if(accessNetworks[i]?.name != undefined){
                        accessNetworkNames.push(accessNetworks[i].name)
                    }
                }
                if(accessNetworkNames.length > 0){
                    setAccessNetworkNames([...accessNetworkNames])
                }
                setWanProfileName(wanProfileName)
            }
        } else if(condition.action_type =='assignTrafficPolicy'){
            let devices = condition?.devices || []
            if(devices.length > 0){
                let trafficPolicy = devices[0].traffic_policy
                let tpProfileName = trafficPolicy?.name || ""
                
                let accessNetworks:any = trafficPolicy?.access_networks || [];
                let accessNetworkNames :string[] = []
                for(var i=0;i<accessNetworks.length;i++){
                    if(accessNetworks[i]?.name != undefined){
                        accessNetworkNames.push(accessNetworks[i].name)
                    }
                }
                if(accessNetworkNames.length > 0){
                    setAccessNetworkNames([...accessNetworkNames])
                }
                setTrafficPolicyName(tpProfileName)
            }
        }
    }, [])

    useEffect(() => {
        onChange && onChange({
            ...condition,
            geo_codes: geoCodes.split(","),
            action_type: action,
            devices: devicesAction,
            id: condition.id,
        })
    }, [geoCodes, action, devicesAction])

    useEffect(()=>{
        if(!_.isEmpty(trafficPolicies)){
            let commonList :any = []
            var trafficPolicyData = trafficPolicies || []

            for(var i=0;i<trafficPolicyData.length;i++){
                if(!commonList.find(e => e.name == trafficPolicyData[i].name)){
                    commonList.push({
                        name: trafficPolicyData[i].name,
                        common: trafficPolicyData[i].common
                    })
                }
            }

            commonList.sort((a, b)=> b.common - a.common)

            setTrafficPolicyCommonList([...commonList])
        } else {
            setTrafficPolicyCommonList([])
        }
    }, [trafficPolicies])

    useEffect(()=>{
        if(!_.isEmpty(wanProfiles)){
            let commonList :any = []
            var wanProfileData = wanProfiles || []

            for(var i=0;i<wanProfileData.length;i++){
                if(!commonList.find(e => e.name == wanProfileData[i].name)){
                    commonList.push({
                        name: wanProfileData[i].name,
                        common: wanProfileData[i].common
                    })
                }
            }

            commonList.sort((a, b)=> b.common - a.common)

            setWanProfileCommonList([...commonList])
        } else {
            setWanProfileCommonList([])
        }
    }, [wanProfiles])

    useEffect(()=>{
        if(!_.isEmpty(accessNetworks)){
            let commonList :any = []
            var accessNetworksData = accessNetworks || []

            for(var i=0;i<accessNetworksData.length;i++){
                if(!commonList.find(e => e.name == accessNetworksData[i].name)){
                    commonList.push({
                        name: accessNetworksData[i].name,
                        common: accessNetworksData[i].common
                    })
                }
            }

            commonList.sort((a, b)=> b.common - a.common)

            setAccessNetworksCommonList([...commonList])
        } else {
            setAccessNetworksCommonList([])
        }
    }, [accessNetworks])

    useEffect(()=>{
        let deviceActionData:DevicesAction[] = []
        switch(action){
            case 'assignTrafficPolicy':{
                let accessNetworkList = accessNetworks || [];
                
                for(var i=0;i<selectedDevices.length;i++){
                    deviceActionData.push({
                        site_id: selectedDevices[i].site_id,
                        device_id: selectedDevices[i].device_id,
                        group_id: selectedDevices[i].group_id,
                    })
                    
                    let arrAccessNetworks: AccessNetwork[] = []
                    for(var j=0;j<accessNetworkList.length;j++){
                        if(accessNetworkNames.includes(accessNetworkList[j].name) && deviceActionData[i].device_id == accessNetworkList[j].device_id){
                            arrAccessNetworks.push({
                                id: accessNetworkList[j].id,
                                name: accessNetworkList[j].name
                            })
                        }
                    }

                    var trafficPolicyData = trafficPolicies || [];
                    for(var j=0;j<trafficPolicyData.length;j++){
                        var trafficPolicyId = trafficPolicyData[j].id
                        var deviceId = trafficPolicyData[j].device_id
    
                        if(trafficPolicyName == trafficPolicyData[j].name && deviceId == deviceActionData[i].device_id){
                            deviceActionData[i].traffic_policy = {
                                id: trafficPolicyId,
                                name:  trafficPolicyName,
                            }
                        }
                    } 
                    deviceActionData[i].traffic_policy = {
                        id: deviceActionData[i].traffic_policy?.id || "",
                        name: deviceActionData[i].traffic_policy?.name || "",
                        access_networks: arrAccessNetworks
                    }
                }
                break;
            }
            case 'assignWanProfile':{
                let accessNetworkList = accessNetworks || [];
                
                for(var i=0;i<selectedDevices.length;i++){
                    deviceActionData.push({
                        site_id: selectedDevices[i].site_id,
                        device_id: selectedDevices[i].device_id,
                        group_id: selectedDevices[i].group_id,
                    })
                    
                    let arrAccessNetworks: AccessNetwork[] = []
                    for(var j=0;j<accessNetworkList.length;j++){
                        if(accessNetworkNames.includes(accessNetworkList[j].name) && deviceActionData[i].device_id == accessNetworkList[j].device_id){
                            arrAccessNetworks.push({
                                id: accessNetworkList[j].id,
                                name: accessNetworkList[j].name
                            })
                        }
                    }

                    var wanProfileData = wanProfiles || [];
                    for(var j=0;j<wanProfileData.length;j++){
                        var wanProfileId = wanProfileData[j].id
                        var deviceId = wanProfileData[j].device_id
    
                        if(wanProfileName == wanProfileData[j].name && deviceId == deviceActionData[i].device_id){
                            deviceActionData[i].wan_profile = {
                                id: wanProfileId,
                                name:  wanProfileName,
                            }
                        }
                    } 
                    deviceActionData[i].wan_profile = {
                        id: deviceActionData[i].wan_profile?.id || "",
                        name: deviceActionData[i].wan_profile?.name || "",
                        access_networks: arrAccessNetworks
                    }
                }
                break;
            }
        }
        SetDevicesAction([...deviceActionData])
    }, [accessNetworkNames, trafficPolicyName, wanProfileName])

    const handleGeoCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const _value = event?.target?.value
        setGeoCodes(_value)
    }

    const handleGeoCodeValidation = (code: string): boolean => {
        // const geoCodePattern = /^\d{3}(?:\/\d{2})?(,\d{3}(?:\/\d{2})?)*$/
        const geoCodePattern = /^\d{3}(?:\/\d{2}|\/\d{3})?(, ?\d{3}(?:\/\d{2}|\/\d{3})?)*$/
        return geoCodePattern.test(code)
    }

    const handleChangeActionOnExhaust = (event: SelectChangeEvent) => {
        const _action = event.target.value as ActionType
        setAction(_action)
    }


    const handleChangeWanProfileName = (event: SelectChangeEvent) => {
        setWanProfileName(event.target.value as string)
    }

    const handleChangeTrafficPolicyName = (event: SelectChangeEvent) => {
        setTrafficPolicyName(event.target.value as string)
    }

    const handleChangeAccessNetworkNames = (event: SelectChangeEvent<string[]>) => {
        const {
            target: { value },
        } = event;
        let _value = typeof value === 'string' ? value.split(',') : value
        _value = _value.filter(name => accessNetworks?.find(network => network.name === name))
        setAccessNetworkNames(_value);
    }


    return (
        <Grid>
            <Grid container spacing={0.5}>
                <Grid item xs={12} md={10}>
                    <Grid container spacing={0.5} style={{paddingTop:"5px", paddingBottom:"5px"}}>
                            {
                                condition?.configuration_type != "default" ?
                                    <Grid item xs={3} md={3}>
                                        <FormControl fullWidth error={!geoCodes?.length}>
                                            <TextField
                                                size="small"
                                                label="MCC"
                                                variant="outlined"
                                                value={geoCodes}
                                                onChange={handleGeoCodeChange}
                                                placeholder="e.g: 312,234,310"
                                                InputProps={{
                                                    readOnly: readOnly,
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                : <></>
                            }
                        <Grid item xs={3} md={3}>
                            <FormControl fullWidth size="small">
                                <InputLabel id="action-on-exhaust-label">Action</InputLabel>
                                <Select
                                    value={action}
                                    onChange={handleChangeActionOnExhaust}
                                    labelId="action-on-exhaust-label"
                                    label="Action"
                                    readOnly={readOnly}
                                >
                                    <MenuItem value={Actions.ActionAssignTrafficPolicy}>Assign Traffic Policy</MenuItem>
                                    <MenuItem value={Actions.ActionAssignWanProfile}>Assign WAN Profile</MenuItem>
                                    <MenuItem value={Actions.ActionNotification}>Send Notification</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3} md={3}>
                            {
                                (action === Actions.ActionAssignWanProfile || action === Actions.ActionAssignTrafficPolicy) ? 
                                    <>
                                        {
                                            action === Actions.ActionAssignWanProfile && 
                                                <FormControl fullWidth size="small">
                                                    <InputLabel id="wan-profile-label">WAN Profile</InputLabel>
                                                    <Select
                                                        value={wanProfileName}
                                                        onChange={handleChangeWanProfileName}
                                                        labelId="wan-profile-label"
                                                        label="WAN Profile"
                                                        readOnly={readOnly}
                                                    >
                                                        {wanProfileCommonList && wanProfileCommonList.map(profile => <MenuItem disabled={profile.common == 0 ? true : false } key={profile.id} value={profile.name}>{profile.name}</MenuItem>)}
                                                    </Select>
                                                </FormControl>
                                        }
                                        {
                                            action === Actions.ActionAssignTrafficPolicy && 
                                                <FormControl fullWidth size="small">
                                                    <InputLabel id="traffic-policy-label">Traffic Policy</InputLabel>
                                                    <Select
                                                        value={trafficPolicyName}
                                                        onChange={handleChangeTrafficPolicyName}
                                                        labelId="traffic-policy-label"
                                                        label="Traffic Policy"
                                                        readOnly={readOnly}
                                                    >
                                                        {trafficPolicyCommonList && trafficPolicyCommonList.map(policy => <MenuItem disabled={policy.common == 0 ? true : false } key={policy.id} value={policy.name}>{policy.name}</MenuItem>)}
                                                    </Select>
                                                </FormControl>
                                        }
                                    </>
                                    :<></>
                            }
                        </Grid>
                        <Grid item xs={3} md={3}>
                            {
                                (action === Actions.ActionAssignWanProfile || action === Actions.ActionAssignTrafficPolicy) && 
                                    <FormControl fullWidth size="small">
                                        <InputLabel id="access-network-label">Access Networks</InputLabel>
                                        <Select
                                            multiple
                                            value={accessNetworkNames}
                                            onChange={handleChangeAccessNetworkNames}
                                            labelId="access-network-label"
                                            label="Access Networks"
                                            renderValue={(selected) => {
                                                let _anName = new Map(accessNetworksCommonList?.map(an => [an.name, an.name]))
                                                return (selected as string[]).map(name => _anName.get(name) || '<Deleted>').join(', ')
                                            }}
                                            readOnly={readOnly}
                                        >
                                            {accessNetworksCommonList && accessNetworksCommonList.map(network => <MenuItem disabled={network.common == 0 ? true : false } key={network.id} value={network.name}>
                                                <Checkbox size='small' checked={false || accessNetworkNames.indexOf(network.name) > -1} />
                                                <ListItemText primary={network.name} />
                                            </MenuItem>)}
                                        </Select>
                                    </FormControl>
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={1} display={'flex'} gap={0.25} alignItems={'center'} className="configuration-remove-icon">
                    {removeComp}
                </Grid>
            </Grid>
        </Grid>
    )
}

const mapStateToProps = (state) => ({
    accessNetworks: state.geoConfigManager.accessNetworks,
    wanProfiles: state.geoConfigManager.wanProfiles,
    trafficPolicies: state.geoConfigManager.trafficPolicies
});

export default connect(mapStateToProps, {})(GeoConfigActions)