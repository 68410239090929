import React, { useState, useEffect } from "react";
import Chart from '../../components/Charts/chart';
import { connect, useDispatch } from 'react-redux';
import { fetchRssiHistoData, fetchSinrHistoData, fetchSpeedHistoData, fetchVsatHexALLMetrics, fetchVsatHistoDetails, fetchVsatTxPowerHistoDetails } from "../../actions/fetchData";
import ChartAdv from '../../components/Charts/HighChartsComp';
import Button from '@mui/material/Button';
import { Grid } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import './TowerVesselDetails.css';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import MetricsTable from './MetricsInfoTable';
import Histogram from '../../components/Charts/Histogram'
import { SET_SELECTED_HEX } from "../../actions/actionType";
import BasicTable from "./../../components/Table";
import VsatMetricsTable from './vsatMetricsTable';
import { getRSSIHistoDetails, getSINRHistoDetails, getSPEEDHistoDetails, getStarLinkDropPercentageHistoDetails, getStarLinkHistoDetails, getStarLinkLatencyHistoDetails, getStarLinkSpeedHistoDetails } from "../../actions/singleStoreActions";
export interface MccMncCodes {
    operator?:string;
    carrier?:string;
    mcc?:string;
    mnc?:string;
    connectedPercentage?:number;
}

const HexDetails = (props: any) => {

    const { selctedVessel, vesselStartTime, vesselEndTime, handleChartOnClose, hoveredStart, hoveredEnd, chartsLoading, hexContent, selectedCarrier, selctedIndex, setHexId, vsatHistoDetails, isVsatDataSource, vsatTxPowerDetails,selectedDataSource, vsatHexAllMetrics , hexResolution, starLinkHistoDetails, selectedLayer, starLinkLatencyHistoDetails, starLinkDropPercentageHistoDetails, starLinkSpeedHistoDetails } = props;
    const [tableRowData, setTableRowData] = useState<any>([null]);
    const [sinrInterval, setSinrInterval] = useState(2);
    const [rssiInterval, setRssiInterval] = useState(2);
    const [speedInterval, setSpeedInterval] = useState(2)

    const dispatch = useDispatch();
    // const [isStarLink, setStarLink] = useState(false);
    // console.log("hexContent", hexContent);
    // if(selectedDataSource === "STARLINK" && !isStarLink) {
    //     setStarLink(true);
    // }

    useEffect(() => {
        if (tableRowData?.mcc !== undefined && tableRowData.mcc !== null) {
            dispatch(getSINRHistoDetails(selctedIndex, selectedCarrier, tableRowData?.operator, tableRowData?.mcc, tableRowData?.mnc, "sinr", sinrInterval, hexResolution));

        } else {

        }
    }, [tableRowData, sinrInterval])


    // useEffect(() => {
    //     if (selctedIndex && isVsatDataSource && selectedCarrier !=="ALL" ) {
    //         dispatch(fetchVsatHistoDetails(selctedIndex, selectedCarrier, "snr", sinrInterval));
    //         dispatch(fetchVsatTxPowerHistoDetails(selctedIndex,selectedCarrier,"txpower",sinrInterval))
    //     } 
    // }, [ selctedIndex,selectedCarrier, sinrInterval])


    useEffect(() => {
        if (tableRowData?.mcc !== undefined && tableRowData.mcc !== null) {
            dispatch(getRSSIHistoDetails(selctedIndex, selectedCarrier, tableRowData?.operator, tableRowData?.mcc, tableRowData?.mnc, "rssi", rssiInterval, hexResolution));
        } else {

        }
    }, [tableRowData, rssiInterval])

    useEffect(() => {
        if (tableRowData?.mcc !== undefined && tableRowData.mcc !== null) {
            dispatch(getSPEEDHistoDetails(selctedIndex, selectedCarrier, tableRowData?.operator, tableRowData?.mcc, tableRowData?.mnc, "speed", speedInterval, hexResolution));
        } else {

        }

    }, [tableRowData, speedInterval])

    useEffect(() => {
        if(isVsatDataSource && selectedCarrier === "ALL" && selectedDataSource === "VSAT") {
            dispatch(fetchVsatHexALLMetrics(selctedIndex))
        }
    },[selctedIndex])

    useEffect(() => {
        if(selectedDataSource === "STARLINK") {
            dispatch(getStarLinkLatencyHistoDetails(selctedIndex, hexResolution, selectedLayer));
            dispatch(getStarLinkDropPercentageHistoDetails(selctedIndex, hexResolution, selectedLayer));
            dispatch(getStarLinkSpeedHistoDetails(selctedIndex, hexResolution, selectedLayer));
        }
    },[selctedIndex,hexResolution])


    let frequencyBands = hexContent?.layer?.properties?.frequencyBands;
    let isFrequencyBand = (frequencyBands && frequencyBands !== undefined && frequencyBands.length > 0);
    let allcarrierMetrics = hexContent?.layer?.properties?.carrierWiseMetrics;
    let servingCarMccMncDetails:any = [];
    let servingCarMccMncDetailsd:MccMncCodes[] = [];
    let allCarrierInfoDetails = allcarrierMetrics && allcarrierMetrics.length > 0 && allcarrierMetrics.forEach((item:any,index:any) => {
        let currentOpertaor = item?.operator;
        let servingSytemMc = item?.servingSystemMC;
        servingCarMccMncDetails = servingSytemMc && servingSytemMc.length > 0 && servingSytemMc.forEach((eachServingMccMnc:any) => {
            let eachdetails:MccMncCodes = {"operator":currentOpertaor,"carrier":eachServingMccMnc?.carrier,"mcc":eachServingMccMnc?.mcc,"mnc":eachServingMccMnc?.mnc, "connectedPercentage":eachServingMccMnc?.connectedPercentage};
            if(eachdetails !== undefined) {
                servingCarMccMncDetailsd.push(eachdetails);
            }
            
        });
    })

    let singleCarrierMetrics: any = hexContent?.layer?.properties?.carrierDistribution;
    let filteredSinglecarrierMetrics = singleCarrierMetrics && singleCarrierMetrics.length > 0 && singleCarrierMetrics.map((item: any) => {
        return { "operator": selectedCarrier, "name": item?.carrier, "mcc": item?.mcc, "mnc": item?.mnc, "connectedPercentage": item?.connectedPercentage }
    });
    let sinrHistoDataDatils = props?.sinrHistoData;
    let rssiHistoDataDetails = props?.rssiHistoData;
    let speedHistoDataDetails = props?.speedHistoData;
    let vsatHistogramDetails = props?.vsatHistoDetails?.getVsatMetrics?.histogramData
    let VsatTxDetails = props?.vsatTxPowerDetails?.getVsatMetrics?.histogramData;


    let vsatSinrData = vsatHistogramDetails && vsatHistogramDetails.length > 0 && vsatHistogramDetails.map((item:any) => {
        return item?.key;
    })

    let vsatsinRDocCount = vsatHistogramDetails && vsatHistogramDetails.length > 0 && vsatHistogramDetails.map((item:any) => {
        return item?.doc_count;
    })

    let vsatTxPData = VsatTxDetails && VsatTxDetails.length > 0 && VsatTxDetails.map((item:any) => {
        return item?.key;
    })

    let vsatTxPCount = VsatTxDetails && VsatTxDetails.length > 0  && VsatTxDetails.map((item:any) => {
        return item?.doc_count;
    });

    let vsatProvidersList = vsatHexAllMetrics?.serviceproviders;


    let rssiData = [];
    if(rssiHistoDataDetails && rssiHistoDataDetails?.columns ) {
        rssiData = rssiHistoDataDetails?.columns;
    }

    let rssiDocCount = [];
    if(rssiHistoDataDetails && rssiHistoDataDetails?.rows && rssiHistoDataDetails?.rows[0]) {
        rssiDocCount = rssiHistoDataDetails?.rows[0]
    }

    let sinrData = [];
    if(sinrHistoDataDatils && sinrHistoDataDatils?.columns && sinrHistoDataDatils.columns) {
        sinrData = sinrHistoDataDatils?.columns
    }

    let sinrDocCount = [];
    if(sinrHistoDataDatils && sinrHistoDataDatils?.rows && sinrHistoDataDatils.rows[0]) {
        sinrDocCount = sinrHistoDataDatils?.rows[0]
    }

    let speedData = [];
    if(speedHistoDataDetails && speedHistoDataDetails?.columns) {
        speedData = speedHistoDataDetails?.columns;
    }

    let speedDocCount = [];
    if(speedHistoDataDetails && speedHistoDataDetails?.rows && speedHistoDataDetails?.rows[0]) {
        speedDocCount = speedHistoDataDetails?.rows[0];
    }

    let starlinkLatencyHitoData = [];
    if(starLinkLatencyHistoDetails && starLinkLatencyHistoDetails?.columns) {
        starlinkLatencyHitoData = starLinkLatencyHistoDetails?.columns;
    }
    let starLinkLatencyCount = [];
    if(starLinkLatencyHistoDetails && starLinkLatencyHistoDetails?.rows && starLinkLatencyHistoDetails?.rows[0]) {
        starLinkLatencyCount = starLinkLatencyHistoDetails?.rows[0];
    }

    let starlinkSpeedHitoData = [];
    if(starLinkSpeedHistoDetails && starLinkSpeedHistoDetails?.columns) {
        starlinkSpeedHitoData = starLinkSpeedHistoDetails?.columns;
    }

    let starLinkSpeedCount = [];
    if(starLinkSpeedHistoDetails && starLinkSpeedHistoDetails?.rows && starLinkSpeedHistoDetails?.rows[0]) {
        starLinkSpeedCount = starLinkSpeedHistoDetails?.rows[0];
    }

    let starlinkDropPercentageHitoData = [];
    if(starLinkDropPercentageHistoDetails && starLinkDropPercentageHistoDetails?.columns) {
        starlinkDropPercentageHitoData = starLinkDropPercentageHistoDetails?.columns;
    }

    let starLinkDropPercentageCount = [];
    if(starLinkDropPercentageHistoDetails && starLinkDropPercentageHistoDetails?.rows && starLinkDropPercentageHistoDetails?.rows[0]) {
        starLinkDropPercentageCount = starLinkDropPercentageHistoDetails?.rows[0];
    }


    // const getFrequencyList = (): any => {
    //     if (isFrequencyBand) { }
    //     let freqList = isFrequencyBand ? frequencyBands.map((item: any, i: any) => {
    //         return <><ListItem key={i} >{`${i + 1}. ${item}`}</ListItem><Divider /></>
    //     }) : <div> No Data</div>
    //     return freqList;
    // }

    // const getCarrierList = (): any => {
    //     let allCarierList = allcarrierMetrics && allcarrierMetrics.map((item: any, i: any) => {
    //         return <><ListItem key={i} >{`${i + 1}. ${item.operator}`}</ListItem><Divider /></>
    //     });
    //     return allCarierList;
    // }


    const handleChartClose = () => {
        speedData = [];
        speedDocCount = [];
        rssiDocCount = [];
        rssiData = [];
        sinrDocCount = [];
        sinrData = [];
        starLinkLatencyCount = [];
        starlinkLatencyHitoData = [];
        starLinkSpeedCount = [];
        starlinkSpeedHitoData = [];
        starLinkDropPercentageCount = [];
        starlinkDropPercentageHitoData = [];
        setTableRowData(null);
        setHexId(0);
        handleChartOnClose();
    }
    const sinrChartOptions = {
        categoriesName: "SINR",
        chartTitleText: "SINR",
        seriesName: "sinr"

    }

    const rssiChartOptions = {
        categoriesName: "RSSI",
        chartTitleText: "RSSI",
        seriesName: "rssi"

    }

    const speedChartOptions = {
        categoriesName: "SPEED",
        chartTitleText: "SPEED",
        seriesName: "speed(Mbps)"
    }

    const vsatSinrChartOptions = {
        categoriesName: "SINR",
        chartTitleText: "SINR",
        seriesName: "SINR "
    }

    const vsatTxPChartOptions = {
        categoriesName: "TxPower",
        chartTitleText: "TxPower",
        seriesName: "TxPoWer "
    }

    const starLinkLatencyChartOptions = {
        categoriesName: "LATENCY",
        chartTitleText: "LATENCY",
        seriesName: "Latency (ms) "
    }

    const starLinkSpeedChartOptions = {
        categoriesName: "SPEED",
        chartTitleText: "SPEED",
        seriesName: "speed(Mbps) "
    }

    const starLinkDroPPercentageChartOptions = {
        categoriesName: "DROP PERCENTAGE",
        chartTitleText: "DROP PERCENTAGE",
        seriesName: "Drop Percentage(%) "
    } 

    const handleRowData = (rowData: any) => {
        if (rowData?.mcc !== null && rowData?.mcc !== undefined) {
            setTableRowData(rowData)
        }

    }


   
    const handleRssiHistoInterval = (event: any) => {
        setRssiInterval(event?.target?.value);
    }

    const handleSinrHistoInterval = (event: any) => {
        setSinrInterval(event?.target?.value);
    }

    const handleSpeedHistoInterval = (event: any) => {
        setSpeedInterval(event?.target?.value);
    }



    return (
        <div className="popUpchartContainer">
            <Grid className="popupChartHeader">
                <div className="popUpVesselName">{selctedVessel}</div>
                {/* {isChartLoading && <Grid className="chartLoader">
                    <CircularProgress sx={{ width: "24px !important", height: "24px !important", color: "#264C86" }} />
                </Grid>} */}
                <CloseIcon className="closeIc" onClick={() => handleChartClose()} />
            </Grid>
            <div className="chartCollection">

                {/* ALL Carieer Section */}
                {selectedCarrier === "ALL" && <>
                {servingCarMccMncDetailsd && servingCarMccMncDetailsd.length > 0 && <BasicTable  isOperatorEnabled={true} isConnectedPercent={true} vesselMetricsInfo={servingCarMccMncDetailsd}/>}
                </>
                }

                {/* Individual Carrier Section */}

                {selectedCarrier !== "ALL" && <Accordion className="chartAccordions"
                    defaultExpanded={true}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className="accordionTittle">{"HISTOGRAM"}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {filteredSinglecarrierMetrics && selectedDataSource ==="CELL" && <div> <MetricsTable singleCarrierMetrics={filteredSinglecarrierMetrics} handleRowData={handleRowData} selctedIndex={selctedIndex} /></div>}
                        {rssiDocCount && rssiDocCount.length > 0 &&  selectedDataSource ==="CELL" &&  filteredSinglecarrierMetrics && tableRowData && <Histogram options={rssiChartOptions} chartData={rssiDocCount} catogoriesData={rssiData} chartType={"sinr"} handleHistoInterval={handleRssiHistoInterval} />}
                        {sinrDocCount && sinrDocCount.length > 0 &&  selectedDataSource ==="CELL" &&  filteredSinglecarrierMetrics && tableRowData && <Histogram options={sinrChartOptions} chartData={sinrDocCount} catogoriesData={sinrData} chartType={"rssi"} handleHistoInterval={handleSinrHistoInterval} />}
                        {speedDocCount && speedDocCount.length > 0 &&  selectedDataSource ==="CELL" &&  filteredSinglecarrierMetrics && tableRowData && <Histogram options={speedChartOptions} chartData={speedDocCount} catogoriesData={speedData} chartType={"speed"} handleHistoInterval={handleSpeedHistoInterval} />}
                        {vsatsinRDocCount && vsatsinRDocCount.length > 0  && <Histogram options={vsatSinrChartOptions} chartData={vsatsinRDocCount} catogoriesData={vsatSinrData} chartType={"SINR"} handleHistoInterval={handleSpeedHistoInterval} />}
                        {vsatTxPCount && vsatTxPCount.length > 0  && <Histogram options={vsatTxPChartOptions} chartData={vsatTxPCount} catogoriesData={vsatTxPData} chartType={"TxPower"} handleHistoInterval={handleSpeedHistoInterval} />}
                        {starLinkLatencyCount && starLinkLatencyCount.length > 0  && selectedDataSource ==="STARLINK" && <Histogram options={starLinkLatencyChartOptions} chartData={starLinkLatencyCount} isIntevalEnaled={false} catogoriesData={starlinkLatencyHitoData} chartType={"Latency"} handleHistoInterval={handleSpeedHistoInterval} /> }
                        {starLinkSpeedCount && starLinkSpeedCount.length > 0  && selectedDataSource ==="STARLINK"  && <Histogram options={starLinkSpeedChartOptions} chartData={starLinkSpeedCount} isIntevalEnaled={false} catogoriesData={starlinkSpeedHitoData} chartType={"Speed"} handleHistoInterval={handleSpeedHistoInterval} /> }
                        {starLinkDropPercentageCount && starLinkDropPercentageCount.length > 0 && selectedDataSource ==="STARLINK"  && <Histogram options={starLinkDroPPercentageChartOptions} chartData={starLinkDropPercentageCount} isIntevalEnaled={false} catogoriesData={starlinkDropPercentageHitoData} chartType={"Drop Percentage"} handleHistoInterval={handleSpeedHistoInterval} /> }
    
                    </AccordionDetails>
                </Accordion>}
                {isVsatDataSource && <VsatMetricsTable  vsatMetricsInfo={vsatProvidersList} />}

            </div>


        </div>
    )
}

const mapStateToProps = (state: any) => ({
    sinrHistoData: state?.products?.sinrHistoData,
    rssiHistoData: state?.products?.rssiHistoData,
    speedHistoData: state?.products?.speedHistoData,
    vsatHistoDetails: state?.products?.vsatHistoDetails,
    vsatTxPowerDetails: state?.products?.vsatTxPowerDetails,
    vsatHexAllMetrics: state?.products?.vsatHexAllMetrics,
    starLinkHistoDetails: state?.products?.starLinkHistoDetails,
    starLinkLatencyHistoDetails: state?.products?.starLinkLatencyHistoDetails,
    starLinkDropPercentageHistoDetails: state?.products?.starLinkDropPercentageHistoDetails,
    starLinkSpeedHistoDetails: state?.products?.starLinkSpeedHistoDetails
});

const mapDispatchToProps = (dispatch: (arg0: { type: string; item: any; }) => any) => {
    return {
        setHexId: (hexIndexId: any) => dispatch({ type: SET_SELECTED_HEX, item: hexIndexId })
    }
}

const HexDetailsWithData = connect(
    mapStateToProps, mapDispatchToProps
)(HexDetails);

export default HexDetailsWithData;