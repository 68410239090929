import { useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import { useLeafletContext } from "@react-leaflet/core";
import * as L from "leaflet";
import './Map.css';
import "../../components/PolyLine/Leaflet.MultiOptionsPolyline";

const MutiOptionPolyLine = (props) => {
  const { points, map } = props;

  const container = map;

  useEffect(() => {
    container.addLayer(multiOptionPoly);
    return () => {
      container.removeLayer(multiOptionPoly);

    };
  }, [points]);

  const multiOptionPoly = L.multiOptionsPolyline(points, {
    multiOptions: {
      optionIdxFn: function (props) {
        if (props && props[2]) {
          return props[2];
        } else {
          return 0;
        }

      },
      // vsat - blue // lte green // no network - red // both - purple  - Update 
      options: [
        { color: '#FF0000' }, { color: '#A020F0' }, { color: '#0000FF' }, { color: '#00FF00' }, { color: "#000000" }, {color: "#ff9900"}
      ]
    },
    weight: 2,
    lineCap: 'butt',
    opacity: 0.75,
    smoothFactor: 1.5
  });

  return null;
}

export default MutiOptionPolyLine;



