import * as React from 'react';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import ListItem from '@mui/material/ListItem';
import CustomSlider from '../../components/Slider'

import './CheckBox.css'

export default function IndeterminateCheckbox(props: any) {
  const { uniqueContoursName, handleContorlist, handleContorsOpacityVal, handleAllContors, isCustomSliderEnabled } = props;
  const [checked, setChecked] = React.useState(true);
  const [contorsOpacity, setContorsOpacity] = React.useState(3);

  //   const handleLayerItemClick = (layerName: string) => {
  //     let LayerList = { ...selectedLayersList };
  //     LayerList[layerName] = !LayerList[layerName];
  //     setSelectedLayersList(LayerList);
  // }

  const handleChange = (item: any, i: any): any => {
    handleContorlist(item);
  }

  const handleAllChange = (props: any) => {
    if (checked) {
      setChecked(false);
      let uniqueContr = { ...uniqueContoursName };
      Object.keys(uniqueContoursName).map((item: any) => {
        uniqueContr[item] = false;
      })
      handleAllContors(uniqueContr);

    } else {
      setChecked(true);
      let uniqueContr = { ...uniqueContoursName };
      Object.keys(uniqueContoursName).map((item: any) => {
        uniqueContr[item] = true;
      })
      handleAllContors(uniqueContr);
    }
  }

  const handleContorsOpacity = (opacity: any) => {

    setContorsOpacity(opacity);
    handleContorsOpacityVal(opacity);
  }

  const childList = Object.keys(uniqueContoursName);

  const children = (
    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
      { childList && childList.map((item, i) => {
        return <FormControlLabel key={"" + i}
          label={item}
          control={<Checkbox key={"" + i} className="contoursLayers" checked={uniqueContoursName[item]} onClick={() => handleChange(item, i)} />}
        />
      })
      }

    </Box>
  );

  return (
    <div className="ContorListContainer">
      <><ListItem className="customSlider">
        {isCustomSliderEnabled && <CustomSlider handleSlideValue={handleContorsOpacity} defaultValue={contorsOpacity}></CustomSlider>}
      </ListItem></>
      <FormControlLabel className="allCountoursSel"
        label="ALL"
        control={
          <Checkbox
            checked={checked}

            onChange={handleAllChange}
          />
        }
      />
      {children}
    </div>
  );
}
