import { Fragment, useEffect, useMemo, useState } from "react";
import { IconButton, Paper, TableCell, TableRow, Tooltip } from "@mui/material";
import { Edit, Delete, Lens } from "@material-ui/icons";
import { convertDateTimeIntoTimezone, readablePeriod } from "../../../utils/util";
import { Device, GeoConfig } from "../types";
import { MMDDYYYYHMMSS_DATE_FORMAT_24_HRS } from "../../../utils/constants";
import {JsonDataTooltip} from "./../../../components/JSONTooltip/JsonTooltip"
interface EditableGeoConfigRowProps {
    geoConfig: GeoConfig;
    onEdit: (geoConfig: GeoConfig) => void;
    onDelete: (geoConfig: GeoConfig) => void;
    onToggleDisable: (geoConfig: GeoConfig) => void;
    isReadOnlyUser: boolean;
    devices: any;
    timezone: string;
}

export function EditableGeoConfigRow(props: EditableGeoConfigRowProps) {
    const { geoConfig, devices, onEdit, onDelete, onToggleDisable, isReadOnlyUser, timezone } = props;
    const [selectedDevice, setSelectedDevice] = useState<Device | undefined>(geoConfig != null ? devices.find(d => d.id === geoConfig.device_id) : undefined);
    let actions: any = geoConfig?.actions

    const handleEdit = () => {
        onEdit(geoConfig);
    }

    const handleDelete = () => {
        onDelete(geoConfig);
    }

    useEffect(() => {
        if (devices?.length > 0) {
            setSelectedDevice(geoConfig != null ? devices.find(d => d.id === geoConfig.device_id) : undefined);
        }
    }, [devices])

    return (
        <TableRow key={geoConfig.id} className="row-parent">
            <TableCell>
                {geoConfig.dp_name}
            </TableCell>
            <TableCell>
                {geoConfig.rule_name}
            </TableCell>
            <TableCell>
                <JsonDataTooltip className="labelDEtailsJsonToolt=Tip" data={actions} />
            </TableCell>
            <TableCell>
                {convertDateTimeIntoTimezone(geoConfig?.created_at, timezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS)}
            </TableCell>
            {!isReadOnlyUser && <TableCell>
                <Paper elevation={0} className="geoConfig_management--device_summary--actions">
                    <Fragment>
                        <Tooltip title="Edit">
                            <IconButton size='small' onClick={handleEdit}>
                                <Edit />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete">
                            <IconButton size='small' onClick={handleDelete}>
                                <Delete />
                            </IconButton>
                        </Tooltip>
                    </Fragment>
                </Paper>
            </TableCell>}
        </TableRow>
    )
}