import { Button, Dialog, DialogTitle, DialogContent, DialogActions  } from "@mui/material";

interface DeleteGeoConfigConfirmationDialogProps {
    open: boolean;
    onConfirm: () => void;
    onCancel: () => void;
    ruleName: string;
}

export function DeleteGeoConfigConfirmationDialog(props: DeleteGeoConfigConfirmationDialogProps) {
    const { open, onConfirm, onCancel, ruleName } = props;

    return (
        <Dialog
            open={open}
            onClose={onCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle className='delete-content-filter-title'>
                        <span className="content-filter-title-name"> Confirmation </span>
            </DialogTitle>
            <DialogContent className="delete-content-filter-dialog-content">
                <div className="delete-form-content-filter-body">
                    Are you sure you want to delete Geofence Rule(MCC) - {ruleName}?
                </div>
            </DialogContent>
            <DialogActions className='delete-content-filter-dialog-actions'>
                <Button variant="outlined" className="content_filter--button_outlined_filter" onClick={onCancel}>
                    Cancel
                </Button>
                <Button variant="contained" className="content_filter-footer-create-button" onClick={onConfirm}>Delete</Button>
            </DialogActions>
        </Dialog>
    )
}