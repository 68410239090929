import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";
import './slider.css';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 125,

  },
  margin: {
    height: theme.spacing(2)
  },
  thumb: {
    background: "red",
    "&~&": {
      background: "green"
    }
  },
  mark: {
    background: "black"
  },
  rail: {
    background: "linear-gradient(to right, white , green , red);"
  },
  track: {
    background: "orange"
  },
  valueLabel: {
    "&>*": {
      background: "black"
    }
  },
  MuiSlider: {
    markLabel: {
        display: 'none',
    },
  },
}));



export default function DiscreteSlider(props:any) {
  const { handleSlideValue,defaultValue } = props;
  const classes = useStyles();
  let basicValue = defaultValue ? defaultValue : 5;
  const [sliderValue, setValue] = useState([basicValue]);

  useEffect(()=> {
    handleSlideValue(sliderValue);
  },[sliderValue]);

  const onChange = (e:any, sliderVale:any) => {
    handleSlideValue(sliderVale);
      setValue(sliderVale);
  };


  return (
    <div className={classes.root}>
      <Slider style={{marginLeft:"45px", padding:"0px"}}
        classes={{
          thumb: classes.thumb,
          rail: classes.rail,
          track: classes.track,
          valueLabel: classes.valueLabel,
          mark: classes.mark
        }}
        valueLabelDisplay="auto"
        value={sliderValue}
        min={0}
        max={9}
        onChange={onChange}
      />
    </div>
  );
}
