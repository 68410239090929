export const BeamNewData = {
    "type": "FeatureCollection",
    "features":
        [
            {
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                50.1679,
                                2.5201
                            ],
                            [
                                49.2632,
                                1.357
                            ],
                            [
                                48.7463,
                                0.1939
                            ],
                            [
                                48.617,
                                -1.7447
                            ],
                            [
                                47.8416,
                                -2.9078
                            ],
                            [
                                47.3247,
                                -4.4586
                            ],
                            [
                                46.8077,
                                -6.2679
                            ],
                            [
                                46.937,
                                -7.3018
                            ],
                            [
                                47.0662,
                                -8.7234
                            ],
                            [
                                46.6785,
                                -9.7573
                            ],
                            [
                                46.5492,
                                -11.8251
                            ],
                            [
                                45.9031,
                                -14.0221
                            ],
                            [
                                44.223,
                                -15.0559
                            ],
                            [
                                45.3861,
                                -16.9945
                            ],
                            [
                                44.9984,
                                -19.3207
                            ],
                            [
                                44.0938,
                                -22.1639
                            ],
                            [
                                44.223,
                                -24.2317
                            ],
                            [
                                44.3522,
                                -26.6872
                            ],
                            [
                                44.6107,
                                -28.7549
                            ],
                            [
                                43.8353,
                                -30.1765
                            ],
                            [
                                43.1891,
                                -32.115
                            ],
                            [
                                42.9307,
                                -32.8905
                            ],
                            [
                                42.1552,
                                -34.1828
                            ],
                            [
                                39.3121,
                                -36.5091
                            ],
                            [
                                38.2782,
                                -37.1552
                            ],
                            [
                                36.9858,
                                -38.7061
                            ],
                            [
                                36.0812,
                                -39.7399
                            ],
                            [
                                35.5642,
                                -40.3861
                            ],
                            [
                                34.1426,
                                -40.7738
                            ],
                            [
                                31.6872,
                                -41.1615
                            ],
                            [
                                30.0071,
                                -41.5493
                            ],
                            [
                                26.9054,
                                -41.42
                            ],
                            [
                                24.7084,
                                -41.937
                            ],
                            [
                                23.1576,
                                -42.3247
                            ],
                            [
                                21.8652,
                                -42.3247
                            ],
                            [
                                20.7021,
                                -41.937
                            ],
                            [
                                18.5051,
                                -41.2908
                            ],
                            [
                                17.2128,
                                -40.2569
                            ],
                            [
                                15.4035,
                                -38.3184
                            ],
                            [
                                13.3357,
                                -35.0875
                            ],
                            [
                                11.1387,
                                -31.0812
                            ],
                            [
                                9.8463,
                                -28.6257
                            ],
                            [
                                10.1048,
                                -27.721
                            ],
                            [
                                9.7171,
                                -26.041
                            ],
                            [
                                8.6832,
                                -23.844
                            ],
                            [
                                8.037,
                                -21.9054
                            ],
                            [
                                6.4862,
                                -20.0961
                            ],
                            [
                                6.6154,
                                -18.933
                            ],
                            [
                                6.6154,
                                -17.3822
                            ],
                            [
                                6.7447,
                                -16.4775
                            ],
                            [
                                5.84,
                                -15.0559
                            ],
                            [
                                5.4523,
                                -13.3759
                            ],
                            [
                                5.1939,
                                -11.9543
                            ],
                            [
                                7.0032,
                                -9.8865
                            ],
                            [
                                7.5201,
                                -8.8526
                            ],
                            [
                                7.5201,
                                -7.1726
                            ],
                            [
                                7.0032,
                                -4.4586
                            ],
                            [
                                5.9693,
                                -2.3909
                            ],
                            [
                                4.6769,
                                -0.84
                            ],
                            [
                                3.7723,
                                -0.5816
                            ],
                            [
                                2.4799,
                                0.0646
                            ],
                            [
                                0.6706,
                                0.3231
                            ],
                            [
                                -2.1726,
                                -0.5816
                            ],
                            [
                                -3.3357,
                                -0.1939
                            ],
                            [
                                -6.1789,
                                0.9693
                            ],
                            [
                                -9.4098,
                                3.4247
                            ],
                            [
                                -12.253,
                                4.5879
                            ],
                            [
                                -13.5453,
                                4.9756
                            ],
                            [
                                -15.6131,
                                6.7849
                            ],
                            [
                                -17.2931,
                                7.8188
                            ],
                            [
                                -18.7147,
                                8.2065
                            ],
                            [
                                -20.3948,
                                10.145
                            ],
                            [
                                -21.5579,
                                11.0496
                            ],
                            [
                                -22.8503,
                                11.3081
                            ],
                            [
                                -24.5303,
                                12.4712
                            ],
                            [
                                -25.3058,
                                13.8928
                            ],
                            [
                                -26.2104,
                                15.9606
                            ],
                            [
                                -25.435,
                                18.5453
                            ],
                            [
                                -23.4965,
                                21.13
                            ],
                            [
                                -22.721,
                                22.1639
                            ],
                            [
                                -20.2656,
                                22.8101
                            ],
                            [
                                -17.8101,
                                22.9393
                            ],
                            [
                                -15.4838,
                                24.1024
                            ],
                            [
                                -11.9945,
                                23.7147
                            ],
                            [
                                -7.8589,
                                22.0347
                            ],
                            [
                                -5.9204,
                                20.8716
                            ],
                            [
                                2.2214,
                                19.5792
                            ],
                            [
                                5.84,
                                20.3546
                            ],
                            [
                                8.2955,
                                20.0961
                            ],
                            [
                                12.1726,
                                19.5792
                            ],
                            [
                                15.5327,
                                19.0623
                            ],
                            [
                                17.0835,
                                16.9945
                            ],
                            [
                                18.6344,
                                15.056
                            ],
                            [
                                20.1852,
                                11.9543
                            ],
                            [
                                20.4437,
                                9.6281
                            ],
                            [
                                22.8991,
                                9.1111
                            ],
                            [
                                25.7423,
                                9.1111
                            ],
                            [
                                27.6808,
                                9.1111
                            ],
                            [
                                29.7486,
                                8.9819
                            ],
                            [
                                31.1702,
                                9.6281
                            ],
                            [
                                34.5303,
                                9.1111
                            ],
                            [
                                36.5981,
                                8.7234
                            ],
                            [
                                39.1828,
                                9.1111
                            ],
                            [
                                42.2845,
                                8.9819
                            ],
                            [
                                44.8692,
                                8.7234
                            ],
                            [
                                46.937,
                                8.0772
                            ],
                            [
                                49.0047,
                                7.8188
                            ],
                            [
                                50.1679,
                                7.4311
                            ],
                            [
                                51.2017,
                                6.9141
                            ],
                            [
                                51.9771,
                                4.8463
                            ],
                            [
                                51.4602,
                                4.4586
                            ],
                            [
                                51.0725,
                                3.554
                            ],
                            [
                                50.6848,
                                3.1663
                            ],
                            [
                                50.4263,
                                2.5201
                            ],
                            [
                                50.1679,
                                2.5201
                            ]
                        ]
                    ]
                },
                "type": "Feature",
                "properties": {
                    "obj_child_num": 0,
                    "obj_revision": 5,
                    "obj_id": 7543,
                    "obj_version": "",
                    "gain": 0,
                    "obj_name": "Astra4A_AF17_BTZ",
                    "obj_parentid": 2103
                }
            },
            {
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                50.1679,
                                2.5201
                            ],
                            [
                                49.2632,
                                1.357
                            ],
                            [
                                48.7463,
                                0.1939
                            ],
                            [
                                48.617,
                                -1.7447
                            ],
                            [
                                47.8416,
                                -2.9078
                            ],
                            [
                                47.3247,
                                -4.4586
                            ],
                            [
                                46.8077,
                                -6.2679
                            ],
                            [
                                46.937,
                                -7.3018
                            ],
                            [
                                47.0662,
                                -8.7234
                            ],
                            [
                                46.6785,
                                -9.7573
                            ],
                            [
                                46.5492,
                                -11.8251
                            ],
                            [
                                45.9031,
                                -14.0221
                            ],
                            [
                                44.223,
                                -15.0559
                            ],
                            [
                                45.3861,
                                -16.9945
                            ],
                            [
                                44.9984,
                                -19.3207
                            ],
                            [
                                44.0938,
                                -22.1639
                            ],
                            [
                                44.223,
                                -24.2317
                            ],
                            [
                                44.3522,
                                -26.6872
                            ],
                            [
                                44.6107,
                                -28.7549
                            ],
                            [
                                43.8353,
                                -30.1765
                            ],
                            [
                                43.1891,
                                -32.115
                            ],
                            [
                                42.9307,
                                -32.8905
                            ],
                            [
                                42.1552,
                                -34.1828
                            ],
                            [
                                39.3121,
                                -36.5091
                            ],
                            [
                                38.2782,
                                -37.1552
                            ],
                            [
                                36.9858,
                                -38.7061
                            ],
                            [
                                36.0812,
                                -39.7399
                            ],
                            [
                                35.5642,
                                -40.3861
                            ],
                            [
                                34.1426,
                                -40.7738
                            ],
                            [
                                31.6872,
                                -41.1615
                            ],
                            [
                                30.0071,
                                -41.5493
                            ],
                            [
                                26.9054,
                                -41.42
                            ],
                            [
                                24.7084,
                                -41.937
                            ],
                            [
                                23.1576,
                                -42.3247
                            ],
                            [
                                21.8652,
                                -42.3247
                            ],
                            [
                                20.7021,
                                -41.937
                            ],
                            [
                                18.5051,
                                -41.2908
                            ],
                            [
                                17.2128,
                                -40.2569
                            ],
                            [
                                15.4035,
                                -38.3184
                            ],
                            [
                                13.3357,
                                -35.0875
                            ],
                            [
                                11.1387,
                                -31.0812
                            ],
                            [
                                9.8463,
                                -28.6257
                            ],
                            [
                                10.1048,
                                -27.721
                            ],
                            [
                                9.7171,
                                -26.041
                            ],
                            [
                                8.6832,
                                -23.844
                            ],
                            [
                                8.037,
                                -21.9054
                            ],
                            [
                                6.4862,
                                -20.0961
                            ],
                            [
                                6.6154,
                                -18.933
                            ],
                            [
                                6.6154,
                                -17.3822
                            ],
                            [
                                6.7447,
                                -16.4775
                            ],
                            [
                                5.84,
                                -15.0559
                            ],
                            [
                                5.4523,
                                -13.3759
                            ],
                            [
                                5.1939,
                                -11.9543
                            ],
                            [
                                7.0032,
                                -9.8865
                            ],
                            [
                                7.5201,
                                -8.8526
                            ],
                            [
                                7.5201,
                                -7.1726
                            ],
                            [
                                7.0032,
                                -4.4586
                            ],
                            [
                                5.9693,
                                -2.3909
                            ],
                            [
                                4.6769,
                                -0.84
                            ],
                            [
                                3.7723,
                                -0.5816
                            ],
                            [
                                2.4799,
                                0.0646
                            ],
                            [
                                0.6706,
                                0.3231
                            ],
                            [
                                -2.1726,
                                -0.5816
                            ],
                            [
                                -3.3357,
                                -0.1939
                            ],
                            [
                                -6.1789,
                                0.9693
                            ],
                            [
                                -9.4098,
                                3.4247
                            ],
                            [
                                -12.253,
                                4.5879
                            ],
                            [
                                -13.5453,
                                4.9756
                            ],
                            [
                                -15.6131,
                                6.7849
                            ],
                            [
                                -17.2931,
                                7.8188
                            ],
                            [
                                -18.7147,
                                8.2065
                            ],
                            [
                                -20.3948,
                                10.145
                            ],
                            [
                                -21.5579,
                                11.0496
                            ],
                            [
                                -22.8503,
                                11.3081
                            ],
                            [
                                -24.5303,
                                12.4712
                            ],
                            [
                                -25.3058,
                                13.8928
                            ],
                            [
                                -26.2104,
                                15.9606
                            ],
                            [
                                -25.435,
                                18.5453
                            ],
                            [
                                -23.4965,
                                21.13
                            ],
                            [
                                -22.721,
                                22.1639
                            ],
                            [
                                -20.2656,
                                22.8101
                            ],
                            [
                                -17.8101,
                                22.9393
                            ],
                            [
                                -15.4838,
                                24.1024
                            ],
                            [
                                -11.9945,
                                23.7147
                            ],
                            [
                                -7.8589,
                                22.0347
                            ],
                            [
                                -5.9204,
                                20.8716
                            ],
                            [
                                2.2214,
                                19.5792
                            ],
                            [
                                5.84,
                                20.3546
                            ],
                            [
                                8.2955,
                                20.0961
                            ],
                            [
                                12.1726,
                                19.5792
                            ],
                            [
                                15.5327,
                                19.0623
                            ],
                            [
                                17.0835,
                                16.9945
                            ],
                            [
                                18.6344,
                                15.056
                            ],
                            [
                                20.1852,
                                11.9543
                            ],
                            [
                                20.4437,
                                9.6281
                            ],
                            [
                                22.8991,
                                9.1111
                            ],
                            [
                                25.7423,
                                9.1111
                            ],
                            [
                                27.6808,
                                9.1111
                            ],
                            [
                                29.7486,
                                8.9819
                            ],
                            [
                                31.1702,
                                9.6281
                            ],
                            [
                                34.5303,
                                9.1111
                            ],
                            [
                                36.5981,
                                8.7234
                            ],
                            [
                                39.1828,
                                9.1111
                            ],
                            [
                                42.2845,
                                8.9819
                            ],
                            [
                                44.8692,
                                8.7234
                            ],
                            [
                                46.937,
                                8.0772
                            ],
                            [
                                49.0047,
                                7.8188
                            ],
                            [
                                50.1679,
                                7.4311
                            ],
                            [
                                51.2017,
                                6.9141
                            ],
                            [
                                51.9771,
                                4.8463
                            ],
                            [
                                51.4602,
                                4.4586
                            ],
                            [
                                51.0725,
                                3.554
                            ],
                            [
                                50.6848,
                                3.1663
                            ],
                            [
                                50.4263,
                                2.5201
                            ],
                            [
                                50.1679,
                                2.5201
                            ]
                        ]
                    ]
                },
                "type": "Feature",
                "properties": {
                    "obj_child_num": 0,
                    "obj_revision": 5,
                    "obj_id": 7543,
                    "obj_version": "",
                    "gain": 4500,
                    "obj_name": "Astra4A_AF17_BTZ",
                    "obj_parentid": 2103
                }
            },
            {
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -14.0784,
                                80.301
                            ],
                            [
                                -11.0482,
                                78.0788
                            ],
                            [
                                -10.6442,
                                73.6345
                            ],
                            [
                                -11.4522,
                                70.8063
                            ],
                            [
                                -14.4824,
                                67.5741
                            ],
                            [
                                -17.7147,
                                67.5741
                            ],
                            [
                                -21.553,
                                68.1801
                            ],
                            [
                                -24.9872,
                                67.17
                            ],
                            [
                                -25.1892,
                                63.5338
                            ],
                            [
                                -21.553,
                                61.5136
                            ],
                            [
                                -16.9066,
                                60.9076
                            ],
                            [
                                -18.5227,
                                57.4733
                            ],
                            [
                                -17.9167,
                                54.4431
                            ],
                            [
                                -16.0986,
                                49.7967
                            ],
                            [
                                -16.7046,
                                47.5746
                            ],
                            [
                                -17.9167,
                                46.3625
                            ],
                            [
                                -17.1086,
                                41.7161
                            ],
                            [
                                -13.6744,
                                35.4537
                            ],
                            [
                                -12.0583,
                                32.8275
                            ],
                            [
                                -9.4321,
                                32.4234
                            ],
                            [
                                -7.6139,
                                32.6254
                            ],
                            [
                                -5.7958,
                                31.8174
                            ],
                            [
                                -2.3615,
                                33.2315
                            ],
                            [
                                0.8707,
                                33.0295
                            ],
                            [
                                5.113,
                                34.8476
                            ],
                            [
                                7.1332,
                                34.8476
                            ],
                            [
                                8.9513,
                                34.6456
                            ],
                            [
                                10.5674,
                                33.2315
                            ],
                            [
                                11.7795,
                                31.4134
                            ],
                            [
                                13.1936,
                                30.8073
                            ],
                            [
                                14.8097,
                                31.4134
                            ],
                            [
                                15.4158,
                                33.4335
                            ],
                            [
                                17.0319,
                                33.8375
                            ],
                            [
                                19.0521,
                                32.2214
                            ],
                            [
                                22.2843,
                                31.6154
                            ],
                            [
                                24.5065,
                                31.4134
                            ],
                            [
                                26.7286,
                                30.4033
                            ],
                            [
                                29.9609,
                                30.2013
                            ],
                            [
                                33.1931,
                                30.2013
                            ],
                            [
                                35.8193,
                                30.4033
                            ],
                            [
                                39.2536,
                                31.4134
                            ],
                            [
                                39.4556,
                                30.6053
                            ],
                            [
                                38.8495,
                                29.1912
                            ],
                            [
                                39.6576,
                                28.5851
                            ],
                            [
                                41.4757,
                                29.7972
                            ],
                            [
                                42.2838,
                                31.2113
                            ],
                            [
                                44.708,
                                32.0194
                            ],
                            [
                                47.9402,
                                33.8375
                            ],
                            [
                                51.3745,
                                35.6557
                            ],
                            [
                                53.5967,
                                37.0698
                            ],
                            [
                                55.8188,
                                38.4839
                            ],
                            [
                                58.243,
                                40.504
                            ],
                            [
                                61.0712,
                                43.3322
                            ],
                            [
                                64.1014,
                                46.1605
                            ],
                            [
                                65.5155,
                                46.9685
                            ],
                            [
                                67.7377,
                                48.1806
                            ],
                            [
                                70.7679,
                                49.5947
                            ],
                            [
                                74.0002,
                                50.4028
                            ],
                            [
                                76.8284,
                                51.2108
                            ],
                            [
                                78.8485,
                                51.8169
                            ],
                            [
                                80.0606,
                                52.4229
                            ],
                            [
                                80.4416,
                                52.499
                            ],
                            [
                                79.8282,
                                54.2403
                            ],
                            [
                                79.1461,
                                55.9763
                            ],
                            [
                                78.3846,
                                57.7063
                            ],
                            [
                                77.5307,
                                59.429
                            ],
                            [
                                76.5686,
                                61.1433
                            ],
                            [
                                75.4784,
                                62.8475
                            ],
                            [
                                74.2359,
                                64.5396
                            ],
                            [
                                72.8098,
                                66.2169
                            ],
                            [
                                71.1609,
                                67.8762
                            ],
                            [
                                69.2386,
                                69.5129
                            ],
                            [
                                66.9772,
                                71.1213
                            ],
                            [
                                64.2909,
                                72.6934
                            ],
                            [
                                61.0674,
                                74.2183
                            ],
                            [
                                57.1603,
                                75.681
                            ],
                            [
                                52.3829,
                                77.0604
                            ],
                            [
                                46.5084,
                                78.3268
                            ],
                            [
                                39.2936,
                                79.4395
                            ],
                            [
                                30.5497,
                                80.3452
                            ],
                            [
                                20.2878,
                                80.9814
                            ],
                            [
                                8.8952,
                                81.2887
                            ],
                            [
                                -2.8299,
                                81.2326
                            ],
                            [
                                -13.9455,
                                80.8196
                            ],
                            [
                                -14.0784,
                                80.301
                            ]
                        ]
                    ]
                },
                "type": "Feature",
                "properties": {
                    "obj_child_num": 0,
                    "obj_revision": 6,
                    "obj_id": 23701,
                    "obj_version": "",
                    "gain": 0,
                    "obj_name": "Astra4A_EU03_BTZ",
                    "obj_parentid": 2103
                }
            },
            {
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -14.0784,
                                80.301
                            ],
                            [
                                -11.0482,
                                78.0788
                            ],
                            [
                                -10.6442,
                                73.6345
                            ],
                            [
                                -11.4522,
                                70.8063
                            ],
                            [
                                -14.4824,
                                67.5741
                            ],
                            [
                                -17.7147,
                                67.5741
                            ],
                            [
                                -21.553,
                                68.1801
                            ],
                            [
                                -24.9872,
                                67.17
                            ],
                            [
                                -25.1892,
                                63.5338
                            ],
                            [
                                -21.553,
                                61.5136
                            ],
                            [
                                -16.9066,
                                60.9076
                            ],
                            [
                                -18.5227,
                                57.4733
                            ],
                            [
                                -17.9167,
                                54.4431
                            ],
                            [
                                -16.0986,
                                49.7967
                            ],
                            [
                                -16.7046,
                                47.5746
                            ],
                            [
                                -17.9167,
                                46.3625
                            ],
                            [
                                -17.1086,
                                41.7161
                            ],
                            [
                                -13.6744,
                                35.4537
                            ],
                            [
                                -12.0583,
                                32.8275
                            ],
                            [
                                -9.4321,
                                32.4234
                            ],
                            [
                                -7.6139,
                                32.6254
                            ],
                            [
                                -5.7958,
                                31.8174
                            ],
                            [
                                -2.3615,
                                33.2315
                            ],
                            [
                                0.8707,
                                33.0295
                            ],
                            [
                                5.113,
                                34.8476
                            ],
                            [
                                7.1332,
                                34.8476
                            ],
                            [
                                8.9513,
                                34.6456
                            ],
                            [
                                10.5674,
                                33.2315
                            ],
                            [
                                11.7795,
                                31.4134
                            ],
                            [
                                13.1936,
                                30.8073
                            ],
                            [
                                14.8097,
                                31.4134
                            ],
                            [
                                15.4158,
                                33.4335
                            ],
                            [
                                17.0319,
                                33.8375
                            ],
                            [
                                19.0521,
                                32.2214
                            ],
                            [
                                22.2843,
                                31.6154
                            ],
                            [
                                24.5065,
                                31.4134
                            ],
                            [
                                26.7286,
                                30.4033
                            ],
                            [
                                29.9609,
                                30.2013
                            ],
                            [
                                33.1931,
                                30.2013
                            ],
                            [
                                35.8193,
                                30.4033
                            ],
                            [
                                39.2536,
                                31.4134
                            ],
                            [
                                39.4556,
                                30.6053
                            ],
                            [
                                38.8495,
                                29.1912
                            ],
                            [
                                39.6576,
                                28.5851
                            ],
                            [
                                41.4757,
                                29.7972
                            ],
                            [
                                42.2838,
                                31.2113
                            ],
                            [
                                44.708,
                                32.0194
                            ],
                            [
                                47.9402,
                                33.8375
                            ],
                            [
                                51.3745,
                                35.6557
                            ],
                            [
                                53.5967,
                                37.0698
                            ],
                            [
                                55.8188,
                                38.4839
                            ],
                            [
                                58.243,
                                40.504
                            ],
                            [
                                61.0712,
                                43.3322
                            ],
                            [
                                64.1014,
                                46.1605
                            ],
                            [
                                65.5155,
                                46.9685
                            ],
                            [
                                67.7377,
                                48.1806
                            ],
                            [
                                70.7679,
                                49.5947
                            ],
                            [
                                74.0002,
                                50.4028
                            ],
                            [
                                76.8284,
                                51.2108
                            ],
                            [
                                78.8485,
                                51.8169
                            ],
                            [
                                80.0606,
                                52.4229
                            ],
                            [
                                80.4416,
                                52.499
                            ],
                            [
                                79.8282,
                                54.2403
                            ],
                            [
                                79.1461,
                                55.9763
                            ],
                            [
                                78.3846,
                                57.7063
                            ],
                            [
                                77.5307,
                                59.429
                            ],
                            [
                                76.5686,
                                61.1433
                            ],
                            [
                                75.4784,
                                62.8475
                            ],
                            [
                                74.2359,
                                64.5396
                            ],
                            [
                                72.8098,
                                66.2169
                            ],
                            [
                                71.1609,
                                67.8762
                            ],
                            [
                                69.2386,
                                69.5129
                            ],
                            [
                                66.9772,
                                71.1213
                            ],
                            [
                                64.2909,
                                72.6934
                            ],
                            [
                                61.0674,
                                74.2183
                            ],
                            [
                                57.1603,
                                75.681
                            ],
                            [
                                52.3829,
                                77.0604
                            ],
                            [
                                46.5084,
                                78.3268
                            ],
                            [
                                39.2936,
                                79.4395
                            ],
                            [
                                30.5497,
                                80.3452
                            ],
                            [
                                20.2878,
                                80.9814
                            ],
                            [
                                8.8952,
                                81.2887
                            ],
                            [
                                -2.8299,
                                81.2326
                            ],
                            [
                                -13.9455,
                                80.8196
                            ],
                            [
                                -14.0784,
                                80.301
                            ]
                        ]
                    ]
                },
                "type": "Feature",
                "properties": {
                    "obj_child_num": 0,
                    "obj_revision": 6,
                    "obj_id": 23701,
                    "obj_version": "",
                    "gain": 4500,
                    "obj_name": "Astra4A_EU03_BTZ",
                    "obj_parentid": 2103
                }
            },
            {
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -99.222,
                                34.724
                            ],
                            [
                                -88.143,
                                33.628
                            ],
                            [
                                -80.852,
                                32.276
                            ],
                            [
                                -77.776,
                                31.567
                            ],
                            [
                                -75.803,
                                31.064
                            ],
                            [
                                -71.85,
                                30.021
                            ],
                            [
                                -69.749,
                                29.5
                            ],
                            [
                                -68.596,
                                29.185
                            ],
                            [
                                -65.846,
                                28.59
                            ],
                            [
                                -63.449,
                                28.213
                            ],
                            [
                                -61.252,
                                27.929
                            ],
                            [
                                -59.131,
                                27.567
                            ],
                            [
                                -58.454,
                                27.378
                            ],
                            [
                                -56.965,
                                26.882
                            ],
                            [
                                -54.934,
                                25.819
                            ],
                            [
                                -54.703,
                                25.663
                            ],
                            [
                                -53.251,
                                24.393
                            ],
                            [
                                -52.173,
                                23.18
                            ],
                            [
                                -52.043,
                                23.017
                            ],
                            [
                                -51.115,
                                21.678
                            ],
                            [
                                -50.417,
                                20.367
                            ],
                            [
                                -49.952,
                                19.083
                            ],
                            [
                                -49.764,
                                17.824
                            ],
                            [
                                -49.851,
                                16.585
                            ],
                            [
                                -50.215,
                                15.46
                            ],
                            [
                                -50.253,
                                15.367
                            ],
                            [
                                -51.045,
                                14.167
                            ],
                            [
                                -51.377,
                                13.773
                            ],
                            [
                                -52.128,
                                12.98
                            ],
                            [
                                -52.623,
                                12.344
                            ],
                            [
                                -53.019,
                                11.793
                            ],
                            [
                                -53.314,
                                10.599
                            ],
                            [
                                -53.172,
                                9.402
                            ],
                            [
                                -52.877,
                                8.21
                            ],
                            [
                                -52.606,
                                7.024
                            ],
                            [
                                -52.469,
                                5.846
                            ],
                            [
                                -52.543,
                                4.673
                            ],
                            [
                                -52.873,
                                3.504
                            ],
                            [
                                -53.131,
                                2.939
                            ],
                            [
                                -53.481,
                                2.338
                            ],
                            [
                                -54.455,
                                1.171
                            ],
                            [
                                -54.58,
                                1.048
                            ],
                            [
                                -56.117,
                                0.049
                            ],
                            [
                                -56.205,
                                0
                            ],
                            [
                                -57.715,
                                -0.782
                            ],
                            [
                                -58.358,
                                -1.18
                            ],
                            [
                                -59.389,
                                -1.885
                            ],
                            [
                                -59.96,
                                -2.369
                            ],
                            [
                                -61.136,
                                -3.564
                            ],
                            [
                                -61.195,
                                -3.679
                            ],
                            [
                                -61.737,
                                -4.761
                            ],
                            [
                                -61.581,
                                -5.955
                            ],
                            [
                                -61.429,
                                -6.167
                            ],
                            [
                                -60.569,
                                -7.138
                            ],
                            [
                                -59.904,
                                -7.819
                            ],
                            [
                                -59.431,
                                -8.318
                            ],
                            [
                                -58.799,
                                -9.508
                            ],
                            [
                                -58.663,
                                -10.604
                            ],
                            [
                                -58.652,
                                -10.712
                            ],
                            [
                                -58.913,
                                -11.931
                            ],
                            [
                                -58.931,
                                -11.969
                            ],
                            [
                                -59.518,
                                -13.17
                            ],
                            [
                                -60.334,
                                -14.426
                            ],
                            [
                                -61.364,
                                -15.704
                            ],
                            [
                                -61.787,
                                -16.124
                            ],
                            [
                                -62.75,
                                -17.012
                            ],
                            [
                                -64.444,
                                -18.197
                            ],
                            [
                                -64.73,
                                -18.365
                            ],
                            [
                                -67.059,
                                -19.452
                            ],
                            [
                                -68.115,
                                -19.809
                            ],
                            [
                                -69.72,
                                -20.273
                            ],
                            [
                                -72.388,
                                -20.656
                            ],
                            [
                                -75.013,
                                -20.585
                            ],
                            [
                                -76.618,
                                -20.225
                            ],
                            [
                                -77.494,
                                -19.967
                            ],
                            [
                                -79.271,
                                -19.027
                            ],
                            [
                                -79.723,
                                -18.697
                            ],
                            [
                                -80.878,
                                -17.778
                            ],
                            [
                                -81.795,
                                -16.87
                            ],
                            [
                                -82.204,
                                -16.52
                            ],
                            [
                                -83.403,
                                -15.259
                            ],
                            [
                                -83.945,
                                -14.654
                            ],
                            [
                                -84.561,
                                -13.998
                            ],
                            [
                                -85.555,
                                -12.733
                            ],
                            [
                                -86.137,
                                -11.735
                            ],
                            [
                                -86.304,
                                -11.461
                            ],
                            [
                                -86.926,
                                -10.188
                            ],
                            [
                                -87.472,
                                -8.914
                            ],
                            [
                                -88.088,
                                -7.644
                            ],
                            [
                                -88.355,
                                -7.233
                            ],
                            [
                                -88.981,
                                -6.379
                            ],
                            [
                                -90.147,
                                -5.115
                            ],
                            [
                                -91.38,
                                -3.846
                            ],
                            [
                                -92.617,
                                -2.572
                            ],
                            [
                                -92.994,
                                -2.205
                            ],
                            [
                                -94.192,
                                -1.291
                            ],
                            [
                                -96.829,
                                0
                            ],
                            [
                                -101.158,
                                0.602
                            ],
                            [
                                -101.307,
                                1.7308
                            ],
                            [
                                -101.295,
                                3.5101
                            ],
                            [
                                -101.274,
                                5.2893
                            ],
                            [
                                -101.244,
                                7.0684
                            ],
                            [
                                -101.206,
                                8.8473
                            ],
                            [
                                -101.158,
                                10.626
                            ],
                            [
                                -101.102,
                                12.4045
                            ],
                            [
                                -101.036,
                                14.1827
                            ],
                            [
                                -100.96,
                                15.9605
                            ],
                            [
                                -100.874,
                                17.738
                            ],
                            [
                                -100.777,
                                19.5149
                            ],
                            [
                                -100.669,
                                21.2914
                            ],
                            [
                                -100.549,
                                23.0673
                            ],
                            [
                                -100.417,
                                24.8425
                            ],
                            [
                                -100.272,
                                26.617
                            ],
                            [
                                -100.112,
                                28.3907
                            ],
                            [
                                -99.937,
                                30.1635
                            ],
                            [
                                -99.7459,
                                31.9353
                            ],
                            [
                                -99.5371,
                                33.706
                            ],
                            [
                                -99.222,
                                34.724
                            ]
                        ]
                    ]
                },
                "type": "Feature",
                "properties": {
                    "obj_child_num": 0,
                    "obj_revision": 4,
                    "obj_id": 7548,
                    "obj_version": "",
                    "gain": 0,
                    "obj_name": "NSS-7_CA01_WMP",
                    "obj_parentid": 1730
                }
            },
            {
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -99.222,
                                34.724
                            ],
                            [
                                -88.143,
                                33.628
                            ],
                            [
                                -80.852,
                                32.276
                            ],
                            [
                                -77.776,
                                31.567
                            ],
                            [
                                -75.803,
                                31.064
                            ],
                            [
                                -71.85,
                                30.021
                            ],
                            [
                                -69.749,
                                29.5
                            ],
                            [
                                -68.596,
                                29.185
                            ],
                            [
                                -65.846,
                                28.59
                            ],
                            [
                                -63.449,
                                28.213
                            ],
                            [
                                -61.252,
                                27.929
                            ],
                            [
                                -59.131,
                                27.567
                            ],
                            [
                                -58.454,
                                27.378
                            ],
                            [
                                -56.965,
                                26.882
                            ],
                            [
                                -54.934,
                                25.819
                            ],
                            [
                                -54.703,
                                25.663
                            ],
                            [
                                -53.251,
                                24.393
                            ],
                            [
                                -52.173,
                                23.18
                            ],
                            [
                                -52.043,
                                23.017
                            ],
                            [
                                -51.115,
                                21.678
                            ],
                            [
                                -50.417,
                                20.367
                            ],
                            [
                                -49.952,
                                19.083
                            ],
                            [
                                -49.764,
                                17.824
                            ],
                            [
                                -49.851,
                                16.585
                            ],
                            [
                                -50.215,
                                15.46
                            ],
                            [
                                -50.253,
                                15.367
                            ],
                            [
                                -51.045,
                                14.167
                            ],
                            [
                                -51.377,
                                13.773
                            ],
                            [
                                -52.128,
                                12.98
                            ],
                            [
                                -52.623,
                                12.344
                            ],
                            [
                                -53.019,
                                11.793
                            ],
                            [
                                -53.314,
                                10.599
                            ],
                            [
                                -53.172,
                                9.402
                            ],
                            [
                                -52.877,
                                8.21
                            ],
                            [
                                -52.606,
                                7.024
                            ],
                            [
                                -52.469,
                                5.846
                            ],
                            [
                                -52.543,
                                4.673
                            ],
                            [
                                -52.873,
                                3.504
                            ],
                            [
                                -53.131,
                                2.939
                            ],
                            [
                                -53.481,
                                2.338
                            ],
                            [
                                -54.455,
                                1.171
                            ],
                            [
                                -54.58,
                                1.048
                            ],
                            [
                                -56.117,
                                0.049
                            ],
                            [
                                -56.205,
                                0
                            ],
                            [
                                -57.715,
                                -0.782
                            ],
                            [
                                -58.358,
                                -1.18
                            ],
                            [
                                -59.389,
                                -1.885
                            ],
                            [
                                -59.96,
                                -2.369
                            ],
                            [
                                -61.136,
                                -3.564
                            ],
                            [
                                -61.195,
                                -3.679
                            ],
                            [
                                -61.737,
                                -4.761
                            ],
                            [
                                -61.581,
                                -5.955
                            ],
                            [
                                -61.429,
                                -6.167
                            ],
                            [
                                -60.569,
                                -7.138
                            ],
                            [
                                -59.904,
                                -7.819
                            ],
                            [
                                -59.431,
                                -8.318
                            ],
                            [
                                -58.799,
                                -9.508
                            ],
                            [
                                -58.663,
                                -10.604
                            ],
                            [
                                -58.652,
                                -10.712
                            ],
                            [
                                -58.913,
                                -11.931
                            ],
                            [
                                -58.931,
                                -11.969
                            ],
                            [
                                -59.518,
                                -13.17
                            ],
                            [
                                -60.334,
                                -14.426
                            ],
                            [
                                -61.364,
                                -15.704
                            ],
                            [
                                -61.787,
                                -16.124
                            ],
                            [
                                -62.75,
                                -17.012
                            ],
                            [
                                -64.444,
                                -18.197
                            ],
                            [
                                -64.73,
                                -18.365
                            ],
                            [
                                -67.059,
                                -19.452
                            ],
                            [
                                -68.115,
                                -19.809
                            ],
                            [
                                -69.72,
                                -20.273
                            ],
                            [
                                -72.388,
                                -20.656
                            ],
                            [
                                -75.013,
                                -20.585
                            ],
                            [
                                -76.618,
                                -20.225
                            ],
                            [
                                -77.494,
                                -19.967
                            ],
                            [
                                -79.271,
                                -19.027
                            ],
                            [
                                -79.723,
                                -18.697
                            ],
                            [
                                -80.878,
                                -17.778
                            ],
                            [
                                -81.795,
                                -16.87
                            ],
                            [
                                -82.204,
                                -16.52
                            ],
                            [
                                -83.403,
                                -15.259
                            ],
                            [
                                -83.945,
                                -14.654
                            ],
                            [
                                -84.561,
                                -13.998
                            ],
                            [
                                -85.555,
                                -12.733
                            ],
                            [
                                -86.137,
                                -11.735
                            ],
                            [
                                -86.304,
                                -11.461
                            ],
                            [
                                -86.926,
                                -10.188
                            ],
                            [
                                -87.472,
                                -8.914
                            ],
                            [
                                -88.088,
                                -7.644
                            ],
                            [
                                -88.355,
                                -7.233
                            ],
                            [
                                -88.981,
                                -6.379
                            ],
                            [
                                -90.147,
                                -5.115
                            ],
                            [
                                -91.38,
                                -3.846
                            ],
                            [
                                -92.617,
                                -2.572
                            ],
                            [
                                -92.994,
                                -2.205
                            ],
                            [
                                -94.192,
                                -1.291
                            ],
                            [
                                -96.829,
                                0
                            ],
                            [
                                -101.158,
                                0.602
                            ],
                            [
                                -101.307,
                                1.7308
                            ],
                            [
                                -101.295,
                                3.5101
                            ],
                            [
                                -101.274,
                                5.2893
                            ],
                            [
                                -101.244,
                                7.0684
                            ],
                            [
                                -101.206,
                                8.8473
                            ],
                            [
                                -101.158,
                                10.626
                            ],
                            [
                                -101.102,
                                12.4045
                            ],
                            [
                                -101.036,
                                14.1827
                            ],
                            [
                                -100.96,
                                15.9605
                            ],
                            [
                                -100.874,
                                17.738
                            ],
                            [
                                -100.777,
                                19.5149
                            ],
                            [
                                -100.669,
                                21.2914
                            ],
                            [
                                -100.549,
                                23.0673
                            ],
                            [
                                -100.417,
                                24.8425
                            ],
                            [
                                -100.272,
                                26.617
                            ],
                            [
                                -100.112,
                                28.3907
                            ],
                            [
                                -99.937,
                                30.1635
                            ],
                            [
                                -99.7459,
                                31.9353
                            ],
                            [
                                -99.5371,
                                33.706
                            ],
                            [
                                -99.222,
                                34.724
                            ]
                        ]
                    ]
                },
                "type": "Feature",
                "properties": {
                    "obj_child_num": 0,
                    "obj_revision": 4,
                    "obj_id": 7548,
                    "obj_version": "",
                    "gain": 4500,
                    "obj_name": "NSS-7_CA01_WMP",
                    "obj_parentid": 1730
                }
            },
            {
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                52.4869,
                                -31.219
                            ],
                            [
                                55.9706,
                                -12.0588
                            ],
                            [
                                45.2516,
                                -7.5033
                            ],
                            [
                                32.6569,
                                -2.8137
                            ],
                            [
                                20.1961,
                                -0.268
                            ],
                            [
                                12.2908,
                                -1.7418
                            ],
                            [
                                8.6732,
                                -5.7614
                            ],
                            [
                                8.4052,
                                -7.1013
                            ],
                            [
                                4.3856,
                                -8.9771
                            ],
                            [
                                -0.0359,
                                -10.8529
                            ],
                            [
                                -0.4379,
                                -11.6569
                            ],
                            [
                                -0.4379,
                                -13.2647
                            ],
                            [
                                0.634,
                                -14.6046
                            ],
                            [
                                4.9216,
                                -19.1601
                            ],
                            [
                                9.7451,
                                -23.5817
                            ],
                            [
                                12.0229,
                                -25.0556
                            ],
                            [
                                10.683,
                                -28.6732
                            ],
                            [
                                11.8889,
                                -32.2909
                            ],
                            [
                                14.3007,
                                -35.9085
                            ],
                            [
                                18.3203,
                                -38.0523
                            ],
                            [
                                22.7418,
                                -38.4543
                            ],
                            [
                                25.8235,
                                -38.3203
                            ],
                            [
                                29.3072,
                                -36.3105
                            ],
                            [
                                30.6471,
                                -33.7647
                            ],
                            [
                                34.3987,
                                -33.3627
                            ],
                            [
                                37.2124,
                                -31.8889
                            ],
                            [
                                40.6961,
                                -31.4869
                            ],
                            [
                                47.1275,
                                -31.7549
                            ],
                            [
                                50.2092,
                                -31.6209
                            ],
                            [
                                52.4869,
                                -31.4869
                            ],
                            [
                                52.4869,
                                -31.219
                            ]
                        ]
                    ]
                },
                "type": "Feature",
                "properties": {
                    "obj_child_num": 0,
                    "obj_revision": 5,
                    "obj_id": 7556,
                    "obj_version": "",
                    "gain": 0,
                    "obj_name": "NSS-7_SA01_WMP",
                    "obj_parentid": 1730
                }
            },
            {
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                52.4869,
                                -31.219
                            ],
                            [
                                55.9706,
                                -12.0588
                            ],
                            [
                                45.2516,
                                -7.5033
                            ],
                            [
                                32.6569,
                                -2.8137
                            ],
                            [
                                20.1961,
                                -0.268
                            ],
                            [
                                12.2908,
                                -1.7418
                            ],
                            [
                                8.6732,
                                -5.7614
                            ],
                            [
                                8.4052,
                                -7.1013
                            ],
                            [
                                4.3856,
                                -8.9771
                            ],
                            [
                                -0.0359,
                                -10.8529
                            ],
                            [
                                -0.4379,
                                -11.6569
                            ],
                            [
                                -0.4379,
                                -13.2647
                            ],
                            [
                                0.634,
                                -14.6046
                            ],
                            [
                                4.9216,
                                -19.1601
                            ],
                            [
                                9.7451,
                                -23.5817
                            ],
                            [
                                12.0229,
                                -25.0556
                            ],
                            [
                                10.683,
                                -28.6732
                            ],
                            [
                                11.8889,
                                -32.2909
                            ],
                            [
                                14.3007,
                                -35.9085
                            ],
                            [
                                18.3203,
                                -38.0523
                            ],
                            [
                                22.7418,
                                -38.4543
                            ],
                            [
                                25.8235,
                                -38.3203
                            ],
                            [
                                29.3072,
                                -36.3105
                            ],
                            [
                                30.6471,
                                -33.7647
                            ],
                            [
                                34.3987,
                                -33.3627
                            ],
                            [
                                37.2124,
                                -31.8889
                            ],
                            [
                                40.6961,
                                -31.4869
                            ],
                            [
                                47.1275,
                                -31.7549
                            ],
                            [
                                50.2092,
                                -31.6209
                            ],
                            [
                                52.4869,
                                -31.4869
                            ],
                            [
                                52.4869,
                                -31.219
                            ]
                        ]
                    ]
                },
                "type": "Feature",
                "properties": {
                    "obj_child_num": 0,
                    "obj_revision": 5,
                    "obj_id": 7556,
                    "obj_version": "",
                    "gain": 4500,
                    "obj_name": "NSS-7_SA01_WMP",
                    "obj_parentid": 1730
                }
            },
            {
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -117.815,
                                38.707
                            ],
                            [
                                -117.256,
                                38.603
                            ],
                            [
                                -114.534,
                                38.006
                            ],
                            [
                                -111.993,
                                37.355
                            ],
                            [
                                -109.971,
                                36.791
                            ],
                            [
                                -109.648,
                                36.706
                            ],
                            [
                                -107.567,
                                36.196
                            ],
                            [
                                -105.679,
                                35.797
                            ],
                            [
                                -103.955,
                                35.518
                            ],
                            [
                                -102.373,
                                35.367
                            ],
                            [
                                -100.905,
                                35.334
                            ],
                            [
                                -99.504,
                                35.358
                            ],
                            [
                                -98.113,
                                35.342
                            ],
                            [
                                -96.707,
                                35.233
                            ],
                            [
                                -95.303,
                                35.054
                            ],
                            [
                                -93.923,
                                34.858
                            ],
                            [
                                -92.582,
                                34.679
                            ],
                            [
                                -91.282,
                                34.537
                            ],
                            [
                                -90.022,
                                34.446
                            ],
                            [
                                -88.802,
                                34.416
                            ],
                            [
                                -87.609,
                                34.426
                            ],
                            [
                                -86.423,
                                34.411
                            ],
                            [
                                -85.221,
                                34.288
                            ],
                            [
                                -84.521,
                                34.125
                            ],
                            [
                                -84.002,
                                34.021
                            ],
                            [
                                -82.78,
                                33.65
                            ],
                            [
                                -81.559,
                                33.165
                            ],
                            [
                                -80.884,
                                32.857
                            ],
                            [
                                -80.354,
                                32.63
                            ],
                            [
                                -79.176,
                                32.095
                            ],
                            [
                                -78.345,
                                31.664
                            ],
                            [
                                -78.014,
                                31.498
                            ],
                            [
                                -76.871,
                                30.858
                            ],
                            [
                                -76.385,
                                30.517
                            ],
                            [
                                -75.739,
                                30.1
                            ],
                            [
                                -74.848,
                                29.404
                            ],
                            [
                                -74.626,
                                29.245
                            ],
                            [
                                -73.538,
                                28.363
                            ],
                            [
                                -73.486,
                                28.316
                            ],
                            [
                                -72.475,
                                27.479
                            ],
                            [
                                -72.212,
                                27.25
                            ],
                            [
                                -70.995,
                                26.203
                            ],
                            [
                                -70.417,
                                25.697
                            ],
                            [
                                -69.782,
                                25.172
                            ],
                            [
                                -69.421,
                                24.87
                            ],
                            [
                                -68.459,
                                24.158
                            ],
                            [
                                -68.442,
                                24.145
                            ],
                            [
                                -67.478,
                                23.566
                            ],
                            [
                                -66.7,
                                23.158
                            ],
                            [
                                -66.524,
                                23.074
                            ],
                            [
                                -65.576,
                                22.67
                            ],
                            [
                                -64.634,
                                22.256
                            ],
                            [
                                -64.47,
                                22.176
                            ],
                            [
                                -63.697,
                                21.86
                            ],
                            [
                                -62.766,
                                21.439
                            ],
                            [
                                -62.285,
                                21.21
                            ],
                            [
                                -61.838,
                                21.022
                            ],
                            [
                                -60.912,
                                20.653
                            ],
                            [
                                -59.987,
                                20.308
                            ],
                            [
                                -59.85,
                                20.262
                            ],
                            [
                                -59.066,
                                19.918
                            ],
                            [
                                -58.16,
                                19.315
                            ],
                            [
                                -57.504,
                                18.376
                            ],
                            [
                                -57.315,
                                17.843
                            ],
                            [
                                -57.18,
                                17.436
                            ],
                            [
                                -56.963,
                                16.503
                            ],
                            [
                                -56.747,
                                15.577
                            ],
                            [
                                -56.563,
                                14.921
                            ],
                            [
                                -56.483,
                                14.657
                            ],
                            [
                                -56.256,
                                13.742
                            ],
                            [
                                -56.183,
                                12.832
                            ],
                            [
                                -56.321,
                                11.925
                            ],
                            [
                                -56.728,
                                11.02
                            ],
                            [
                                -56.756,
                                10.984
                            ],
                            [
                                -57.562,
                                10.118
                            ],
                            [
                                -57.694,
                                10.021
                            ],
                            [
                                -58.607,
                                9.49
                            ],
                            [
                                -59.272,
                                9.217
                            ],
                            [
                                -59.508,
                                9.129
                            ],
                            [
                                -60.401,
                                8.898
                            ],
                            [
                                -61.288,
                                8.78
                            ],
                            [
                                -62.171,
                                8.763
                            ],
                            [
                                -63.05,
                                8.863
                            ],
                            [
                                -63.926,
                                9.166
                            ],
                            [
                                -63.982,
                                9.202
                            ],
                            [
                                -64.787,
                                10.091
                            ],
                            [
                                -64.798,
                                10.14
                            ],
                            [
                                -64.923,
                                10.985
                            ],
                            [
                                -64.783,
                                11.824
                            ],
                            [
                                -64.773,
                                11.884
                            ],
                            [
                                -64.613,
                                12.787
                            ],
                            [
                                -64.77,
                                13.179
                            ],
                            [
                                -65.165,
                                13.694
                            ],
                            [
                                -65.657,
                                13.898
                            ],
                            [
                                -66.553,
                                13.824
                            ],
                            [
                                -66.842,
                                13.692
                            ],
                            [
                                -67.446,
                                13.257
                            ],
                            [
                                -67.973,
                                12.785
                            ],
                            [
                                -68.333,
                                12.314
                            ],
                            [
                                -68.861,
                                11.883
                            ],
                            [
                                -69.214,
                                11.552
                            ],
                            [
                                -70.095,
                                11.087
                            ],
                            [
                                -70.338,
                                10.987
                            ],
                            [
                                -70.974,
                                10.588
                            ],
                            [
                                -71.414,
                                10.096
                            ],
                            [
                                -71.837,
                                9.242
                            ],
                            [
                                -71.847,
                                9.207
                            ],
                            [
                                -72.144,
                                8.32
                            ],
                            [
                                -72.603,
                                7.437
                            ],
                            [
                                -72.688,
                                7.326
                            ],
                            [
                                -73.212,
                                6.557
                            ],
                            [
                                -73.551,
                                6.202
                            ],
                            [
                                -74.071,
                                5.68
                            ],
                            [
                                -74.419,
                                5.383
                            ],
                            [
                                -75.236,
                                4.806
                            ],
                            [
                                -75.291,
                                4.765
                            ],
                            [
                                -76.168,
                                4.239
                            ],
                            [
                                -76.983,
                                3.934
                            ],
                            [
                                -77.05,
                                3.899
                            ],
                            [
                                -77.936,
                                3.628
                            ],
                            [
                                -78.829,
                                3.546
                            ],
                            [
                                -79.729,
                                3.609
                            ],
                            [
                                -80.637,
                                3.798
                            ],
                            [
                                -81.06,
                                3.946
                            ],
                            [
                                -81.553,
                                4.076
                            ],
                            [
                                -82.478,
                                4.44
                            ],
                            [
                                -83.207,
                                4.835
                            ],
                            [
                                -83.415,
                                4.936
                            ],
                            [
                                -84.364,
                                5.455
                            ],
                            [
                                -84.82,
                                5.728
                            ],
                            [
                                -85.325,
                                5.998
                            ],
                            [
                                -86.299,
                                6.55
                            ],
                            [
                                -86.428,
                                6.627
                            ],
                            [
                                -87.287,
                                7.099
                            ],
                            [
                                -88.009,
                                7.533
                            ],
                            [
                                -88.292,
                                7.698
                            ],
                            [
                                -89.316,
                                8.302
                            ],
                            [
                                -89.556,
                                8.445
                            ],
                            [
                                -90.358,
                                8.909
                            ],
                            [
                                -91.129,
                                9.366
                            ],
                            [
                                -91.421,
                                9.519
                            ],
                            [
                                -92.493,
                                10.011
                            ],
                            [
                                -93.213,
                                10.304
                            ],
                            [
                                -93.579,
                                10.43
                            ],
                            [
                                -94.675,
                                10.756
                            ],
                            [
                                -95.795,
                                11.109
                            ],
                            [
                                -96.232,
                                11.272
                            ],
                            [
                                -96.946,
                                11.515
                            ],
                            [
                                -98.14,
                                12.024
                            ],
                            [
                                -98.557,
                                12.243
                            ],
                            [
                                -99.382,
                                12.633
                            ],
                            [
                                -100.44,
                                13.218
                            ],
                            [
                                -100.681,
                                13.336
                            ],
                            [
                                -102.016,
                                13.996
                            ],
                            [
                                -102.438,
                                14.21
                            ],
                            [
                                -103.391,
                                14.616
                            ],
                            [
                                -104.812,
                                15.21
                            ],
                            [
                                -104.865,
                                15.231
                            ],
                            [
                                -106.269,
                                15.728
                            ],
                            [
                                -107.665,
                                16.283
                            ],
                            [
                                -107.817,
                                16.348
                            ],
                            [
                                -109.495,
                                17.128
                            ],
                            [
                                -109.893,
                                17.337
                            ],
                            [
                                -111.428,
                                18.381
                            ],
                            [
                                -111.472,
                                18.416
                            ],
                            [
                                -112.811,
                                19.434
                            ],
                            [
                                -113.941,
                                20.355
                            ],
                            [
                                -114.142,
                                20.5
                            ],
                            [
                                -115.848,
                                21.601
                            ],
                            [
                                -116.599,
                                22.043
                            ],
                            [
                                -117.981,
                                22.745
                            ],
                            [
                                -119.427,
                                23.44
                            ],
                            [
                                -120.528,
                                23.936
                            ],
                            [
                                -122.823,
                                24.998
                            ],
                            [
                                -123.185,
                                25.161
                            ],
                            [
                                -125.663,
                                26.403
                            ],
                            [
                                -127.815,
                                27.651
                            ],
                            [
                                -129.611,
                                28.899
                            ],
                            [
                                -131.052,
                                30.145
                            ],
                            [
                                -132.235,
                                31.396
                            ],
                            [
                                -133.367,
                                32.665
                            ],
                            [
                                -133.484,
                                32.789
                            ],
                            [
                                -134.535,
                                33.963
                            ],
                            [
                                -135.639,
                                35.282
                            ],
                            [
                                -135.785,
                                35.541
                            ],
                            [
                                -136.794,
                                36.633
                            ],
                            [
                                -137.626,
                                37.834
                            ],
                            [
                                -137.791,
                                37.999
                            ],
                            [
                                -138.399,
                                39.358
                            ],
                            [
                                -138.33,
                                39.646
                            ],
                            [
                                -135.442,
                                40.387
                            ],
                            [
                                -135.423,
                                40.387
                            ],
                            [
                                -131.234,
                                40.421
                            ],
                            [
                                -127.17,
                                40.128
                            ],
                            [
                                -123.523,
                                39.694
                            ],
                            [
                                -120.247,
                                39.181
                            ],
                            [
                                -117.815,
                                38.707
                            ]
                        ]
                    ]
                },
                "type": "Feature",
                "properties": {
                    "obj_child_num": 0,
                    "obj_revision": 4,
                    "obj_id": 19582,
                    "obj_version": "",
                    "gain": 0,
                    "obj_name": "SES-10_CA02-SMN",
                    "obj_parentid": 19567
                }
            },
            {
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -89.155,
                                3.095
                            ],
                            [
                                -89.063,
                                3.06
                            ],
                            [
                                -88.079,
                                2.488
                            ],
                            [
                                -87.718,
                                2.206
                            ],
                            [
                                -87.104,
                                1.618
                            ],
                            [
                                -86.838,
                                1.322
                            ],
                            [
                                -86.3,
                                0.44
                            ],
                            [
                                -86.144,
                                -0.146
                            ],
                            [
                                -86.064,
                                -0.44
                            ],
                            [
                                -86.15,
                                -1.248
                            ],
                            [
                                -86.158,
                                -1.321
                            ],
                            [
                                -86.573,
                                -2.203
                            ],
                            [
                                -87.129,
                                -3.003
                            ],
                            [
                                -87.197,
                                -3.087
                            ],
                            [
                                -87.883,
                                -3.975
                            ],
                            [
                                -88.128,
                                -4.286
                            ],
                            [
                                -88.781,
                                -4.867
                            ],
                            [
                                -89.133,
                                -5.095
                            ],
                            [
                                -90.134,
                                -5.415
                            ],
                            [
                                -91.135,
                                -5.474
                            ],
                            [
                                -92.139,
                                -5.356
                            ],
                            [
                                -93.147,
                                -5.06
                            ],
                            [
                                -93.459,
                                -4.9
                            ],
                            [
                                -94.146,
                                -4.308
                            ],
                            [
                                -94.361,
                                -4.012
                            ],
                            [
                                -94.636,
                                -3.12
                            ],
                            [
                                -94.77,
                                -2.229
                            ],
                            [
                                -94.937,
                                -1.337
                            ],
                            [
                                -95.094,
                                -0.446
                            ],
                            [
                                -95.093,
                                -0.429
                            ],
                            [
                                -95.038,
                                0.446
                            ],
                            [
                                -94.665,
                                1.337
                            ],
                            [
                                -94.072,
                                2.206
                            ],
                            [
                                -94.057,
                                2.226
                            ],
                            [
                                -93.064,
                                3.113
                            ],
                            [
                                -92.057,
                                3.454
                            ],
                            [
                                -91.053,
                                3.52
                            ],
                            [
                                -90.055,
                                3.389
                            ],
                            [
                                -89.155,
                                3.095
                            ]
                        ]
                    ]
                },
                "type": "Feature",
                "properties": {
                    "obj_child_num": 0,
                    "obj_revision": 4,
                    "obj_id": 19582,
                    "obj_version": "",
                    "gain": 0,
                    "obj_name": "SES-10_CA02-SMN",
                    "obj_parentid": 19567
                }
            },
            {
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -117.815,
                                38.707
                            ],
                            [
                                -117.256,
                                38.603
                            ],
                            [
                                -114.534,
                                38.006
                            ],
                            [
                                -111.993,
                                37.355
                            ],
                            [
                                -109.971,
                                36.791
                            ],
                            [
                                -109.648,
                                36.706
                            ],
                            [
                                -107.567,
                                36.196
                            ],
                            [
                                -105.679,
                                35.797
                            ],
                            [
                                -103.955,
                                35.518
                            ],
                            [
                                -102.373,
                                35.367
                            ],
                            [
                                -100.905,
                                35.334
                            ],
                            [
                                -99.504,
                                35.358
                            ],
                            [
                                -98.113,
                                35.342
                            ],
                            [
                                -96.707,
                                35.233
                            ],
                            [
                                -95.303,
                                35.054
                            ],
                            [
                                -93.923,
                                34.858
                            ],
                            [
                                -92.582,
                                34.679
                            ],
                            [
                                -91.282,
                                34.537
                            ],
                            [
                                -90.022,
                                34.446
                            ],
                            [
                                -88.802,
                                34.416
                            ],
                            [
                                -87.609,
                                34.426
                            ],
                            [
                                -86.423,
                                34.411
                            ],
                            [
                                -85.221,
                                34.288
                            ],
                            [
                                -84.521,
                                34.125
                            ],
                            [
                                -84.002,
                                34.021
                            ],
                            [
                                -82.78,
                                33.65
                            ],
                            [
                                -81.559,
                                33.165
                            ],
                            [
                                -80.884,
                                32.857
                            ],
                            [
                                -80.354,
                                32.63
                            ],
                            [
                                -79.176,
                                32.095
                            ],
                            [
                                -78.345,
                                31.664
                            ],
                            [
                                -78.014,
                                31.498
                            ],
                            [
                                -76.871,
                                30.858
                            ],
                            [
                                -76.385,
                                30.517
                            ],
                            [
                                -75.739,
                                30.1
                            ],
                            [
                                -74.848,
                                29.404
                            ],
                            [
                                -74.626,
                                29.245
                            ],
                            [
                                -73.538,
                                28.363
                            ],
                            [
                                -73.486,
                                28.316
                            ],
                            [
                                -72.475,
                                27.479
                            ],
                            [
                                -72.212,
                                27.25
                            ],
                            [
                                -70.995,
                                26.203
                            ],
                            [
                                -70.417,
                                25.697
                            ],
                            [
                                -69.782,
                                25.172
                            ],
                            [
                                -69.421,
                                24.87
                            ],
                            [
                                -68.459,
                                24.158
                            ],
                            [
                                -68.442,
                                24.145
                            ],
                            [
                                -67.478,
                                23.566
                            ],
                            [
                                -66.7,
                                23.158
                            ],
                            [
                                -66.524,
                                23.074
                            ],
                            [
                                -65.576,
                                22.67
                            ],
                            [
                                -64.634,
                                22.256
                            ],
                            [
                                -64.47,
                                22.176
                            ],
                            [
                                -63.697,
                                21.86
                            ],
                            [
                                -62.766,
                                21.439
                            ],
                            [
                                -62.285,
                                21.21
                            ],
                            [
                                -61.838,
                                21.022
                            ],
                            [
                                -60.912,
                                20.653
                            ],
                            [
                                -59.987,
                                20.308
                            ],
                            [
                                -59.85,
                                20.262
                            ],
                            [
                                -59.066,
                                19.918
                            ],
                            [
                                -58.16,
                                19.315
                            ],
                            [
                                -57.504,
                                18.376
                            ],
                            [
                                -57.315,
                                17.843
                            ],
                            [
                                -57.18,
                                17.436
                            ],
                            [
                                -56.963,
                                16.503
                            ],
                            [
                                -56.747,
                                15.577
                            ],
                            [
                                -56.563,
                                14.921
                            ],
                            [
                                -56.483,
                                14.657
                            ],
                            [
                                -56.256,
                                13.742
                            ],
                            [
                                -56.183,
                                12.832
                            ],
                            [
                                -56.321,
                                11.925
                            ],
                            [
                                -56.728,
                                11.02
                            ],
                            [
                                -56.756,
                                10.984
                            ],
                            [
                                -57.562,
                                10.118
                            ],
                            [
                                -57.694,
                                10.021
                            ],
                            [
                                -58.607,
                                9.49
                            ],
                            [
                                -59.272,
                                9.217
                            ],
                            [
                                -59.508,
                                9.129
                            ],
                            [
                                -60.401,
                                8.898
                            ],
                            [
                                -61.288,
                                8.78
                            ],
                            [
                                -62.171,
                                8.763
                            ],
                            [
                                -63.05,
                                8.863
                            ],
                            [
                                -63.926,
                                9.166
                            ],
                            [
                                -63.982,
                                9.202
                            ],
                            [
                                -64.787,
                                10.091
                            ],
                            [
                                -64.798,
                                10.14
                            ],
                            [
                                -64.923,
                                10.985
                            ],
                            [
                                -64.783,
                                11.824
                            ],
                            [
                                -64.773,
                                11.884
                            ],
                            [
                                -64.613,
                                12.787
                            ],
                            [
                                -64.77,
                                13.179
                            ],
                            [
                                -65.165,
                                13.694
                            ],
                            [
                                -65.657,
                                13.898
                            ],
                            [
                                -66.553,
                                13.824
                            ],
                            [
                                -66.842,
                                13.692
                            ],
                            [
                                -67.446,
                                13.257
                            ],
                            [
                                -67.973,
                                12.785
                            ],
                            [
                                -68.333,
                                12.314
                            ],
                            [
                                -68.861,
                                11.883
                            ],
                            [
                                -69.214,
                                11.552
                            ],
                            [
                                -70.095,
                                11.087
                            ],
                            [
                                -70.338,
                                10.987
                            ],
                            [
                                -70.974,
                                10.588
                            ],
                            [
                                -71.414,
                                10.096
                            ],
                            [
                                -71.837,
                                9.242
                            ],
                            [
                                -71.847,
                                9.207
                            ],
                            [
                                -72.144,
                                8.32
                            ],
                            [
                                -72.603,
                                7.437
                            ],
                            [
                                -72.688,
                                7.326
                            ],
                            [
                                -73.212,
                                6.557
                            ],
                            [
                                -73.551,
                                6.202
                            ],
                            [
                                -74.071,
                                5.68
                            ],
                            [
                                -74.419,
                                5.383
                            ],
                            [
                                -75.236,
                                4.806
                            ],
                            [
                                -75.291,
                                4.765
                            ],
                            [
                                -76.168,
                                4.239
                            ],
                            [
                                -76.983,
                                3.934
                            ],
                            [
                                -77.05,
                                3.899
                            ],
                            [
                                -77.936,
                                3.628
                            ],
                            [
                                -78.829,
                                3.546
                            ],
                            [
                                -79.729,
                                3.609
                            ],
                            [
                                -80.637,
                                3.798
                            ],
                            [
                                -81.06,
                                3.946
                            ],
                            [
                                -81.553,
                                4.076
                            ],
                            [
                                -82.478,
                                4.44
                            ],
                            [
                                -83.207,
                                4.835
                            ],
                            [
                                -83.415,
                                4.936
                            ],
                            [
                                -84.364,
                                5.455
                            ],
                            [
                                -84.82,
                                5.728
                            ],
                            [
                                -85.325,
                                5.998
                            ],
                            [
                                -86.299,
                                6.55
                            ],
                            [
                                -86.428,
                                6.627
                            ],
                            [
                                -87.287,
                                7.099
                            ],
                            [
                                -88.009,
                                7.533
                            ],
                            [
                                -88.292,
                                7.698
                            ],
                            [
                                -89.316,
                                8.302
                            ],
                            [
                                -89.556,
                                8.445
                            ],
                            [
                                -90.358,
                                8.909
                            ],
                            [
                                -91.129,
                                9.366
                            ],
                            [
                                -91.421,
                                9.519
                            ],
                            [
                                -92.493,
                                10.011
                            ],
                            [
                                -93.213,
                                10.304
                            ],
                            [
                                -93.579,
                                10.43
                            ],
                            [
                                -94.675,
                                10.756
                            ],
                            [
                                -95.795,
                                11.109
                            ],
                            [
                                -96.232,
                                11.272
                            ],
                            [
                                -96.946,
                                11.515
                            ],
                            [
                                -98.14,
                                12.024
                            ],
                            [
                                -98.557,
                                12.243
                            ],
                            [
                                -99.382,
                                12.633
                            ],
                            [
                                -100.44,
                                13.218
                            ],
                            [
                                -100.681,
                                13.336
                            ],
                            [
                                -102.016,
                                13.996
                            ],
                            [
                                -102.438,
                                14.21
                            ],
                            [
                                -103.391,
                                14.616
                            ],
                            [
                                -104.812,
                                15.21
                            ],
                            [
                                -104.865,
                                15.231
                            ],
                            [
                                -106.269,
                                15.728
                            ],
                            [
                                -107.665,
                                16.283
                            ],
                            [
                                -107.817,
                                16.348
                            ],
                            [
                                -109.495,
                                17.128
                            ],
                            [
                                -109.893,
                                17.337
                            ],
                            [
                                -111.428,
                                18.381
                            ],
                            [
                                -111.472,
                                18.416
                            ],
                            [
                                -112.811,
                                19.434
                            ],
                            [
                                -113.941,
                                20.355
                            ],
                            [
                                -114.142,
                                20.5
                            ],
                            [
                                -115.848,
                                21.601
                            ],
                            [
                                -116.599,
                                22.043
                            ],
                            [
                                -117.981,
                                22.745
                            ],
                            [
                                -119.427,
                                23.44
                            ],
                            [
                                -120.528,
                                23.936
                            ],
                            [
                                -122.823,
                                24.998
                            ],
                            [
                                -123.185,
                                25.161
                            ],
                            [
                                -125.663,
                                26.403
                            ],
                            [
                                -127.815,
                                27.651
                            ],
                            [
                                -129.611,
                                28.899
                            ],
                            [
                                -131.052,
                                30.145
                            ],
                            [
                                -132.235,
                                31.396
                            ],
                            [
                                -133.367,
                                32.665
                            ],
                            [
                                -133.484,
                                32.789
                            ],
                            [
                                -134.535,
                                33.963
                            ],
                            [
                                -135.639,
                                35.282
                            ],
                            [
                                -135.785,
                                35.541
                            ],
                            [
                                -136.794,
                                36.633
                            ],
                            [
                                -137.626,
                                37.834
                            ],
                            [
                                -137.791,
                                37.999
                            ],
                            [
                                -138.399,
                                39.358
                            ],
                            [
                                -138.33,
                                39.646
                            ],
                            [
                                -135.442,
                                40.387
                            ],
                            [
                                -135.423,
                                40.387
                            ],
                            [
                                -131.234,
                                40.421
                            ],
                            [
                                -127.17,
                                40.128
                            ],
                            [
                                -123.523,
                                39.694
                            ],
                            [
                                -120.247,
                                39.181
                            ],
                            [
                                -117.815,
                                38.707
                            ]
                        ]
                    ]
                },
                "type": "Feature",
                "properties": {
                    "obj_child_num": 0,
                    "obj_revision": 4,
                    "obj_id": 19582,
                    "obj_version": "",
                    "gain": 4500,
                    "obj_name": "SES-10_CA02-SMN",
                    "obj_parentid": 19567
                }
            },
            {
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -89.155,
                                3.095
                            ],
                            [
                                -89.063,
                                3.06
                            ],
                            [
                                -88.079,
                                2.488
                            ],
                            [
                                -87.718,
                                2.206
                            ],
                            [
                                -87.104,
                                1.618
                            ],
                            [
                                -86.838,
                                1.322
                            ],
                            [
                                -86.3,
                                0.44
                            ],
                            [
                                -86.144,
                                -0.146
                            ],
                            [
                                -86.064,
                                -0.44
                            ],
                            [
                                -86.15,
                                -1.248
                            ],
                            [
                                -86.158,
                                -1.321
                            ],
                            [
                                -86.573,
                                -2.203
                            ],
                            [
                                -87.129,
                                -3.003
                            ],
                            [
                                -87.197,
                                -3.087
                            ],
                            [
                                -87.883,
                                -3.975
                            ],
                            [
                                -88.128,
                                -4.286
                            ],
                            [
                                -88.781,
                                -4.867
                            ],
                            [
                                -89.133,
                                -5.095
                            ],
                            [
                                -90.134,
                                -5.415
                            ],
                            [
                                -91.135,
                                -5.474
                            ],
                            [
                                -92.139,
                                -5.356
                            ],
                            [
                                -93.147,
                                -5.06
                            ],
                            [
                                -93.459,
                                -4.9
                            ],
                            [
                                -94.146,
                                -4.308
                            ],
                            [
                                -94.361,
                                -4.012
                            ],
                            [
                                -94.636,
                                -3.12
                            ],
                            [
                                -94.77,
                                -2.229
                            ],
                            [
                                -94.937,
                                -1.337
                            ],
                            [
                                -95.094,
                                -0.446
                            ],
                            [
                                -95.093,
                                -0.429
                            ],
                            [
                                -95.038,
                                0.446
                            ],
                            [
                                -94.665,
                                1.337
                            ],
                            [
                                -94.072,
                                2.206
                            ],
                            [
                                -94.057,
                                2.226
                            ],
                            [
                                -93.064,
                                3.113
                            ],
                            [
                                -92.057,
                                3.454
                            ],
                            [
                                -91.053,
                                3.52
                            ],
                            [
                                -90.055,
                                3.389
                            ],
                            [
                                -89.155,
                                3.095
                            ]
                        ]
                    ]
                },
                "type": "Feature",
                "properties": {
                    "obj_child_num": 0,
                    "obj_revision": 4,
                    "obj_id": 19582,
                    "obj_version": "",
                    "gain": 4500,
                    "obj_name": "SES-10_CA02-SMN",
                    "obj_parentid": 19567
                }
            },
            {
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                106.003,
                                42.0879
                            ],
                            [
                                106.215,
                                42.4477
                            ],
                            [
                                106.576,
                                43.0029
                            ],
                            [
                                107.285,
                                43.9437
                            ],
                            [
                                107.412,
                                44.0902
                            ],
                            [
                                108.196,
                                44.9151
                            ],
                            [
                                108.574,
                                45.2698
                            ],
                            [
                                109.34,
                                45.9222
                            ],
                            [
                                109.734,
                                46.2225
                            ],
                            [
                                110.822,
                                46.9739
                            ],
                            [
                                110.899,
                                47.0216
                            ],
                            [
                                112.051,
                                47.6523
                            ],
                            [
                                112.964,
                                48.0927
                            ],
                            [
                                113.212,
                                48.1991
                            ],
                            [
                                114.351,
                                48.6093
                            ],
                            [
                                115.484,
                                48.9392
                            ],
                            [
                                116.598,
                                49.1778
                            ],
                            [
                                117.682,
                                49.3186
                            ],
                            [
                                118.726,
                                49.3572
                            ],
                            [
                                119.722,
                                49.2944
                            ],
                            [
                                120.664,
                                49.1329
                            ],
                            [
                                121.549,
                                48.8786
                            ],
                            [
                                122.16,
                                48.6279
                            ],
                            [
                                122.366,
                                48.5221
                            ],
                            [
                                123.083,
                                48.0209
                            ],
                            [
                                123.466,
                                47.682
                            ],
                            [
                                123.713,
                                47.4033
                            ],
                            [
                                124.192,
                                46.7239
                            ],
                            [
                                124.237,
                                46.6388
                            ],
                            [
                                124.586,
                                45.7682
                            ],
                            [
                                124.614,
                                45.6644
                            ],
                            [
                                124.763,
                                44.821
                            ],
                            [
                                124.793,
                                44.3921
                            ],
                            [
                                124.798,
                                43.8863
                            ],
                            [
                                124.722,
                                42.9648
                            ],
                            [
                                124.651,
                                42.584
                            ],
                            [
                                124.538,
                                42.0557
                            ],
                            [
                                124.264,
                                41.1593
                            ],
                            [
                                123.917,
                                40.276
                            ],
                            [
                                123.498,
                                39.4049
                            ],
                            [
                                123.009,
                                38.5457
                            ],
                            [
                                122.456,
                                37.6981
                            ],
                            [
                                121.844,
                                36.862
                            ],
                            [
                                121.541,
                                36.4907
                            ],
                            [
                                121.156,
                                36.036
                            ],
                            [
                                120.393,
                                35.22
                            ],
                            [
                                120.072,
                                34.9074
                            ],
                            [
                                119.534,
                                34.4128
                            ],
                            [
                                118.895,
                                33.8753
                            ],
                            [
                                118.564,
                                33.6138
                            ],
                            [
                                117.855,
                                33.1047
                            ],
                            [
                                117.433,
                                32.8212
                            ],
                            [
                                116.899,
                                32.4972
                            ],
                            [
                                116.076,
                                32.0332
                            ],
                            [
                                115.999,
                                31.9943
                            ],
                            [
                                115.152,
                                31.6187
                            ],
                            [
                                114.335,
                                31.3022
                            ],
                            [
                                114.132,
                                31.2391
                            ],
                            [
                                113.554,
                                31.083
                            ],
                            [
                                112.798,
                                30.9329
                            ],
                            [
                                112.062,
                                30.8453
                            ],
                            [
                                111.346,
                                30.8218
                            ],
                            [
                                110.646,
                                30.8618
                            ],
                            [
                                109.96,
                                30.9637
                            ],
                            [
                                109.395,
                                31.0987
                            ],
                            [
                                109.288,
                                31.1297
                            ],
                            [
                                108.632,
                                31.3926
                            ],
                            [
                                107.985,
                                31.7128
                            ],
                            [
                                107.87,
                                31.782
                            ],
                            [
                                107.354,
                                32.1482
                            ],
                            [
                                106.935,
                                32.4893
                            ],
                            [
                                106.734,
                                32.6818
                            ],
                            [
                                106.251,
                                33.2117
                            ],
                            [
                                106.131,
                                33.3662
                            ],
                            [
                                105.74,
                                33.9476
                            ],
                            [
                                105.555,
                                34.2795
                            ],
                            [
                                105.353,
                                34.6962
                            ],
                            [
                                105.061,
                                35.4573
                            ],
                            [
                                105.025,
                                35.5886
                            ],
                            [
                                104.88,
                                36.2318
                            ],
                            [
                                104.779,
                                37.0199
                            ],
                            [
                                104.757,
                                37.8223
                            ],
                            [
                                104.82,
                                38.6399
                            ],
                            [
                                104.974,
                                39.4739
                            ],
                            [
                                105.221,
                                40.3257
                            ],
                            [
                                105.564,
                                41.1965
                            ],
                            [
                                106.003,
                                42.0879
                            ]
                        ]
                    ]
                },
                "type": "Feature",
                "properties": {
                    "obj_child_num": 0,
                    "obj_revision": 2,
                    "obj_id": 114978,
                    "obj_version": "",
                    "gain": 0,
                    "obj_name": "SES-12_Beam_NE03_ADL",
                    "obj_parentid": 101805
                }
            },
            {
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                106.003,
                                42.0879
                            ],
                            [
                                106.215,
                                42.4477
                            ],
                            [
                                106.576,
                                43.0029
                            ],
                            [
                                107.285,
                                43.9437
                            ],
                            [
                                107.412,
                                44.0902
                            ],
                            [
                                108.196,
                                44.9151
                            ],
                            [
                                108.574,
                                45.2698
                            ],
                            [
                                109.34,
                                45.9222
                            ],
                            [
                                109.734,
                                46.2225
                            ],
                            [
                                110.822,
                                46.9739
                            ],
                            [
                                110.899,
                                47.0216
                            ],
                            [
                                112.051,
                                47.6523
                            ],
                            [
                                112.964,
                                48.0927
                            ],
                            [
                                113.212,
                                48.1991
                            ],
                            [
                                114.351,
                                48.6093
                            ],
                            [
                                115.484,
                                48.9392
                            ],
                            [
                                116.598,
                                49.1778
                            ],
                            [
                                117.682,
                                49.3186
                            ],
                            [
                                118.726,
                                49.3572
                            ],
                            [
                                119.722,
                                49.2944
                            ],
                            [
                                120.664,
                                49.1329
                            ],
                            [
                                121.549,
                                48.8786
                            ],
                            [
                                122.16,
                                48.6279
                            ],
                            [
                                122.366,
                                48.5221
                            ],
                            [
                                123.083,
                                48.0209
                            ],
                            [
                                123.466,
                                47.682
                            ],
                            [
                                123.713,
                                47.4033
                            ],
                            [
                                124.192,
                                46.7239
                            ],
                            [
                                124.237,
                                46.6388
                            ],
                            [
                                124.586,
                                45.7682
                            ],
                            [
                                124.614,
                                45.6644
                            ],
                            [
                                124.763,
                                44.821
                            ],
                            [
                                124.793,
                                44.3921
                            ],
                            [
                                124.798,
                                43.8863
                            ],
                            [
                                124.722,
                                42.9648
                            ],
                            [
                                124.651,
                                42.584
                            ],
                            [
                                124.538,
                                42.0557
                            ],
                            [
                                124.264,
                                41.1593
                            ],
                            [
                                123.917,
                                40.276
                            ],
                            [
                                123.498,
                                39.4049
                            ],
                            [
                                123.009,
                                38.5457
                            ],
                            [
                                122.456,
                                37.6981
                            ],
                            [
                                121.844,
                                36.862
                            ],
                            [
                                121.541,
                                36.4907
                            ],
                            [
                                121.156,
                                36.036
                            ],
                            [
                                120.393,
                                35.22
                            ],
                            [
                                120.072,
                                34.9074
                            ],
                            [
                                119.534,
                                34.4128
                            ],
                            [
                                118.895,
                                33.8753
                            ],
                            [
                                118.564,
                                33.6138
                            ],
                            [
                                117.855,
                                33.1047
                            ],
                            [
                                117.433,
                                32.8212
                            ],
                            [
                                116.899,
                                32.4972
                            ],
                            [
                                116.076,
                                32.0332
                            ],
                            [
                                115.999,
                                31.9943
                            ],
                            [
                                115.152,
                                31.6187
                            ],
                            [
                                114.335,
                                31.3022
                            ],
                            [
                                114.132,
                                31.2391
                            ],
                            [
                                113.554,
                                31.083
                            ],
                            [
                                112.798,
                                30.9329
                            ],
                            [
                                112.062,
                                30.8453
                            ],
                            [
                                111.346,
                                30.8218
                            ],
                            [
                                110.646,
                                30.8618
                            ],
                            [
                                109.96,
                                30.9637
                            ],
                            [
                                109.395,
                                31.0987
                            ],
                            [
                                109.288,
                                31.1297
                            ],
                            [
                                108.632,
                                31.3926
                            ],
                            [
                                107.985,
                                31.7128
                            ],
                            [
                                107.87,
                                31.782
                            ],
                            [
                                107.354,
                                32.1482
                            ],
                            [
                                106.935,
                                32.4893
                            ],
                            [
                                106.734,
                                32.6818
                            ],
                            [
                                106.251,
                                33.2117
                            ],
                            [
                                106.131,
                                33.3662
                            ],
                            [
                                105.74,
                                33.9476
                            ],
                            [
                                105.555,
                                34.2795
                            ],
                            [
                                105.353,
                                34.6962
                            ],
                            [
                                105.061,
                                35.4573
                            ],
                            [
                                105.025,
                                35.5886
                            ],
                            [
                                104.88,
                                36.2318
                            ],
                            [
                                104.779,
                                37.0199
                            ],
                            [
                                104.757,
                                37.8223
                            ],
                            [
                                104.82,
                                38.6399
                            ],
                            [
                                104.974,
                                39.4739
                            ],
                            [
                                105.221,
                                40.3257
                            ],
                            [
                                105.564,
                                41.1965
                            ],
                            [
                                106.003,
                                42.0879
                            ]
                        ]
                    ]
                },
                "type": "Feature",
                "properties": {
                    "obj_child_num": 0,
                    "obj_revision": 2,
                    "obj_id": 114978,
                    "obj_version": "",
                    "gain": 3400,
                    "obj_name": "SES-12_Beam_NE03_ADL",
                    "obj_parentid": 101805
                }
            },
            {
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                102.718,
                                15.1694
                            ],
                            [
                                103.406,
                                16.7551
                            ],
                            [
                                104.228,
                                18.4555
                            ],
                            [
                                104.419,
                                18.7421
                            ],
                            [
                                105.661,
                                19.1051
                            ],
                            [
                                106.157,
                                19.1815
                            ],
                            [
                                106.979,
                                19.5063
                            ],
                            [
                                107.724,
                                19.9457
                            ],
                            [
                                108.66,
                                20.1558
                            ],
                            [
                                109.635,
                                19.8884
                            ],
                            [
                                109.883,
                                19.64
                            ],
                            [
                                110.15,
                                18.8949
                            ],
                            [
                                110.437,
                                18.0925
                            ],
                            [
                                110.552,
                                17.1181
                            ],
                            [
                                110.666,
                                16.9844
                            ],
                            [
                                111.698,
                                16.6787
                            ],
                            [
                                112.118,
                                16.182
                            ],
                            [
                                112.672,
                                15.5706
                            ],
                            [
                                113.016,
                                14.73
                            ],
                            [
                                112.863,
                                14.2715
                            ],
                            [
                                112.405,
                                13.5264
                            ],
                            [
                                111.22,
                                11.6541
                            ],
                            [
                                111.22,
                                11.5203
                            ],
                            [
                                109.386,
                                10.5651
                            ],
                            [
                                108.851,
                                10.2976
                            ],
                            [
                                106.902,
                                10.3358
                            ],
                            [
                                106.52,
                                10.1448
                            ],
                            [
                                106.119,
                                10.2212
                            ],
                            [
                                105.966,
                                10.2594
                            ],
                            [
                                105.489,
                                10.9472
                            ],
                            [
                                104.648,
                                11.6159
                            ],
                            [
                                103.597,
                                12.7622
                            ],
                            [
                                102.699,
                                14.0422
                            ],
                            [
                                102.604,
                                14.2142
                            ],
                            [
                                102.527,
                                14.6345
                            ],
                            [
                                102.699,
                                15.2267
                            ],
                            [
                                102.718,
                                15.1694
                            ]
                        ]
                    ]
                },
                "type": "Feature",
                "properties": {
                    "obj_child_num": 0,
                    "obj_revision": 3,
                    "obj_id": 143835,
                    "obj_version": "",
                    "gain": 0,
                    "obj_name": "SES-12-Ka_Beam_SE02_ADL",
                    "obj_parentid": 101805
                }
            },
            {
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                102.718,
                                15.1694
                            ],
                            [
                                103.406,
                                16.7551
                            ],
                            [
                                104.228,
                                18.4555
                            ],
                            [
                                104.419,
                                18.7421
                            ],
                            [
                                105.661,
                                19.1051
                            ],
                            [
                                106.157,
                                19.1815
                            ],
                            [
                                106.979,
                                19.5063
                            ],
                            [
                                107.724,
                                19.9457
                            ],
                            [
                                108.66,
                                20.1558
                            ],
                            [
                                109.635,
                                19.8884
                            ],
                            [
                                109.883,
                                19.64
                            ],
                            [
                                110.15,
                                18.8949
                            ],
                            [
                                110.437,
                                18.0925
                            ],
                            [
                                110.552,
                                17.1181
                            ],
                            [
                                110.666,
                                16.9844
                            ],
                            [
                                111.698,
                                16.6787
                            ],
                            [
                                112.118,
                                16.182
                            ],
                            [
                                112.672,
                                15.5706
                            ],
                            [
                                113.016,
                                14.73
                            ],
                            [
                                112.863,
                                14.2715
                            ],
                            [
                                112.405,
                                13.5264
                            ],
                            [
                                111.22,
                                11.6541
                            ],
                            [
                                111.22,
                                11.5203
                            ],
                            [
                                109.386,
                                10.5651
                            ],
                            [
                                108.851,
                                10.2976
                            ],
                            [
                                106.902,
                                10.3358
                            ],
                            [
                                106.52,
                                10.1448
                            ],
                            [
                                106.119,
                                10.2212
                            ],
                            [
                                105.966,
                                10.2594
                            ],
                            [
                                105.489,
                                10.9472
                            ],
                            [
                                104.648,
                                11.6159
                            ],
                            [
                                103.597,
                                12.7622
                            ],
                            [
                                102.699,
                                14.0422
                            ],
                            [
                                102.604,
                                14.2142
                            ],
                            [
                                102.527,
                                14.6345
                            ],
                            [
                                102.699,
                                15.2267
                            ],
                            [
                                102.718,
                                15.1694
                            ]
                        ]
                    ]
                },
                "type": "Feature",
                "properties": {
                    "obj_child_num": 0,
                    "obj_revision": 3,
                    "obj_id": 143835,
                    "obj_version": "",
                    "gain": 4500,
                    "obj_name": "SES-12-Ka_Beam_SE02_ADL",
                    "obj_parentid": 101805
                }
            },
            {
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                98.5478,
                                14.1792
                            ],
                            [
                                99.9092,
                                14.3854
                            ],
                            [
                                101.621,
                                14.736
                            ],
                            [
                                103.313,
                                15.1898
                            ],
                            [
                                103.601,
                                15.1691
                            ],
                            [
                                104.035,
                                14.7566
                            ],
                            [
                                104.365,
                                14.2617
                            ],
                            [
                                105.375,
                                12.983
                            ],
                            [
                                106.303,
                                12.1786
                            ],
                            [
                                106.757,
                                11.663
                            ],
                            [
                                107.108,
                                11.1062
                            ],
                            [
                                107.129,
                                10.7968
                            ],
                            [
                                107.046,
                                10.5287
                            ],
                            [
                                106.551,
                                9.6418
                            ],
                            [
                                105.808,
                                8.2806
                            ],
                            [
                                105.726,
                                7.765
                            ],
                            [
                                105.499,
                                6.5482
                            ],
                            [
                                105.231,
                                5.682
                            ],
                            [
                                105.107,
                                5.2489
                            ],
                            [
                                105.025,
                                5.1045
                            ],
                            [
                                104.818,
                                4.8776
                            ],
                            [
                                104.488,
                                4.7745
                            ],
                            [
                                103.56,
                                4.7951
                            ],
                            [
                                102.9,
                                4.7951
                            ],
                            [
                                102.529,
                                4.857
                            ],
                            [
                                101.745,
                                4.8158
                            ],
                            [
                                100.219,
                                4.7745
                            ],
                            [
                                99.2079,
                                4.7745
                            ],
                            [
                                98.8572,
                                4.9189
                            ],
                            [
                                98.5891,
                                5.2076
                            ],
                            [
                                98.1147,
                                5.9501
                            ],
                            [
                                97.6196,
                                6.6719
                            ],
                            [
                                97.0008,
                                7.3732
                            ],
                            [
                                96.4645,
                                7.8475
                            ],
                            [
                                96.0313,
                                8.1981
                            ],
                            [
                                95.9076,
                                8.5281
                            ],
                            [
                                96.0726,
                                9.2087
                            ],
                            [
                                96.2376,
                                10.3018
                            ],
                            [
                                96.5677,
                                11.2918
                            ],
                            [
                                97.269,
                                12.4467
                            ],
                            [
                                98.0321,
                                13.6017
                            ],
                            [
                                98.3622,
                                14.0348
                            ],
                            [
                                98.5272,
                                14.1585
                            ],
                            [
                                98.5478,
                                14.1792
                            ]
                        ]
                    ]
                },
                "type": "Feature",
                "properties": {
                    "obj_child_num": 0,
                    "obj_revision": 2,
                    "obj_id": 143933,
                    "obj_version": "",
                    "gain": 0,
                    "obj_name": "SES-12-Ka_Beam_SE05_ADL",
                    "obj_parentid": 101805
                }
            },
            {
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                98.5478,
                                14.1792
                            ],
                            [
                                99.9092,
                                14.3854
                            ],
                            [
                                101.621,
                                14.736
                            ],
                            [
                                103.313,
                                15.1898
                            ],
                            [
                                103.601,
                                15.1691
                            ],
                            [
                                104.035,
                                14.7566
                            ],
                            [
                                104.365,
                                14.2617
                            ],
                            [
                                105.375,
                                12.983
                            ],
                            [
                                106.303,
                                12.1786
                            ],
                            [
                                106.757,
                                11.663
                            ],
                            [
                                107.108,
                                11.1062
                            ],
                            [
                                107.129,
                                10.7968
                            ],
                            [
                                107.046,
                                10.5287
                            ],
                            [
                                106.551,
                                9.6418
                            ],
                            [
                                105.808,
                                8.2806
                            ],
                            [
                                105.726,
                                7.765
                            ],
                            [
                                105.499,
                                6.5482
                            ],
                            [
                                105.231,
                                5.682
                            ],
                            [
                                105.107,
                                5.2489
                            ],
                            [
                                105.025,
                                5.1045
                            ],
                            [
                                104.818,
                                4.8776
                            ],
                            [
                                104.488,
                                4.7745
                            ],
                            [
                                103.56,
                                4.7951
                            ],
                            [
                                102.9,
                                4.7951
                            ],
                            [
                                102.529,
                                4.857
                            ],
                            [
                                101.745,
                                4.8158
                            ],
                            [
                                100.219,
                                4.7745
                            ],
                            [
                                99.2079,
                                4.7745
                            ],
                            [
                                98.8572,
                                4.9189
                            ],
                            [
                                98.5891,
                                5.2076
                            ],
                            [
                                98.1147,
                                5.9501
                            ],
                            [
                                97.6196,
                                6.6719
                            ],
                            [
                                97.0008,
                                7.3732
                            ],
                            [
                                96.4645,
                                7.8475
                            ],
                            [
                                96.0313,
                                8.1981
                            ],
                            [
                                95.9076,
                                8.5281
                            ],
                            [
                                96.0726,
                                9.2087
                            ],
                            [
                                96.2376,
                                10.3018
                            ],
                            [
                                96.5677,
                                11.2918
                            ],
                            [
                                97.269,
                                12.4467
                            ],
                            [
                                98.0321,
                                13.6017
                            ],
                            [
                                98.3622,
                                14.0348
                            ],
                            [
                                98.5272,
                                14.1585
                            ],
                            [
                                98.5478,
                                14.1792
                            ]
                        ]
                    ]
                },
                "type": "Feature",
                "properties": {
                    "obj_child_num": 0,
                    "obj_revision": 2,
                    "obj_id": 143933,
                    "obj_version": "",
                    "gain": 4500,
                    "obj_name": "SES-12-Ka_Beam_SE05_ADL",
                    "obj_parentid": 101805
                }
            },
            {
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                105.799,
                                11.4082
                            ],
                            [
                                106.197,
                                11.6661
                            ],
                            [
                                106.83,
                                11.8303
                            ],
                            [
                                108.472,
                                11.7834
                            ],
                            [
                                109.972,
                                12.5806
                            ],
                            [
                                110.465,
                                12.8151
                            ],
                            [
                                110.676,
                                12.862
                            ],
                            [
                                111.051,
                                12.8151
                            ],
                            [
                                111.379,
                                12.4399
                            ],
                            [
                                111.614,
                                11.7834
                            ],
                            [
                                112.106,
                                10.7985
                            ],
                            [
                                112.528,
                                9.9543
                            ],
                            [
                                113.068,
                                9.1805
                            ],
                            [
                                113.724,
                                8.3832
                            ],
                            [
                                114.217,
                                7.8673
                            ],
                            [
                                114.404,
                                7.5625
                            ],
                            [
                                114.522,
                                7.1638
                            ],
                            [
                                114.358,
                                6.8355
                            ],
                            [
                                113.959,
                                6.39
                            ],
                            [
                                112.927,
                                5.241
                            ],
                            [
                                112.528,
                                4.7485
                            ],
                            [
                                112.247,
                                3.5057
                            ],
                            [
                                112.083,
                                3.0836
                            ],
                            [
                                111.684,
                                2.9195
                            ],
                            [
                                110.817,
                                2.896
                            ],
                            [
                                109.949,
                                2.8022
                            ],
                            [
                                109.433,
                                2.5208
                            ],
                            [
                                108.894,
                                2.2394
                            ],
                            [
                                107.815,
                                2.216
                            ],
                            [
                                106.056,
                                2.2394
                            ],
                            [
                                105.517,
                                2.3098
                            ],
                            [
                                105.142,
                                2.8022
                            ],
                            [
                                104.626,
                                3.9043
                            ],
                            [
                                104.204,
                                4.7016
                            ],
                            [
                                103.735,
                                5.2175
                            ],
                            [
                                103.618,
                                5.452
                            ],
                            [
                                103.782,
                                6.0148
                            ],
                            [
                                104.134,
                                7.07
                            ],
                            [
                                104.298,
                                8.5004
                            ],
                            [
                                104.462,
                                9.0632
                            ],
                            [
                                105.283,
                                10.4233
                            ],
                            [
                                105.587,
                                10.9626
                            ],
                            [
                                105.775,
                                11.4082
                            ],
                            [
                                105.799,
                                11.4082
                            ]
                        ]
                    ]
                },
                "type": "Feature",
                "properties": {
                    "obj_child_num": 0,
                    "obj_revision": 3,
                    "obj_id": 143949,
                    "obj_version": "",
                    "gain": 0,
                    "obj_name": "SES-12-Ka_Beam_SE06_ADL",
                    "obj_parentid": 101805
                }
            },
            {
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                105.799,
                                11.4082
                            ],
                            [
                                106.197,
                                11.6661
                            ],
                            [
                                106.83,
                                11.8303
                            ],
                            [
                                108.472,
                                11.7834
                            ],
                            [
                                109.972,
                                12.5806
                            ],
                            [
                                110.465,
                                12.8151
                            ],
                            [
                                110.676,
                                12.862
                            ],
                            [
                                111.051,
                                12.8151
                            ],
                            [
                                111.379,
                                12.4399
                            ],
                            [
                                111.614,
                                11.7834
                            ],
                            [
                                112.106,
                                10.7985
                            ],
                            [
                                112.528,
                                9.9543
                            ],
                            [
                                113.068,
                                9.1805
                            ],
                            [
                                113.724,
                                8.3832
                            ],
                            [
                                114.217,
                                7.8673
                            ],
                            [
                                114.404,
                                7.5625
                            ],
                            [
                                114.522,
                                7.1638
                            ],
                            [
                                114.358,
                                6.8355
                            ],
                            [
                                113.959,
                                6.39
                            ],
                            [
                                112.927,
                                5.241
                            ],
                            [
                                112.528,
                                4.7485
                            ],
                            [
                                112.247,
                                3.5057
                            ],
                            [
                                112.083,
                                3.0836
                            ],
                            [
                                111.684,
                                2.9195
                            ],
                            [
                                110.817,
                                2.896
                            ],
                            [
                                109.949,
                                2.8022
                            ],
                            [
                                109.433,
                                2.5208
                            ],
                            [
                                108.894,
                                2.2394
                            ],
                            [
                                107.815,
                                2.216
                            ],
                            [
                                106.056,
                                2.2394
                            ],
                            [
                                105.517,
                                2.3098
                            ],
                            [
                                105.142,
                                2.8022
                            ],
                            [
                                104.626,
                                3.9043
                            ],
                            [
                                104.204,
                                4.7016
                            ],
                            [
                                103.735,
                                5.2175
                            ],
                            [
                                103.618,
                                5.452
                            ],
                            [
                                103.782,
                                6.0148
                            ],
                            [
                                104.134,
                                7.07
                            ],
                            [
                                104.298,
                                8.5004
                            ],
                            [
                                104.462,
                                9.0632
                            ],
                            [
                                105.283,
                                10.4233
                            ],
                            [
                                105.587,
                                10.9626
                            ],
                            [
                                105.775,
                                11.4082
                            ],
                            [
                                105.799,
                                11.4082
                            ]
                        ]
                    ]
                },
                "type": "Feature",
                "properties": {
                    "obj_child_num": 0,
                    "obj_revision": 3,
                    "obj_id": 143949,
                    "obj_version": "",
                    "gain": 4500,
                    "obj_name": "SES-12-Ka_Beam_SE06_ADL",
                    "obj_parentid": 101805
                }
            },
            {
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                137.323,
                                -4.9166
                            ],
                            [
                                139.149,
                                -3.1898
                            ],
                            [
                                141.214,
                                -0.7915
                            ],
                            [
                                142.511,
                                0.4557
                            ],
                            [
                                143.28,
                                0.6476
                            ],
                            [
                                146.45,
                                0.8394
                            ],
                            [
                                149.428,
                                0.5516
                            ],
                            [
                                152.022,
                                -0.2159
                            ],
                            [
                                155.673,
                                -1.7508
                            ],
                            [
                                158.891,
                                -3.5256
                            ],
                            [
                                160.716,
                                -5.3963
                            ],
                            [
                                161.389,
                                -6.8833
                            ],
                            [
                                161.389,
                                -8.1304
                            ],
                            [
                                160.572,
                                -9.4256
                            ],
                            [
                                158.651,
                                -10.9605
                            ],
                            [
                                156.633,
                                -11.8719
                            ],
                            [
                                153.175,
                                -12.9272
                            ],
                            [
                                150.052,
                                -13.5507
                            ],
                            [
                                146.738,
                                -13.6467
                            ],
                            [
                                144.192,
                                -13.3109
                            ],
                            [
                                144,
                                -13.119
                            ],
                            [
                                143.76,
                                -12.3036
                            ],
                            [
                                141.79,
                                -10.3369
                            ],
                            [
                                138.572,
                                -6.9792
                            ],
                            [
                                137.323,
                                -5.6841
                            ],
                            [
                                137.227,
                                -5.3963
                            ],
                            [
                                137.227,
                                -5.0126
                            ],
                            [
                                137.419,
                                -4.8687
                            ],
                            [
                                137.323,
                                -4.9166
                            ]
                        ]
                    ]
                },
                "type": "Feature",
                "properties": {
                    "obj_child_num": 0,
                    "obj_revision": 1,
                    "obj_id": 191410,
                    "obj_version": "",
                    "gain": 0,
                    "obj_name": "SES-12-Ka_Beam_SE16_ADL",
                    "obj_parentid": 101805
                }
            },
            {
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                137.323,
                                -4.9166
                            ],
                            [
                                139.149,
                                -3.1898
                            ],
                            [
                                141.214,
                                -0.7915
                            ],
                            [
                                142.511,
                                0.4557
                            ],
                            [
                                143.28,
                                0.6476
                            ],
                            [
                                146.45,
                                0.8394
                            ],
                            [
                                149.428,
                                0.5516
                            ],
                            [
                                152.022,
                                -0.2159
                            ],
                            [
                                155.673,
                                -1.7508
                            ],
                            [
                                158.891,
                                -3.5256
                            ],
                            [
                                160.716,
                                -5.3963
                            ],
                            [
                                161.389,
                                -6.8833
                            ],
                            [
                                161.389,
                                -8.1304
                            ],
                            [
                                160.572,
                                -9.4256
                            ],
                            [
                                158.651,
                                -10.9605
                            ],
                            [
                                156.633,
                                -11.8719
                            ],
                            [
                                153.175,
                                -12.9272
                            ],
                            [
                                150.052,
                                -13.5507
                            ],
                            [
                                146.738,
                                -13.6467
                            ],
                            [
                                144.192,
                                -13.3109
                            ],
                            [
                                144,
                                -13.119
                            ],
                            [
                                143.76,
                                -12.3036
                            ],
                            [
                                141.79,
                                -10.3369
                            ],
                            [
                                138.572,
                                -6.9792
                            ],
                            [
                                137.323,
                                -5.6841
                            ],
                            [
                                137.227,
                                -5.3963
                            ],
                            [
                                137.227,
                                -5.0126
                            ],
                            [
                                137.419,
                                -4.8687
                            ],
                            [
                                137.323,
                                -4.9166
                            ]
                        ]
                    ]
                },
                "type": "Feature",
                "properties": {
                    "obj_child_num": 0,
                    "obj_revision": 1,
                    "obj_id": 191410,
                    "obj_version": "",
                    "gain": 4500,
                    "obj_name": "SES-12-Ka_Beam_SE16_ADL",
                    "obj_parentid": 101805
                }
            },
            {
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                20.398,
                                27.3478
                            ],
                            [
                                20.5299,
                                28.2656
                            ],
                            [
                                20.676,
                                29.2323
                            ],
                            [
                                20.8294,
                                30.1982
                            ],
                            [
                                20.9905,
                                31.163
                            ],
                            [
                                21.1595,
                                32.1269
                            ],
                            [
                                21.3367,
                                33.0898
                            ],
                            [
                                21.5227,
                                34.0515
                            ],
                            [
                                21.7176,
                                35.0122
                            ],
                            [
                                21.922,
                                35.9716
                            ],
                            [
                                22.1363,
                                36.9297
                            ],
                            [
                                22.361,
                                37.8866
                            ],
                            [
                                22.5966,
                                38.842
                            ],
                            [
                                22.8436,
                                39.7959
                            ],
                            [
                                23.1027,
                                40.7483
                            ],
                            [
                                23.3745,
                                41.699
                            ],
                            [
                                23.6596,
                                42.648
                            ],
                            [
                                23.9589,
                                43.5952
                            ],
                            [
                                24.2731,
                                44.5404
                            ],
                            [
                                24.6032,
                                45.4835
                            ],
                            [
                                24.95,
                                46.4245
                            ],
                            [
                                25.3146,
                                47.3631
                            ],
                            [
                                25.6981,
                                48.2993
                            ],
                            [
                                26.1017,
                                49.2328
                            ],
                            [
                                26.5267,
                                50.1636
                            ],
                            [
                                26.9746,
                                51.0913
                            ],
                            [
                                27.4468,
                                52.0159
                            ],
                            [
                                27.9452,
                                52.937
                            ],
                            [
                                28.4715,
                                53.8545
                            ],
                            [
                                29.0278,
                                54.768
                            ],
                            [
                                29.6162,
                                55.6773
                            ],
                            [
                                30.2392,
                                56.582
                            ],
                            [
                                30.8995,
                                57.4818
                            ],
                            [
                                31.5999,
                                58.3763
                            ],
                            [
                                32.3437,
                                59.2649
                            ],
                            [
                                33.0727,
                                60.0785
                            ],
                            [
                                35.8771,
                                60.2528
                            ],
                            [
                                38.7219,
                                60.6504
                            ],
                            [
                                39.0473,
                                60.6727
                            ],
                            [
                                40.8112,
                                61.2478
                            ],
                            [
                                43.0418,
                                61.7711
                            ],
                            [
                                45.682,
                                62.1042
                            ],
                            [
                                46.2078,
                                62.1351
                            ],
                            [
                                48.0164,
                                62.5221
                            ],
                            [
                                51.3219,
                                62.4458
                            ],
                            [
                                54.6991,
                                62.2045
                            ],
                            [
                                57.8704,
                                61.9385
                            ],
                            [
                                60.8369,
                                61.6706
                            ],
                            [
                                63.6559,
                                61.382
                            ],
                            [
                                66.3769,
                                61.0438
                            ],
                            [
                                69.0347,
                                60.6218
                            ],
                            [
                                71.6399,
                                60.0904
                            ],
                            [
                                74.1834,
                                59.4367
                            ],
                            [
                                74.7909,
                                59.2425
                            ],
                            [
                                76.7663,
                                58.4572
                            ],
                            [
                                79.2103,
                                57.3586
                            ],
                            [
                                80.6172,
                                56.6583
                            ],
                            [
                                81.5197,
                                56.1187
                            ],
                            [
                                83.6909,
                                54.7271
                            ],
                            [
                                84.0699,
                                54.4605
                            ],
                            [
                                85.7744,
                                52.9503
                            ],
                            [
                                86.2457,
                                52.4858
                            ],
                            [
                                87.484,
                                50.667
                            ],
                            [
                                87.8964,
                                48.9682
                            ],
                            [
                                87.3092,
                                47.3727
                            ],
                            [
                                86.9614,
                                47.056
                            ],
                            [
                                85.8217,
                                45.9417
                            ],
                            [
                                85.7671,
                                45.8702
                            ],
                            [
                                84.7822,
                                44.5407
                            ],
                            [
                                84.7282,
                                44.4203
                            ],
                            [
                                84.2269,
                                43.0114
                            ],
                            [
                                84.0088,
                                42.0587
                            ],
                            [
                                83.8649,
                                41.6467
                            ],
                            [
                                83.1931,
                                40.333
                            ],
                            [
                                83.1419,
                                40.2704
                            ],
                            [
                                82.0967,
                                39.5014
                            ],
                            [
                                81.1976,
                                39.0961
                            ],
                            [
                                80.9996,
                                39.0349
                            ],
                            [
                                79.8559,
                                38.7884
                            ],
                            [
                                78.7052,
                                38.5674
                            ],
                            [
                                77.5688,
                                38.2895
                            ],
                            [
                                76.7461,
                                37.9876
                            ],
                            [
                                76.4724,
                                37.8757
                            ],
                            [
                                75.4302,
                                37.2981
                            ],
                            [
                                74.7885,
                                36.8315
                            ],
                            [
                                74.4422,
                                36.5713
                            ],
                            [
                                73.4659,
                                35.8334
                            ],
                            [
                                73.2767,
                                35.6876
                            ],
                            [
                                72.4934,
                                35.1049
                            ],
                            [
                                71.8389,
                                34.5671
                            ],
                            [
                                71.5647,
                                34.2651
                            ],
                            [
                                70.8484,
                                33.4507
                            ],
                            [
                                70.7323,
                                33.164
                            ],
                            [
                                70.2704,
                                32.3374
                            ],
                            [
                                70.04,
                                31.6663
                            ],
                            [
                                69.752,
                                31.2411
                            ],
                            [
                                69.184,
                                30.6593
                            ],
                            [
                                68.3806,
                                30.1997
                            ],
                            [
                                68.1699,
                                30.1184
                            ],
                            [
                                67.1439,
                                29.5998
                            ],
                            [
                                66.7839,
                                29.1848
                            ],
                            [
                                66.4419,
                                28.1531
                            ],
                            [
                                66.4404,
                                28.1296
                            ],
                            [
                                66.4629,
                                27.0734
                            ],
                            [
                                66.4804,
                                26.0317
                            ],
                            [
                                66.3541,
                                25.009
                            ],
                            [
                                66.3116,
                                24.892
                            ],
                            [
                                65.8962,
                                24.0109
                            ],
                            [
                                65.5462,
                                23.5351
                            ],
                            [
                                65.0877,
                                23.0362
                            ],
                            [
                                64.6424,
                                22.614
                            ],
                            [
                                63.9147,
                                22.0839
                            ],
                            [
                                63.6706,
                                21.8922
                            ],
                            [
                                62.6403,
                                21.3297
                            ],
                            [
                                62.1785,
                                21.1602
                            ],
                            [
                                61.5667,
                                20.865
                            ],
                            [
                                60.4493,
                                20.4888
                            ],
                            [
                                59.5814,
                                20.2751
                            ],
                            [
                                59.302,
                                20.1459
                            ],
                            [
                                58.1431,
                                19.7723
                            ],
                            [
                                56.9218,
                                19.5129
                            ],
                            [
                                55.5486,
                                19.5984
                            ],
                            [
                                53.8963,
                                20.5033
                            ],
                            [
                                53.7892,
                                20.5487
                            ],
                            [
                                51.9984,
                                21.5843
                            ],
                            [
                                51.8439,
                                21.6535
                            ],
                            [
                                49.8846,
                                22.4931
                            ],
                            [
                                49.1565,
                                22.7324
                            ],
                            [
                                47.9543,
                                23.0332
                            ],
                            [
                                46.1081,
                                23.2326
                            ],
                            [
                                44.3548,
                                23.128
                            ],
                            [
                                43.8284,
                                23.0102
                            ],
                            [
                                44.2143,
                                21.9639
                            ],
                            [
                                45.6957,
                                20.9707
                            ],
                            [
                                45.8347,
                                20.8692
                            ],
                            [
                                47.5038,
                                19.7901
                            ],
                            [
                                48.3517,
                                19.1116
                            ],
                            [
                                48.9214,
                                18.7388
                            ],
                            [
                                49.7132,
                                17.727
                            ],
                            [
                                49.3274,
                                16.8436
                            ],
                            [
                                49.275,
                                16.7716
                            ],
                            [
                                48.0579,
                                16.0463
                            ],
                            [
                                47.5896,
                                15.8658
                            ],
                            [
                                47.5447,
                                14.904
                            ],
                            [
                                48.2945,
                                13.9224
                            ],
                            [
                                48.4949,
                                12.9657
                            ],
                            [
                                47.739,
                                12.3802
                            ],
                            [
                                47.0349,
                                12.0603
                            ],
                            [
                                46.2333,
                                11.9661
                            ],
                            [
                                44.5135,
                                11.9799
                            ],
                            [
                                42.9393,
                                12.1773
                            ],
                            [
                                42.5976,
                                12.2481
                            ],
                            [
                                40.3102,
                                13.0458
                            ],
                            [
                                39.8761,
                                13.2335
                            ],
                            [
                                38.7142,
                                14.2442
                            ],
                            [
                                38.8761,
                                15.2159
                            ],
                            [
                                39.1018,
                                15.47
                            ],
                            [
                                40.6329,
                                16.1291
                            ],
                            [
                                40.5531,
                                16.6493
                            ],
                            [
                                40.5227,
                                17.119
                            ],
                            [
                                40.2202,
                                17.2269
                            ],
                            [
                                38.3531,
                                18.2081
                            ],
                            [
                                36.9557,
                                18.7709
                            ],
                            [
                                35.7606,
                                19.3375
                            ],
                            [
                                33.5926,
                                20.4681
                            ],
                            [
                                32.4827,
                                21.5614
                            ],
                            [
                                32.7572,
                                22.59
                            ],
                            [
                                32.9977,
                                23.0287
                            ],
                            [
                                33.8069,
                                23.5806
                            ],
                            [
                                32.8265,
                                24.7026
                            ],
                            [
                                29.3922,
                                25.7059
                            ],
                            [
                                28.1147,
                                26.0883
                            ],
                            [
                                20.7421,
                                27.3128
                            ],
                            [
                                20.398,
                                27.3478
                            ]
                        ]
                    ]
                },
                "type": "Feature",
                "properties": {
                    "obj_child_num": 0,
                    "obj_revision": 7,
                    "obj_id": 7531,
                    "obj_version": "",
                    "gain": 0,
                    "obj_name": "SES-12_ME12_ADL",
                    "obj_parentid": 2756
                }
            },
            {
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                20.398,
                                27.3478
                            ],
                            [
                                20.5299,
                                28.2656
                            ],
                            [
                                20.676,
                                29.2323
                            ],
                            [
                                20.8294,
                                30.1982
                            ],
                            [
                                20.9905,
                                31.163
                            ],
                            [
                                21.1595,
                                32.1269
                            ],
                            [
                                21.3367,
                                33.0898
                            ],
                            [
                                21.5227,
                                34.0515
                            ],
                            [
                                21.7176,
                                35.0122
                            ],
                            [
                                21.922,
                                35.9716
                            ],
                            [
                                22.1363,
                                36.9297
                            ],
                            [
                                22.361,
                                37.8866
                            ],
                            [
                                22.5966,
                                38.842
                            ],
                            [
                                22.8436,
                                39.7959
                            ],
                            [
                                23.1027,
                                40.7483
                            ],
                            [
                                23.3745,
                                41.699
                            ],
                            [
                                23.6596,
                                42.648
                            ],
                            [
                                23.9589,
                                43.5952
                            ],
                            [
                                24.2731,
                                44.5404
                            ],
                            [
                                24.6032,
                                45.4835
                            ],
                            [
                                24.95,
                                46.4245
                            ],
                            [
                                25.3146,
                                47.3631
                            ],
                            [
                                25.6981,
                                48.2993
                            ],
                            [
                                26.1017,
                                49.2328
                            ],
                            [
                                26.5267,
                                50.1636
                            ],
                            [
                                26.9746,
                                51.0913
                            ],
                            [
                                27.4468,
                                52.0159
                            ],
                            [
                                27.9452,
                                52.937
                            ],
                            [
                                28.4715,
                                53.8545
                            ],
                            [
                                29.0278,
                                54.768
                            ],
                            [
                                29.6162,
                                55.6773
                            ],
                            [
                                30.2392,
                                56.582
                            ],
                            [
                                30.8995,
                                57.4818
                            ],
                            [
                                31.5999,
                                58.3763
                            ],
                            [
                                32.3437,
                                59.2649
                            ],
                            [
                                33.0727,
                                60.0785
                            ],
                            [
                                35.8771,
                                60.2528
                            ],
                            [
                                38.7219,
                                60.6504
                            ],
                            [
                                39.0473,
                                60.6727
                            ],
                            [
                                40.8112,
                                61.2478
                            ],
                            [
                                43.0418,
                                61.7711
                            ],
                            [
                                45.682,
                                62.1042
                            ],
                            [
                                46.2078,
                                62.1351
                            ],
                            [
                                48.0164,
                                62.5221
                            ],
                            [
                                51.3219,
                                62.4458
                            ],
                            [
                                54.6991,
                                62.2045
                            ],
                            [
                                57.8704,
                                61.9385
                            ],
                            [
                                60.8369,
                                61.6706
                            ],
                            [
                                63.6559,
                                61.382
                            ],
                            [
                                66.3769,
                                61.0438
                            ],
                            [
                                69.0347,
                                60.6218
                            ],
                            [
                                71.6399,
                                60.0904
                            ],
                            [
                                74.1834,
                                59.4367
                            ],
                            [
                                74.7909,
                                59.2425
                            ],
                            [
                                76.7663,
                                58.4572
                            ],
                            [
                                79.2103,
                                57.3586
                            ],
                            [
                                80.6172,
                                56.6583
                            ],
                            [
                                81.5197,
                                56.1187
                            ],
                            [
                                83.6909,
                                54.7271
                            ],
                            [
                                84.0699,
                                54.4605
                            ],
                            [
                                85.7744,
                                52.9503
                            ],
                            [
                                86.2457,
                                52.4858
                            ],
                            [
                                87.484,
                                50.667
                            ],
                            [
                                87.8964,
                                48.9682
                            ],
                            [
                                87.3092,
                                47.3727
                            ],
                            [
                                86.9614,
                                47.056
                            ],
                            [
                                85.8217,
                                45.9417
                            ],
                            [
                                85.7671,
                                45.8702
                            ],
                            [
                                84.7822,
                                44.5407
                            ],
                            [
                                84.7282,
                                44.4203
                            ],
                            [
                                84.2269,
                                43.0114
                            ],
                            [
                                84.0088,
                                42.0587
                            ],
                            [
                                83.8649,
                                41.6467
                            ],
                            [
                                83.1931,
                                40.333
                            ],
                            [
                                83.1419,
                                40.2704
                            ],
                            [
                                82.0967,
                                39.5014
                            ],
                            [
                                81.1976,
                                39.0961
                            ],
                            [
                                80.9996,
                                39.0349
                            ],
                            [
                                79.8559,
                                38.7884
                            ],
                            [
                                78.7052,
                                38.5674
                            ],
                            [
                                77.5688,
                                38.2895
                            ],
                            [
                                76.7461,
                                37.9876
                            ],
                            [
                                76.4724,
                                37.8757
                            ],
                            [
                                75.4302,
                                37.2981
                            ],
                            [
                                74.7885,
                                36.8315
                            ],
                            [
                                74.4422,
                                36.5713
                            ],
                            [
                                73.4659,
                                35.8334
                            ],
                            [
                                73.2767,
                                35.6876
                            ],
                            [
                                72.4934,
                                35.1049
                            ],
                            [
                                71.8389,
                                34.5671
                            ],
                            [
                                71.5647,
                                34.2651
                            ],
                            [
                                70.8484,
                                33.4507
                            ],
                            [
                                70.7323,
                                33.164
                            ],
                            [
                                70.2704,
                                32.3374
                            ],
                            [
                                70.04,
                                31.6663
                            ],
                            [
                                69.752,
                                31.2411
                            ],
                            [
                                69.184,
                                30.6593
                            ],
                            [
                                68.3806,
                                30.1997
                            ],
                            [
                                68.1699,
                                30.1184
                            ],
                            [
                                67.1439,
                                29.5998
                            ],
                            [
                                66.7839,
                                29.1848
                            ],
                            [
                                66.4419,
                                28.1531
                            ],
                            [
                                66.4404,
                                28.1296
                            ],
                            [
                                66.4629,
                                27.0734
                            ],
                            [
                                66.4804,
                                26.0317
                            ],
                            [
                                66.3541,
                                25.009
                            ],
                            [
                                66.3116,
                                24.892
                            ],
                            [
                                65.8962,
                                24.0109
                            ],
                            [
                                65.5462,
                                23.5351
                            ],
                            [
                                65.0877,
                                23.0362
                            ],
                            [
                                64.6424,
                                22.614
                            ],
                            [
                                63.9147,
                                22.0839
                            ],
                            [
                                63.6706,
                                21.8922
                            ],
                            [
                                62.6403,
                                21.3297
                            ],
                            [
                                62.1785,
                                21.1602
                            ],
                            [
                                61.5667,
                                20.865
                            ],
                            [
                                60.4493,
                                20.4888
                            ],
                            [
                                59.5814,
                                20.2751
                            ],
                            [
                                59.302,
                                20.1459
                            ],
                            [
                                58.1431,
                                19.7723
                            ],
                            [
                                56.9218,
                                19.5129
                            ],
                            [
                                55.5486,
                                19.5984
                            ],
                            [
                                53.8963,
                                20.5033
                            ],
                            [
                                53.7892,
                                20.5487
                            ],
                            [
                                51.9984,
                                21.5843
                            ],
                            [
                                51.8439,
                                21.6535
                            ],
                            [
                                49.8846,
                                22.4931
                            ],
                            [
                                49.1565,
                                22.7324
                            ],
                            [
                                47.9543,
                                23.0332
                            ],
                            [
                                46.1081,
                                23.2326
                            ],
                            [
                                44.3548,
                                23.128
                            ],
                            [
                                43.8284,
                                23.0102
                            ],
                            [
                                44.2143,
                                21.9639
                            ],
                            [
                                45.6957,
                                20.9707
                            ],
                            [
                                45.8347,
                                20.8692
                            ],
                            [
                                47.5038,
                                19.7901
                            ],
                            [
                                48.3517,
                                19.1116
                            ],
                            [
                                48.9214,
                                18.7388
                            ],
                            [
                                49.7132,
                                17.727
                            ],
                            [
                                49.3274,
                                16.8436
                            ],
                            [
                                49.275,
                                16.7716
                            ],
                            [
                                48.0579,
                                16.0463
                            ],
                            [
                                47.5896,
                                15.8658
                            ],
                            [
                                47.5447,
                                14.904
                            ],
                            [
                                48.2945,
                                13.9224
                            ],
                            [
                                48.4949,
                                12.9657
                            ],
                            [
                                47.739,
                                12.3802
                            ],
                            [
                                47.0349,
                                12.0603
                            ],
                            [
                                46.2333,
                                11.9661
                            ],
                            [
                                44.5135,
                                11.9799
                            ],
                            [
                                42.9393,
                                12.1773
                            ],
                            [
                                42.5976,
                                12.2481
                            ],
                            [
                                40.3102,
                                13.0458
                            ],
                            [
                                39.8761,
                                13.2335
                            ],
                            [
                                38.7142,
                                14.2442
                            ],
                            [
                                38.8761,
                                15.2159
                            ],
                            [
                                39.1018,
                                15.47
                            ],
                            [
                                40.6329,
                                16.1291
                            ],
                            [
                                40.5531,
                                16.6493
                            ],
                            [
                                40.5227,
                                17.119
                            ],
                            [
                                40.2202,
                                17.2269
                            ],
                            [
                                38.3531,
                                18.2081
                            ],
                            [
                                36.9557,
                                18.7709
                            ],
                            [
                                35.7606,
                                19.3375
                            ],
                            [
                                33.5926,
                                20.4681
                            ],
                            [
                                32.4827,
                                21.5614
                            ],
                            [
                                32.7572,
                                22.59
                            ],
                            [
                                32.9977,
                                23.0287
                            ],
                            [
                                33.8069,
                                23.5806
                            ],
                            [
                                32.8265,
                                24.7026
                            ],
                            [
                                29.3922,
                                25.7059
                            ],
                            [
                                28.1147,
                                26.0883
                            ],
                            [
                                20.7421,
                                27.3128
                            ],
                            [
                                20.398,
                                27.3478
                            ]
                        ]
                    ]
                },
                "type": "Feature",
                "properties": {
                    "obj_child_num": 0,
                    "obj_revision": 7,
                    "obj_id": 7531,
                    "obj_version": "",
                    "gain": 4500,
                    "obj_name": "SES-12_ME12_ADL",
                    "obj_parentid": 2756
                }
            },
            {
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                139.756,
                                15.7958
                            ],
                            [
                                140.465,
                                16.5652
                            ],
                            [
                                140.626,
                                16.7004
                            ],
                            [
                                141.96,
                                17.6326
                            ],
                            [
                                142.302,
                                17.8515
                            ],
                            [
                                143.863,
                                18.6012
                            ],
                            [
                                144.087,
                                18.7027
                            ],
                            [
                                145.827,
                                19.2295
                            ],
                            [
                                147.538,
                                19.5091
                            ],
                            [
                                149.203,
                                19.5449
                            ],
                            [
                                150.859,
                                19.4138
                            ],
                            [
                                152.216,
                                18.9784
                            ],
                            [
                                152.362,
                                18.9253
                            ],
                            [
                                153.654,
                                18.1343
                            ],
                            [
                                153.71,
                                18.0988
                            ],
                            [
                                154.891,
                                17.2817
                            ],
                            [
                                155.073,
                                17.1538
                            ],
                            [
                                155.981,
                                16.4234
                            ],
                            [
                                156.444,
                                16.0582
                            ],
                            [
                                157.085,
                                15.5664
                            ],
                            [
                                157.883,
                                14.8543
                            ],
                            [
                                158.034,
                                14.7035
                            ],
                            [
                                158.673,
                                13.8299
                            ],
                            [
                                159.051,
                                13.0342
                            ],
                            [
                                159.085,
                                12.95
                            ],
                            [
                                159.132,
                                12.0605
                            ],
                            [
                                159.04,
                                11.1703
                            ],
                            [
                                158.708,
                                10.2774
                            ],
                            [
                                157.977,
                                9.3791
                            ],
                            [
                                156.835,
                                8.4779
                            ],
                            [
                                156.42,
                                8.1875
                            ],
                            [
                                155.516,
                                7.581
                            ],
                            [
                                153.941,
                                6.688
                            ],
                            [
                                153.821,
                                6.6282
                            ],
                            [
                                151.971,
                                5.7983
                            ],
                            [
                                151.724,
                                5.6921
                            ],
                            [
                                149.908,
                                4.9182
                            ],
                            [
                                149.853,
                                4.8928
                            ],
                            [
                                148.187,
                                4.4969
                            ],
                            [
                                146.657,
                                4.3847
                            ],
                            [
                                145.239,
                                4.5597
                            ],
                            [
                                144.547,
                                4.8547
                            ],
                            [
                                143.936,
                                5.1343
                            ],
                            [
                                143.153,
                                5.6755
                            ],
                            [
                                142.726,
                                5.9651
                            ],
                            [
                                142.328,
                                6.5013
                            ],
                            [
                                141.648,
                                7.2495
                            ],
                            [
                                141.605,
                                7.3277
                            ],
                            [
                                141.039,
                                8.1565
                            ],
                            [
                                140.664,
                                8.6769
                            ],
                            [
                                140.511,
                                8.9864
                            ],
                            [
                                139.884,
                                9.8149
                            ],
                            [
                                139.724,
                                10.016
                            ],
                            [
                                139.377,
                                10.647
                            ],
                            [
                                138.858,
                                11.436
                            ],
                            [
                                138.839,
                                11.4796
                            ],
                            [
                                138.637,
                                12.3229
                            ],
                            [
                                138.576,
                                13.1733
                            ],
                            [
                                138.745,
                                14.0346
                            ],
                            [
                                139.126,
                                14.9078
                            ],
                            [
                                139.756,
                                15.7958
                            ]
                        ]
                    ]
                },
                "type": "Feature",
                "properties": {
                    "obj_child_num": 0,
                    "obj_revision": 7,
                    "obj_id": 7535,
                    "obj_version": "",
                    "gain": 0,
                    "obj_name": "SES-12_NE_Asia01_ADL",
                    "obj_parentid": 2756
                }
            },
            {
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                114.079,
                                44.6037
                            ],
                            [
                                119.97,
                                48.0184
                            ],
                            [
                                126.884,
                                50.6647
                            ],
                            [
                                137.811,
                                56.2989
                            ],
                            [
                                142.848,
                                58.6037
                            ],
                            [
                                151.384,
                                61.0794
                            ],
                            [
                                157.018,
                                62.0184
                            ],
                            [
                                159.665,
                                57.494
                            ],
                            [
                                160.348,
                                52.2867
                            ],
                            [
                                160.518,
                                44.8598
                            ],
                            [
                                160.518,
                                37.8598
                            ],
                            [
                                153.348,
                                35.7257
                            ],
                            [
                                146.262,
                                32.5671
                            ],
                            [
                                142.165,
                                30.5183
                            ],
                            [
                                137.214,
                                26.5061
                            ],
                            [
                                131.579,
                                21.8964
                            ],
                            [
                                130.555,
                                13.9573
                            ],
                            [
                                131.409,
                                9.689
                            ],
                            [
                                130.896,
                                5.3354
                            ],
                            [
                                130.299,
                                1.6646
                            ],
                            [
                                128.677,
                                -0.6402
                            ],
                            [
                                127.482,
                                -1.3232
                            ],
                            [
                                125.689,
                                -0.9817
                            ],
                            [
                                122.104,
                                -0.2988
                            ],
                            [
                                119.457,
                                -0.2134
                            ],
                            [
                                116.555,
                                0.1281
                            ],
                            [
                                114.848,
                                1.4085
                            ],
                            [
                                112.969,
                                4.311
                            ],
                            [
                                112.031,
                                13.2744
                            ],
                            [
                                110.409,
                                19.1647
                            ],
                            [
                                110.409,
                                25.4817
                            ],
                            [
                                110.494,
                                32.3964
                            ],
                            [
                                111.091,
                                38.5427
                            ],
                            [
                                112.457,
                                42.1281
                            ],
                            [
                                114.079,
                                44.5184
                            ],
                            [
                                114.079,
                                44.6037
                            ]
                        ]
                    ]
                },
                "type": "Feature",
                "properties": {
                    "obj_child_num": 0,
                    "obj_revision": 7,
                    "obj_id": 7535,
                    "obj_version": "",
                    "gain": 0,
                    "obj_name": "SES-12_NE_Asia01_ADL",
                    "obj_parentid": 2756
                }
            },
            {
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                139.756,
                                15.7958
                            ],
                            [
                                140.465,
                                16.5652
                            ],
                            [
                                140.626,
                                16.7004
                            ],
                            [
                                141.96,
                                17.6326
                            ],
                            [
                                142.302,
                                17.8515
                            ],
                            [
                                143.863,
                                18.6012
                            ],
                            [
                                144.087,
                                18.7027
                            ],
                            [
                                145.827,
                                19.2295
                            ],
                            [
                                147.538,
                                19.5091
                            ],
                            [
                                149.203,
                                19.5449
                            ],
                            [
                                150.859,
                                19.4138
                            ],
                            [
                                152.216,
                                18.9784
                            ],
                            [
                                152.362,
                                18.9253
                            ],
                            [
                                153.654,
                                18.1343
                            ],
                            [
                                153.71,
                                18.0988
                            ],
                            [
                                154.891,
                                17.2817
                            ],
                            [
                                155.073,
                                17.1538
                            ],
                            [
                                155.981,
                                16.4234
                            ],
                            [
                                156.444,
                                16.0582
                            ],
                            [
                                157.085,
                                15.5664
                            ],
                            [
                                157.883,
                                14.8543
                            ],
                            [
                                158.034,
                                14.7035
                            ],
                            [
                                158.673,
                                13.8299
                            ],
                            [
                                159.051,
                                13.0342
                            ],
                            [
                                159.085,
                                12.95
                            ],
                            [
                                159.132,
                                12.0605
                            ],
                            [
                                159.04,
                                11.1703
                            ],
                            [
                                158.708,
                                10.2774
                            ],
                            [
                                157.977,
                                9.3791
                            ],
                            [
                                156.835,
                                8.4779
                            ],
                            [
                                156.42,
                                8.1875
                            ],
                            [
                                155.516,
                                7.581
                            ],
                            [
                                153.941,
                                6.688
                            ],
                            [
                                153.821,
                                6.6282
                            ],
                            [
                                151.971,
                                5.7983
                            ],
                            [
                                151.724,
                                5.6921
                            ],
                            [
                                149.908,
                                4.9182
                            ],
                            [
                                149.853,
                                4.8928
                            ],
                            [
                                148.187,
                                4.4969
                            ],
                            [
                                146.657,
                                4.3847
                            ],
                            [
                                145.239,
                                4.5597
                            ],
                            [
                                144.547,
                                4.8547
                            ],
                            [
                                143.936,
                                5.1343
                            ],
                            [
                                143.153,
                                5.6755
                            ],
                            [
                                142.726,
                                5.9651
                            ],
                            [
                                142.328,
                                6.5013
                            ],
                            [
                                141.648,
                                7.2495
                            ],
                            [
                                141.605,
                                7.3277
                            ],
                            [
                                141.039,
                                8.1565
                            ],
                            [
                                140.664,
                                8.6769
                            ],
                            [
                                140.511,
                                8.9864
                            ],
                            [
                                139.884,
                                9.8149
                            ],
                            [
                                139.724,
                                10.016
                            ],
                            [
                                139.377,
                                10.647
                            ],
                            [
                                138.858,
                                11.436
                            ],
                            [
                                138.839,
                                11.4796
                            ],
                            [
                                138.637,
                                12.3229
                            ],
                            [
                                138.576,
                                13.1733
                            ],
                            [
                                138.745,
                                14.0346
                            ],
                            [
                                139.126,
                                14.9078
                            ],
                            [
                                139.756,
                                15.7958
                            ]
                        ]
                    ]
                },
                "type": "Feature",
                "properties": {
                    "obj_child_num": 0,
                    "obj_revision": 7,
                    "obj_id": 7535,
                    "obj_version": "",
                    "gain": 4500,
                    "obj_name": "SES-12_NE_Asia01_ADL",
                    "obj_parentid": 2756
                }
            },
            {
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                114.079,
                                44.6037
                            ],
                            [
                                119.97,
                                48.0184
                            ],
                            [
                                126.884,
                                50.6647
                            ],
                            [
                                137.811,
                                56.2989
                            ],
                            [
                                142.848,
                                58.6037
                            ],
                            [
                                151.384,
                                61.0794
                            ],
                            [
                                157.018,
                                62.0184
                            ],
                            [
                                159.665,
                                57.494
                            ],
                            [
                                160.348,
                                52.2867
                            ],
                            [
                                160.518,
                                44.8598
                            ],
                            [
                                160.518,
                                37.8598
                            ],
                            [
                                153.348,
                                35.7257
                            ],
                            [
                                146.262,
                                32.5671
                            ],
                            [
                                142.165,
                                30.5183
                            ],
                            [
                                137.214,
                                26.5061
                            ],
                            [
                                131.579,
                                21.8964
                            ],
                            [
                                130.555,
                                13.9573
                            ],
                            [
                                131.409,
                                9.689
                            ],
                            [
                                130.896,
                                5.3354
                            ],
                            [
                                130.299,
                                1.6646
                            ],
                            [
                                128.677,
                                -0.6402
                            ],
                            [
                                127.482,
                                -1.3232
                            ],
                            [
                                125.689,
                                -0.9817
                            ],
                            [
                                122.104,
                                -0.2988
                            ],
                            [
                                119.457,
                                -0.2134
                            ],
                            [
                                116.555,
                                0.1281
                            ],
                            [
                                114.848,
                                1.4085
                            ],
                            [
                                112.969,
                                4.311
                            ],
                            [
                                112.031,
                                13.2744
                            ],
                            [
                                110.409,
                                19.1647
                            ],
                            [
                                110.409,
                                25.4817
                            ],
                            [
                                110.494,
                                32.3964
                            ],
                            [
                                111.091,
                                38.5427
                            ],
                            [
                                112.457,
                                42.1281
                            ],
                            [
                                114.079,
                                44.5184
                            ],
                            [
                                114.079,
                                44.6037
                            ]
                        ]
                    ]
                },
                "type": "Feature",
                "properties": {
                    "obj_child_num": 0,
                    "obj_revision": 7,
                    "obj_id": 7535,
                    "obj_version": "",
                    "gain": 4500,
                    "obj_name": "SES-12_NE_Asia01_ADL",
                    "obj_parentid": 2756
                }
            },
            {
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -70.6179,
                                48.8929
                            ],
                            [
                                -55.4717,
                                57.6618
                            ],
                            [
                                -39.5283,
                                60.319
                            ],
                            [
                                -31.9552,
                                61.1162
                            ],
                            [
                                -19.5991,
                                59.1233
                            ],
                            [
                                -6.313,
                                50.8859
                            ],
                            [
                                -6.8445,
                                38.9283
                            ],
                            [
                                -4.9844,
                                33.8796
                            ],
                            [
                                -0.8657,
                                29.3623
                            ],
                            [
                                4.4487,
                                19.6635
                            ],
                            [
                                4.5816,
                                16.4748
                            ],
                            [
                                3.5187,
                                14.4819
                            ],
                            [
                                -16.2776,
                                12.3561
                            ],
                            [
                                -13.0889,
                                6.3773
                            ],
                            [
                                -8.5717,
                                3.5873
                            ],
                            [
                                3.3859,
                                0.7972
                            ],
                            [
                                6.8403,
                                -5.5802
                            ],
                            [
                                10.0289,
                                -20.7264
                            ],
                            [
                                4.7145,
                                -23.915
                            ],
                            [
                                -14.4176,
                                -28.9638
                            ],
                            [
                                -24.515,
                                -31.2224
                            ],
                            [
                                -42.0527,
                                -32.8167
                            ],
                            [
                                -49.3601,
                                -34.1453
                            ],
                            [
                                -48.8286,
                                -30.6909
                            ],
                            [
                                -39.2626,
                                -21.9221
                            ],
                            [
                                -32.3538,
                                -6.6431
                            ],
                            [
                                -35.8082,
                                -1.4615
                            ],
                            [
                                -45.2414,
                                1.7272
                            ],
                            [
                                -48.2972,
                                6.6431
                            ],
                            [
                                -61.1847,
                                11.6918
                            ],
                            [
                                -79.7853,
                                1.5943
                            ],
                            [
                                -99.0502,
                                2.5244
                            ],
                            [
                                -114.462,
                                4.2516
                            ],
                            [
                                -118.315,
                                13.0204
                            ],
                            [
                                -127.217,
                                20.9921
                            ],
                            [
                                -125.357,
                                31.7538
                            ],
                            [
                                -114.329,
                                22.4536
                            ],
                            [
                                -109.679,
                                18.6006
                            ],
                            [
                                -99.183,
                                12.2232
                            ],
                            [
                                -92.4071,
                                8.7688
                            ],
                            [
                                -78.7224,
                                4.6501
                            ],
                            [
                                -71.0164,
                                8.2374
                            ],
                            [
                                -66.632,
                                11.2932
                            ],
                            [
                                -69.9536,
                                15.279
                            ],
                            [
                                -72.3451,
                                22.3207
                            ],
                            [
                                -75.1351,
                                28.4323
                            ],
                            [
                                -74.8694,
                                35.2082
                            ],
                            [
                                -70.7507,
                                48.6272
                            ],
                            [
                                -70.6179,
                                48.8929
                            ]
                        ]
                    ]
                },
                "type": "Feature",
                "properties": {
                    "obj_child_num": 0,
                    "obj_revision": 6,
                    "obj_id": 108259,
                    "obj_version": "",
                    "gain": 0,
                    "obj_name": "SES-14_Beam_Atlantic03_BTZ",
                    "obj_parentid": 53931
                }
            },
            {
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                1.4177,
                                -1.0293
                            ],
                            [
                                -4.3869,
                                -3.8363
                            ],
                            [
                                -9.817,
                                -7.2983
                            ],
                            [
                                -18.4302,
                                -11.1347
                            ],
                            [
                                -26.2009,
                                -13.661
                            ],
                            [
                                -32.0055,
                                -15.0645
                            ],
                            [
                                -34.2524,
                                -14.8774
                            ],
                            [
                                -31.8182,
                                -9.6376
                            ],
                            [
                                -30.7884,
                                -6.6434
                            ],
                            [
                                -32.5672,
                                -2.7135
                            ],
                            [
                                -36.6866,
                                1.31
                            ],
                            [
                                -41.2741,
                                1.5907
                            ],
                            [
                                -44.7381,
                                2.8071
                            ],
                            [
                                -47.3596,
                                7.2983
                            ],
                            [
                                -55.5983,
                                10.9475
                            ],
                            [
                                -59.9986,
                                12.6318
                            ],
                            [
                                -63.5562,
                                13.5674
                            ],
                            [
                                -67.8629,
                                17.4973
                            ],
                            [
                                -70.9524,
                                23.5793
                            ],
                            [
                                -73.1994,
                                30.3162
                            ],
                            [
                                -72.1695,
                                36.7724
                            ],
                            [
                                -70.6715,
                                42.1059
                            ],
                            [
                                -67.582,
                                46.6907
                            ],
                            [
                                -62.2455,
                                51.4627
                            ],
                            [
                                -56.3473,
                                54.9247
                            ],
                            [
                                -48.3894,
                                58.0125
                            ],
                            [
                                -37.9973,
                                59.6967
                            ],
                            [
                                -28.9159,
                                59.5096
                            ],
                            [
                                -21.5198,
                                58.6675
                            ],
                            [
                                -18.243,
                                57.4511
                            ],
                            [
                                -10.8468,
                                52.7727
                            ],
                            [
                                -7.9445,
                                50.527
                            ],
                            [
                                -7.8509,
                                44.6322
                            ],
                            [
                                -8.7871,
                                38.176
                            ],
                            [
                                -8.7871,
                                34.4332
                            ],
                            [
                                -10.1915,
                                30.1291
                            ],
                            [
                                -14.8726,
                                25.8249
                            ],
                            [
                                -15.9961,
                                19.1816
                            ],
                            [
                                -16.8387,
                                13.4739
                            ],
                            [
                                -15.9024,
                                8.7954
                            ],
                            [
                                -12.4384,
                                4.9591
                            ],
                            [
                                -7.8509,
                                2.6199
                            ],
                            [
                                -2.1399,
                                1.4035
                            ],
                            [
                                1.8858,
                                -0.655
                            ],
                            [
                                1.4177,
                                -1.0293
                            ]
                        ]
                    ]
                },
                "type": "Feature",
                "properties": {
                    "obj_child_num": 0,
                    "obj_revision": 6,
                    "obj_id": 108259,
                    "obj_version": "",
                    "gain": 4500,
                    "obj_name": "SES-14_Beam_Atlantic03_BTZ",
                    "obj_parentid": 53931
                }
            },
            {
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -70.6179,
                                48.8929
                            ],
                            [
                                -55.4717,
                                57.6618
                            ],
                            [
                                -39.5283,
                                60.319
                            ],
                            [
                                -31.9552,
                                61.1162
                            ],
                            [
                                -19.5991,
                                59.1233
                            ],
                            [
                                -6.313,
                                50.8859
                            ],
                            [
                                -6.8445,
                                38.9283
                            ],
                            [
                                -4.9844,
                                33.8796
                            ],
                            [
                                -0.8657,
                                29.3623
                            ],
                            [
                                4.4487,
                                19.6635
                            ],
                            [
                                4.5816,
                                16.4748
                            ],
                            [
                                3.5187,
                                14.4819
                            ],
                            [
                                -16.2776,
                                12.3561
                            ],
                            [
                                -13.0889,
                                6.3773
                            ],
                            [
                                -8.5717,
                                3.5873
                            ],
                            [
                                3.3859,
                                0.7972
                            ],
                            [
                                6.8403,
                                -5.5802
                            ],
                            [
                                10.0289,
                                -20.7264
                            ],
                            [
                                4.7145,
                                -23.915
                            ],
                            [
                                -14.4176,
                                -28.9638
                            ],
                            [
                                -24.515,
                                -31.2224
                            ],
                            [
                                -42.0527,
                                -32.8167
                            ],
                            [
                                -49.3601,
                                -34.1453
                            ],
                            [
                                -48.8286,
                                -30.6909
                            ],
                            [
                                -39.2626,
                                -21.9221
                            ],
                            [
                                -32.3538,
                                -6.6431
                            ],
                            [
                                -35.8082,
                                -1.4615
                            ],
                            [
                                -45.2414,
                                1.7272
                            ],
                            [
                                -48.2972,
                                6.6431
                            ],
                            [
                                -61.1847,
                                11.6918
                            ],
                            [
                                -79.7853,
                                1.5943
                            ],
                            [
                                -99.0502,
                                2.5244
                            ],
                            [
                                -114.462,
                                4.2516
                            ],
                            [
                                -118.315,
                                13.0204
                            ],
                            [
                                -127.217,
                                20.9921
                            ],
                            [
                                -125.357,
                                31.7538
                            ],
                            [
                                -114.329,
                                22.4536
                            ],
                            [
                                -109.679,
                                18.6006
                            ],
                            [
                                -99.183,
                                12.2232
                            ],
                            [
                                -92.4071,
                                8.7688
                            ],
                            [
                                -78.7224,
                                4.6501
                            ],
                            [
                                -71.0164,
                                8.2374
                            ],
                            [
                                -66.632,
                                11.2932
                            ],
                            [
                                -69.9536,
                                15.279
                            ],
                            [
                                -72.3451,
                                22.3207
                            ],
                            [
                                -75.1351,
                                28.4323
                            ],
                            [
                                -74.8694,
                                35.2082
                            ],
                            [
                                -70.7507,
                                48.6272
                            ],
                            [
                                -70.6179,
                                48.8929
                            ]
                        ]
                    ]
                },
                "type": "Feature",
                "properties": {
                    "obj_child_num": 0,
                    "obj_revision": 6,
                    "obj_id": 108259,
                    "obj_version": "",
                    "gain": 5600,
                    "obj_name": "SES-14_Beam_Atlantic03_BTZ",
                    "obj_parentid": 53931
                }
            },
            {
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -72.6108,
                                24.7122
                            ],
                            [
                                -74.8694,
                                19.6635
                            ],
                            [
                                -76.4638,
                                17.4048
                            ],
                            [
                                -77.6595,
                                16.6077
                            ],
                            [
                                -82.4425,
                                17.9363
                            ],
                            [
                                -88.9527,
                                21.6564
                            ],
                            [
                                -95.0643,
                                25.1108
                            ],
                            [
                                -99.9802,
                                27.768
                            ],
                            [
                                -106.225,
                                32.551
                            ],
                            [
                                -112.336,
                                36.5368
                            ],
                            [
                                -117.784,
                                39.5926
                            ],
                            [
                                -121.769,
                                41.3199
                            ],
                            [
                                -124.958,
                                41.4527
                            ],
                            [
                                -124.958,
                                42.2499
                            ],
                            [
                                -124.559,
                                46.2357
                            ],
                            [
                                -123.364,
                                49.823
                            ],
                            [
                                -118.315,
                                49.5572
                            ],
                            [
                                -110.078,
                                49.0258
                            ],
                            [
                                -105.162,
                                48.7601
                            ],
                            [
                                -101.176,
                                50.8859
                            ],
                            [
                                -98.6516,
                                52.7459
                            ],
                            [
                                -95.9944,
                                54.3403
                            ],
                            [
                                -96.2601,
                                55.6689
                            ],
                            [
                                -97.1901,
                                57.3961
                            ],
                            [
                                -100.777,
                                58.8575
                            ],
                            [
                                -106.756,
                                61.5148
                            ],
                            [
                                -113.266,
                                62.7105
                            ],
                            [
                                -116.986,
                                62.9762
                            ],
                            [
                                -114.861,
                                65.8992
                            ],
                            [
                                -109.679,
                                70.6822
                            ],
                            [
                                -102.239,
                                74.1366
                            ],
                            [
                                -95.3301,
                                76.2624
                            ],
                            [
                                -89.7499,
                                77.7238
                            ],
                            [
                                -87.8898,
                                78.2553
                            ],
                            [
                                -80.981,
                                76.2624
                            ],
                            [
                                -74.8694,
                                74.1366
                            ],
                            [
                                -70.485,
                                72.1437
                            ],
                            [
                                -63.9748,
                                67.6264
                            ],
                            [
                                -60.5204,
                                64.0391
                            ],
                            [
                                -58.7932,
                                60.4519
                            ],
                            [
                                -59.0589,
                                59.2561
                            ],
                            [
                                -60.3875,
                                57.1303
                            ],
                            [
                                -62.9119,
                                55.2703
                            ],
                            [
                                -63.1776,
                                54.3403
                            ],
                            [
                                -59.8561,
                                49.6901
                            ],
                            [
                                -57.7303,
                                44.7742
                            ],
                            [
                                -56.8003,
                                42.2499
                            ],
                            [
                                -56.8003,
                                40.1241
                            ],
                            [
                                -58.1289,
                                38.264
                            ],
                            [
                                -60.7861,
                                38.5298
                            ],
                            [
                                -61.7162,
                                38.1312
                            ],
                            [
                                -63.0448,
                                32.9496
                            ],
                            [
                                -63.5762,
                                27.9009
                            ],
                            [
                                -63.8419,
                                24.8451
                            ],
                            [
                                -65.3034,
                                23.2507
                            ],
                            [
                                -67.2963,
                                22.8521
                            ],
                            [
                                -68.7578,
                                24.9779
                            ],
                            [
                                -70.0864,
                                26.0408
                            ],
                            [
                                -71.6808,
                                25.2436
                            ],
                            [
                                -72.0793,
                                24.9779
                            ],
                            [
                                -72.6108,
                                24.7122
                            ]
                        ]
                    ]
                },
                "type": "Feature",
                "properties": {
                    "obj_child_num": 0,
                    "obj_revision": 3,
                    "obj_id": 147346,
                    "obj_version": "",
                    "gain": 0,
                    "obj_name": "SES-14_NA09_WDB",
                    "obj_parentid": 46078
                }
            },
            {
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -72.6108,
                                24.7122
                            ],
                            [
                                -74.8694,
                                19.6635
                            ],
                            [
                                -76.4638,
                                17.4048
                            ],
                            [
                                -77.6595,
                                16.6077
                            ],
                            [
                                -82.4425,
                                17.9363
                            ],
                            [
                                -88.9527,
                                21.6564
                            ],
                            [
                                -95.0643,
                                25.1108
                            ],
                            [
                                -99.9802,
                                27.768
                            ],
                            [
                                -106.225,
                                32.551
                            ],
                            [
                                -112.336,
                                36.5368
                            ],
                            [
                                -117.784,
                                39.5926
                            ],
                            [
                                -121.769,
                                41.3199
                            ],
                            [
                                -124.958,
                                41.4527
                            ],
                            [
                                -124.958,
                                42.2499
                            ],
                            [
                                -124.559,
                                46.2357
                            ],
                            [
                                -123.364,
                                49.823
                            ],
                            [
                                -118.315,
                                49.5572
                            ],
                            [
                                -110.078,
                                49.0258
                            ],
                            [
                                -105.162,
                                48.7601
                            ],
                            [
                                -101.176,
                                50.8859
                            ],
                            [
                                -98.6516,
                                52.7459
                            ],
                            [
                                -95.9944,
                                54.3403
                            ],
                            [
                                -96.2601,
                                55.6689
                            ],
                            [
                                -97.1901,
                                57.3961
                            ],
                            [
                                -100.777,
                                58.8575
                            ],
                            [
                                -106.756,
                                61.5148
                            ],
                            [
                                -113.266,
                                62.7105
                            ],
                            [
                                -116.986,
                                62.9762
                            ],
                            [
                                -114.861,
                                65.8992
                            ],
                            [
                                -109.679,
                                70.6822
                            ],
                            [
                                -102.239,
                                74.1366
                            ],
                            [
                                -95.3301,
                                76.2624
                            ],
                            [
                                -89.7499,
                                77.7238
                            ],
                            [
                                -87.8898,
                                78.2553
                            ],
                            [
                                -80.981,
                                76.2624
                            ],
                            [
                                -74.8694,
                                74.1366
                            ],
                            [
                                -70.485,
                                72.1437
                            ],
                            [
                                -63.9748,
                                67.6264
                            ],
                            [
                                -60.5204,
                                64.0391
                            ],
                            [
                                -58.7932,
                                60.4519
                            ],
                            [
                                -59.0589,
                                59.2561
                            ],
                            [
                                -60.3875,
                                57.1303
                            ],
                            [
                                -62.9119,
                                55.2703
                            ],
                            [
                                -63.1776,
                                54.3403
                            ],
                            [
                                -59.8561,
                                49.6901
                            ],
                            [
                                -57.7303,
                                44.7742
                            ],
                            [
                                -56.8003,
                                42.2499
                            ],
                            [
                                -56.8003,
                                40.1241
                            ],
                            [
                                -58.1289,
                                38.264
                            ],
                            [
                                -60.7861,
                                38.5298
                            ],
                            [
                                -61.7162,
                                38.1312
                            ],
                            [
                                -63.0448,
                                32.9496
                            ],
                            [
                                -63.5762,
                                27.9009
                            ],
                            [
                                -63.8419,
                                24.8451
                            ],
                            [
                                -65.3034,
                                23.2507
                            ],
                            [
                                -67.2963,
                                22.8521
                            ],
                            [
                                -68.7578,
                                24.9779
                            ],
                            [
                                -70.0864,
                                26.0408
                            ],
                            [
                                -71.6808,
                                25.2436
                            ],
                            [
                                -72.0793,
                                24.9779
                            ],
                            [
                                -72.6108,
                                24.7122
                            ]
                        ]
                    ]
                },
                "type": "Feature",
                "properties": {
                    "obj_child_num": 0,
                    "obj_revision": 3,
                    "obj_id": 147346,
                    "obj_version": "",
                    "gain": 4500,
                    "obj_name": "SES-14_NA09_WDB",
                    "obj_parentid": 46078
                }
            },
            {
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -178.989,
                                65.5006
                            ],
                            [
                                -172.08,
                                69.6193
                            ],
                            [
                                -163.444,
                                72.808
                            ],
                            [
                                -151.354,
                                75.9966
                            ],
                            [
                                -146.969,
                                75.9966
                            ],
                            [
                                -140.326,
                                76.5281
                            ],
                            [
                                -135.41,
                                73.8709
                            ],
                            [
                                -131.956,
                                70.5493
                            ],
                            [
                                -121.725,
                                68.6893
                            ],
                            [
                                -112.425,
                                70.5493
                            ],
                            [
                                -101.929,
                                69.7522
                            ],
                            [
                                -93.1604,
                                66.8292
                            ],
                            [
                                -85.3215,
                                63.9063
                            ],
                            [
                                -76.287,
                                58.9904
                            ],
                            [
                                -69.1125,
                                53.8088
                            ],
                            [
                                -66.3224,
                                50.6201
                            ],
                            [
                                -64.1966,
                                46.5014
                            ],
                            [
                                -63.2666,
                                41.9842
                            ],
                            [
                                -63.1337,
                                38.7955
                            ],
                            [
                                -61.008,
                                35.6068
                            ],
                            [
                                -61.2737,
                                34.6768
                            ],
                            [
                                -62.7352,
                                34.1453
                            ],
                            [
                                -63.5323,
                                33.3482
                            ],
                            [
                                -60.6094,
                                30.9567
                            ],
                            [
                                -60.4765,
                                30.1595
                            ],
                            [
                                -61.2737,
                                28.1666
                            ],
                            [
                                -63.1337,
                                25.3765
                            ],
                            [
                                -60.3437,
                                19.132
                            ],
                            [
                                -59.4136,
                                13.0204
                            ],
                            [
                                -61.008,
                                9.9646
                            ],
                            [
                                -68.3153,
                                9.8317
                            ],
                            [
                                -74.4269,
                                8.1045
                            ],
                            [
                                -80.6714,
                                5.8459
                            ],
                            [
                                -84.923,
                                6.1116
                            ],
                            [
                                -91.0346,
                                11.6918
                            ],
                            [
                                -101.531,
                                15.279
                            ],
                            [
                                -108.174,
                                18.9992
                            ],
                            [
                                -112.425,
                                22.5864
                            ],
                            [
                                -120.928,
                                25.6422
                            ],
                            [
                                -130.893,
                                24.3136
                            ],
                            [
                                -146.172,
                                20.8592
                            ],
                            [
                                -151.619,
                                15.4119
                            ],
                            [
                                -157.2,
                                12.8875
                            ],
                            [
                                -160.92,
                                14.8805
                            ],
                            [
                                -165.038,
                                22.055
                            ],
                            [
                                -165.171,
                                27.9009
                            ],
                            [
                                -160.787,
                                29.8938
                            ],
                            [
                                -157.598,
                                33.8796
                            ],
                            [
                                -149.095,
                                40.5227
                            ],
                            [
                                -146.438,
                                41.7184
                            ],
                            [
                                -145.508,
                                42.3827
                            ],
                            [
                                -142.983,
                                45.8371
                            ],
                            [
                                -144.046,
                                47.9629
                            ],
                            [
                                -145.242,
                                49.6901
                            ],
                            [
                                -148.696,
                                50.0887
                            ],
                            [
                                -150.424,
                                46.6343
                            ],
                            [
                                -152.549,
                                45.97
                            ],
                            [
                                -155.605,
                                47.0329
                            ],
                            [
                                -163.311,
                                52.4802
                            ],
                            [
                                -171.416,
                                52.7459
                            ],
                            [
                                -174.604,
                                59.7876
                            ],
                            [
                                -179.254,
                                62.3119
                            ],
                            [
                                -178.989,
                                65.2349
                            ],
                            [
                                -178.989,
                                65.5006
                            ]
                        ]
                    ]
                },
                "type": "Feature",
                "properties": {
                    "obj_child_num": 0,
                    "obj_revision": 5,
                    "obj_id": 46110,
                    "obj_version": "",
                    "gain": 0,
                    "obj_name": "SES-15_Beam_BC_SMN",
                    "obj_parentid": 8411
                }
            },
            {
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -178.989,
                                65.5006
                            ],
                            [
                                -172.08,
                                69.6193
                            ],
                            [
                                -163.444,
                                72.808
                            ],
                            [
                                -151.354,
                                75.9966
                            ],
                            [
                                -146.969,
                                75.9966
                            ],
                            [
                                -140.326,
                                76.5281
                            ],
                            [
                                -135.41,
                                73.8709
                            ],
                            [
                                -131.956,
                                70.5493
                            ],
                            [
                                -121.725,
                                68.6893
                            ],
                            [
                                -112.425,
                                70.5493
                            ],
                            [
                                -101.929,
                                69.7522
                            ],
                            [
                                -93.1604,
                                66.8292
                            ],
                            [
                                -85.3215,
                                63.9063
                            ],
                            [
                                -76.287,
                                58.9904
                            ],
                            [
                                -69.1125,
                                53.8088
                            ],
                            [
                                -66.3224,
                                50.6201
                            ],
                            [
                                -64.1966,
                                46.5014
                            ],
                            [
                                -63.2666,
                                41.9842
                            ],
                            [
                                -63.1337,
                                38.7955
                            ],
                            [
                                -61.008,
                                35.6068
                            ],
                            [
                                -61.2737,
                                34.6768
                            ],
                            [
                                -62.7352,
                                34.1453
                            ],
                            [
                                -63.5323,
                                33.3482
                            ],
                            [
                                -60.6094,
                                30.9567
                            ],
                            [
                                -60.4765,
                                30.1595
                            ],
                            [
                                -61.2737,
                                28.1666
                            ],
                            [
                                -63.1337,
                                25.3765
                            ],
                            [
                                -60.3437,
                                19.132
                            ],
                            [
                                -59.4136,
                                13.0204
                            ],
                            [
                                -61.008,
                                9.9646
                            ],
                            [
                                -68.3153,
                                9.8317
                            ],
                            [
                                -74.4269,
                                8.1045
                            ],
                            [
                                -80.6714,
                                5.8459
                            ],
                            [
                                -84.923,
                                6.1116
                            ],
                            [
                                -91.0346,
                                11.6918
                            ],
                            [
                                -101.531,
                                15.279
                            ],
                            [
                                -108.174,
                                18.9992
                            ],
                            [
                                -112.425,
                                22.5864
                            ],
                            [
                                -120.928,
                                25.6422
                            ],
                            [
                                -130.893,
                                24.3136
                            ],
                            [
                                -146.172,
                                20.8592
                            ],
                            [
                                -151.619,
                                15.4119
                            ],
                            [
                                -157.2,
                                12.8875
                            ],
                            [
                                -160.92,
                                14.8805
                            ],
                            [
                                -165.038,
                                22.055
                            ],
                            [
                                -165.171,
                                27.9009
                            ],
                            [
                                -160.787,
                                29.8938
                            ],
                            [
                                -157.598,
                                33.8796
                            ],
                            [
                                -149.095,
                                40.5227
                            ],
                            [
                                -146.438,
                                41.7184
                            ],
                            [
                                -145.508,
                                42.3827
                            ],
                            [
                                -142.983,
                                45.8371
                            ],
                            [
                                -144.046,
                                47.9629
                            ],
                            [
                                -145.242,
                                49.6901
                            ],
                            [
                                -148.696,
                                50.0887
                            ],
                            [
                                -150.424,
                                46.6343
                            ],
                            [
                                -152.549,
                                45.97
                            ],
                            [
                                -155.605,
                                47.0329
                            ],
                            [
                                -163.311,
                                52.4802
                            ],
                            [
                                -171.416,
                                52.7459
                            ],
                            [
                                -174.604,
                                59.7876
                            ],
                            [
                                -179.254,
                                62.3119
                            ],
                            [
                                -178.989,
                                65.2349
                            ],
                            [
                                -178.989,
                                65.5006
                            ]
                        ]
                    ]
                },
                "type": "Feature",
                "properties": {
                    "obj_child_num": 0,
                    "obj_revision": 5,
                    "obj_id": 46110,
                    "obj_version": "",
                    "gain": 4500,
                    "obj_name": "SES-15_Beam_BC_SMN",
                    "obj_parentid": 8411
                }
            },
            {
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -161.464,
                                61.819
                            ],
                            [
                                -159.201,
                                63.7023
                            ],
                            [
                                -155.899,
                                66.0564
                            ],
                            [
                                -151.749,
                                68.4105
                            ],
                            [
                                -149.107,
                                67.6572
                            ],
                            [
                                -143.165,
                                66.2448
                            ],
                            [
                                -133.732,
                                63.6082
                            ],
                            [
                                -129.016,
                                61.6307
                            ],
                            [
                                -126.563,
                                59.7474
                            ],
                            [
                                -124.771,
                                57.1108
                            ],
                            [
                                -123.828,
                                55.6983
                            ],
                            [
                                -119.866,
                                53.7209
                            ],
                            [
                                -114.961,
                                52.4967
                            ],
                            [
                                -110.15,
                                52.1201
                            ],
                            [
                                -107.603,
                                52.5909
                            ],
                            [
                                -106.471,
                                53.7209
                            ],
                            [
                                -106.943,
                                55.9808
                            ],
                            [
                                -107.131,
                                58.6174
                            ],
                            [
                                -106.282,
                                58.6174
                            ],
                            [
                                -103.736,
                                57.77
                            ],
                            [
                                -100.906,
                                55.7925
                            ],
                            [
                                -99.8681,
                                54.4742
                            ],
                            [
                                -98.4532,
                                53.9092
                            ],
                            [
                                -94.1141,
                                53.6267
                            ],
                            [
                                -92.1332,
                                53.4384
                            ],
                            [
                                -87.6997,
                                51.5551
                            ],
                            [
                                -84.3982,
                                50.6134
                            ],
                            [
                                -80.9081,
                                50.5193
                            ],
                            [
                                -77.4179,
                                49.2951
                            ],
                            [
                                -76.286,
                                49.1068
                            ],
                            [
                                -69.4943,
                                49.4835
                            ],
                            [
                                -66.0985,
                                49.766
                            ],
                            [
                                -63.5517,
                                50.331
                            ],
                            [
                                -61.7594,
                                50.5193
                            ],
                            [
                                -60.6275,
                                49.6718
                            ],
                            [
                                -60.5331,
                                47.506
                            ],
                            [
                                -61.5707,
                                44.5869
                            ],
                            [
                                -65.9099,
                                39.9729
                            ],
                            [
                                -71.7582,
                                35.1705
                            ],
                            [
                                -74.1164,
                                31.8747
                            ],
                            [
                                -76.003,
                                26.6957
                            ],
                            [
                                -76.286,
                                24.5299
                            ],
                            [
                                -77.6066,
                                23.6824
                            ],
                            [
                                -79.4932,
                                22.7407
                            ],
                            [
                                -82.1343,
                                22.6466
                            ],
                            [
                                -85.7188,
                                23.8707
                            ],
                            [
                                -86.5678,
                                24.3415
                            ],
                            [
                                -87.794,
                                25.8482
                            ],
                            [
                                -89.5863,
                                26.319
                            ],
                            [
                                -92.0388,
                                25.8482
                            ],
                            [
                                -93.5481,
                                25.2832
                            ],
                            [
                                -95.4347,
                                23.2116
                            ],
                            [
                                -96.0949,
                                23.3057
                            ],
                            [
                                -97.8872,
                                23.8707
                            ],
                            [
                                -103.453,
                                27.1665
                            ],
                            [
                                -105.15,
                                28.4848
                            ],
                            [
                                -109.018,
                                28.4848
                            ],
                            [
                                -113.074,
                                29.6148
                            ],
                            [
                                -114.3,
                                29.1439
                            ],
                            [
                                -115.244,
                                29.2381
                            ],
                            [
                                -117.696,
                                30.0856
                            ],
                            [
                                -121.186,
                                31.7805
                            ],
                            [
                                -125.431,
                                35.453
                            ],
                            [
                                -128.261,
                                36.3946
                            ],
                            [
                                -130.053,
                                36.8654
                            ],
                            [
                                -131.751,
                                38.0896
                            ],
                            [
                                -133.26,
                                40.1612
                            ],
                            [
                                -132.789,
                                40.9145
                            ],
                            [
                                -130.902,
                                41.5737
                            ],
                            [
                                -130.148,
                                42.1386
                            ],
                            [
                                -130.053,
                                44.4928
                            ],
                            [
                                -130.808,
                                46.941
                            ],
                            [
                                -132.034,
                                48.4477
                            ],
                            [
                                -134.675,
                                49.8601
                            ],
                            [
                                -138.731,
                                51.3668
                            ],
                            [
                                -142.882,
                                54.0034
                            ],
                            [
                                -147.598,
                                56.4517
                            ],
                            [
                                -152.032,
                                58.4291
                            ],
                            [
                                -161.37,
                                61.819
                            ],
                            [
                                -161.464,
                                61.819
                            ]
                        ]
                    ]
                },
                "type": "Feature",
                "properties": {
                    "obj_child_num": 0,
                    "obj_revision": 8,
                    "obj_id": 7587,
                    "obj_version": "",
                    "gain": 0,
                    "obj_name": "SES-1_NA",
                    "obj_parentid": 2109
                }
            },
            {
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -161.464,
                                61.819
                            ],
                            [
                                -159.201,
                                63.7023
                            ],
                            [
                                -155.899,
                                66.0564
                            ],
                            [
                                -151.749,
                                68.4105
                            ],
                            [
                                -149.107,
                                67.6572
                            ],
                            [
                                -143.165,
                                66.2448
                            ],
                            [
                                -133.732,
                                63.6082
                            ],
                            [
                                -129.016,
                                61.6307
                            ],
                            [
                                -126.563,
                                59.7474
                            ],
                            [
                                -124.771,
                                57.1108
                            ],
                            [
                                -123.828,
                                55.6983
                            ],
                            [
                                -119.866,
                                53.7209
                            ],
                            [
                                -114.961,
                                52.4967
                            ],
                            [
                                -110.15,
                                52.1201
                            ],
                            [
                                -107.603,
                                52.5909
                            ],
                            [
                                -106.471,
                                53.7209
                            ],
                            [
                                -106.943,
                                55.9808
                            ],
                            [
                                -107.131,
                                58.6174
                            ],
                            [
                                -106.282,
                                58.6174
                            ],
                            [
                                -103.736,
                                57.77
                            ],
                            [
                                -100.906,
                                55.7925
                            ],
                            [
                                -99.8681,
                                54.4742
                            ],
                            [
                                -98.4532,
                                53.9092
                            ],
                            [
                                -94.1141,
                                53.6267
                            ],
                            [
                                -92.1332,
                                53.4384
                            ],
                            [
                                -87.6997,
                                51.5551
                            ],
                            [
                                -84.3982,
                                50.6134
                            ],
                            [
                                -80.9081,
                                50.5193
                            ],
                            [
                                -77.4179,
                                49.2951
                            ],
                            [
                                -76.286,
                                49.1068
                            ],
                            [
                                -69.4943,
                                49.4835
                            ],
                            [
                                -66.0985,
                                49.766
                            ],
                            [
                                -63.5517,
                                50.331
                            ],
                            [
                                -61.7594,
                                50.5193
                            ],
                            [
                                -60.6275,
                                49.6718
                            ],
                            [
                                -60.5331,
                                47.506
                            ],
                            [
                                -61.5707,
                                44.5869
                            ],
                            [
                                -65.9099,
                                39.9729
                            ],
                            [
                                -71.7582,
                                35.1705
                            ],
                            [
                                -74.1164,
                                31.8747
                            ],
                            [
                                -76.003,
                                26.6957
                            ],
                            [
                                -76.286,
                                24.5299
                            ],
                            [
                                -77.6066,
                                23.6824
                            ],
                            [
                                -79.4932,
                                22.7407
                            ],
                            [
                                -82.1343,
                                22.6466
                            ],
                            [
                                -85.7188,
                                23.8707
                            ],
                            [
                                -86.5678,
                                24.3415
                            ],
                            [
                                -87.794,
                                25.8482
                            ],
                            [
                                -89.5863,
                                26.319
                            ],
                            [
                                -92.0388,
                                25.8482
                            ],
                            [
                                -93.5481,
                                25.2832
                            ],
                            [
                                -95.4347,
                                23.2116
                            ],
                            [
                                -96.0949,
                                23.3057
                            ],
                            [
                                -97.8872,
                                23.8707
                            ],
                            [
                                -103.453,
                                27.1665
                            ],
                            [
                                -105.15,
                                28.4848
                            ],
                            [
                                -109.018,
                                28.4848
                            ],
                            [
                                -113.074,
                                29.6148
                            ],
                            [
                                -114.3,
                                29.1439
                            ],
                            [
                                -115.244,
                                29.2381
                            ],
                            [
                                -117.696,
                                30.0856
                            ],
                            [
                                -121.186,
                                31.7805
                            ],
                            [
                                -125.431,
                                35.453
                            ],
                            [
                                -128.261,
                                36.3946
                            ],
                            [
                                -130.053,
                                36.8654
                            ],
                            [
                                -131.751,
                                38.0896
                            ],
                            [
                                -133.26,
                                40.1612
                            ],
                            [
                                -132.789,
                                40.9145
                            ],
                            [
                                -130.902,
                                41.5737
                            ],
                            [
                                -130.148,
                                42.1386
                            ],
                            [
                                -130.053,
                                44.4928
                            ],
                            [
                                -130.808,
                                46.941
                            ],
                            [
                                -132.034,
                                48.4477
                            ],
                            [
                                -134.675,
                                49.8601
                            ],
                            [
                                -138.731,
                                51.3668
                            ],
                            [
                                -142.882,
                                54.0034
                            ],
                            [
                                -147.598,
                                56.4517
                            ],
                            [
                                -152.032,
                                58.4291
                            ],
                            [
                                -161.37,
                                61.819
                            ],
                            [
                                -161.464,
                                61.819
                            ]
                        ]
                    ]
                },
                "type": "Feature",
                "properties": {
                    "obj_child_num": 0,
                    "obj_revision": 8,
                    "obj_id": 7587,
                    "obj_version": "",
                    "gain": 4500,
                    "obj_name": "SES-1_NA",
                    "obj_parentid": 2109
                }
            },
            {
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                59.123,
                                -5.094
                            ],
                            [
                                54.111,
                                -5.211
                            ],
                            [
                                49.535,
                                -5.385
                            ],
                            [
                                46.86,
                                -5.477
                            ],
                            [
                                46.501,
                                -5.492
                            ],
                            [
                                44.062,
                                -5.56
                            ],
                            [
                                41.962,
                                -5.595
                            ],
                            [
                                40.086,
                                -5.587
                            ],
                            [
                                38.367,
                                -5.513
                            ],
                            [
                                36.766,
                                -5.361
                            ],
                            [
                                36.552,
                                -5.329
                            ],
                            [
                                35.258,
                                -5.115
                            ],
                            [
                                33.832,
                                -4.802
                            ],
                            [
                                33.427,
                                -4.697
                            ],
                            [
                                32.477,
                                -4.41
                            ],
                            [
                                31.499,
                                -4.089
                            ],
                            [
                                31.189,
                                -3.967
                            ],
                            [
                                29.957,
                                -3.49
                            ],
                            [
                                28.789,
                                -2.982
                            ],
                            [
                                28.593,
                                -2.898
                            ],
                            [
                                27.666,
                                -2.441
                            ],
                            [
                                27.408,
                                -2.311
                            ],
                            [
                                26.585,
                                -1.792
                            ],
                            [
                                26.492,
                                -1.729
                            ],
                            [
                                25.96,
                                -1.151
                            ],
                            [
                                25.803,
                                -0.575
                            ],
                            [
                                26.023,
                                0
                            ],
                            [
                                26.454,
                                0.576
                            ],
                            [
                                26.55,
                                0.705
                            ],
                            [
                                26.839,
                                1.154
                            ],
                            [
                                27.025,
                                1.732
                            ],
                            [
                                27.007,
                                2.309
                            ],
                            [
                                26.814,
                                2.886
                            ],
                            [
                                26.671,
                                3.126
                            ],
                            [
                                26.467,
                                3.461
                            ],
                            [
                                26.001,
                                4.034
                            ],
                            [
                                25.766,
                                4.296
                            ],
                            [
                                25.484,
                                4.606
                            ],
                            [
                                24.983,
                                5.178
                            ],
                            [
                                24.894,
                                5.294
                            ],
                            [
                                24.498,
                                5.749
                            ],
                            [
                                24.058,
                                6.25
                            ],
                            [
                                23.979,
                                6.319
                            ],
                            [
                                23.311,
                                6.887
                            ],
                            [
                                23.22,
                                6.962
                            ],
                            [
                                22.372,
                                7.449
                            ],
                            [
                                21.556,
                                7.994
                            ],
                            [
                                21.536,
                                8.012
                            ],
                            [
                                21.228,
                                8.584
                            ],
                            [
                                21.169,
                                9.162
                            ],
                            [
                                21.208,
                                9.743
                            ],
                            [
                                21.243,
                                10.325
                            ],
                            [
                                21.228,
                                10.839
                            ],
                            [
                                21.231,
                                10.908
                            ],
                            [
                                21.202,
                                11.492
                            ],
                            [
                                21.14,
                                12.076
                            ],
                            [
                                21.076,
                                12.663
                            ],
                            [
                                21.044,
                                13.252
                            ],
                            [
                                21.055,
                                13.646
                            ],
                            [
                                21.065,
                                13.844
                            ],
                            [
                                21.132,
                                14.44
                            ],
                            [
                                21.206,
                                15.039
                            ],
                            [
                                21.257,
                                15.639
                            ],
                            [
                                21.25,
                                16.24
                            ],
                            [
                                21.171,
                                16.817
                            ],
                            [
                                21.167,
                                16.84
                            ],
                            [
                                20.94,
                                17.438
                            ],
                            [
                                20.677,
                                17.963
                            ],
                            [
                                20.623,
                                18.034
                            ],
                            [
                                20.135,
                                18.625
                            ],
                            [
                                20.059,
                                18.726
                            ],
                            [
                                19.358,
                                19.207
                            ],
                            [
                                19.349,
                                19.214
                            ],
                            [
                                18.58,
                                19.518
                            ],
                            [
                                17.772,
                                19.687
                            ],
                            [
                                17.518,
                                19.746
                            ],
                            [
                                16.998,
                                19.909
                            ],
                            [
                                16.258,
                                20.198
                            ],
                            [
                                16.063,
                                20.301
                            ],
                            [
                                15.603,
                                20.696
                            ],
                            [
                                15.386,
                                20.892
                            ],
                            [
                                15.09,
                                21.5
                            ],
                            [
                                15.51,
                                22.144
                            ],
                            [
                                16.23,
                                22.347
                            ],
                            [
                                17.161,
                                22.523
                            ],
                            [
                                18.184,
                                22.857
                            ],
                            [
                                18.249,
                                22.901
                            ],
                            [
                                18.652,
                                23.558
                            ],
                            [
                                18.58,
                                23.729
                            ],
                            [
                                18.268,
                                24.18
                            ],
                            [
                                17.955,
                                24.317
                            ],
                            [
                                17.125,
                                24.442
                            ],
                            [
                                16.211,
                                24.346
                            ],
                            [
                                15.226,
                                24.038
                            ],
                            [
                                15.206,
                                24.03
                            ],
                            [
                                14.365,
                                23.354
                            ],
                            [
                                13.975,
                                22.978
                            ],
                            [
                                13.56,
                                22.686
                            ],
                            [
                                12.992,
                                22.501
                            ],
                            [
                                12.117,
                                22.262
                            ],
                            [
                                11.262,
                                22.031
                            ],
                            [
                                11.038,
                                21.955
                            ],
                            [
                                10.417,
                                21.779
                            ],
                            [
                                9.564,
                                21.45
                            ],
                            [
                                9.201,
                                21.264
                            ],
                            [
                                8.696,
                                21.002
                            ],
                            [
                                8.099,
                                20.611
                            ],
                            [
                                7.807,
                                20.401
                            ],
                            [
                                7.271,
                                19.973
                            ],
                            [
                                6.909,
                                19.666
                            ],
                            [
                                6.51,
                                19.343
                            ],
                            [
                                6.053,
                                19
                            ],
                            [
                                5.579,
                                18.713
                            ],
                            [
                                5.266,
                                18.555
                            ],
                            [
                                4.533,
                                18.308
                            ],
                            [
                                3.836,
                                18.201
                            ],
                            [
                                3.172,
                                18.227
                            ],
                            [
                                2.538,
                                18.388
                            ],
                            [
                                2.081,
                                18.615
                            ],
                            [
                                1.934,
                                18.695
                            ],
                            [
                                1.36,
                                19.151
                            ],
                            [
                                1.318,
                                19.192
                            ],
                            [
                                0.804,
                                19.695
                            ],
                            [
                                0.726,
                                19.776
                            ],
                            [
                                0.249,
                                20.24
                            ],
                            [
                                0.118,
                                20.363
                            ],
                            [
                                -0.313,
                                20.734
                            ],
                            [
                                -0.596,
                                20.95
                            ],
                            [
                                -0.886,
                                21.162
                            ],
                            [
                                -1.467,
                                21.539
                            ],
                            [
                                -2.051,
                                21.891
                            ],
                            [
                                -2.467,
                                22.123
                            ],
                            [
                                -2.638,
                                22.219
                            ],
                            [
                                -3.224,
                                22.549
                            ],
                            [
                                -3.503,
                                22.713
                            ],
                            [
                                -3.807,
                                22.898
                            ],
                            [
                                -4.385,
                                23.272
                            ],
                            [
                                -4.44,
                                23.309
                            ],
                            [
                                -4.959,
                                23.665
                            ],
                            [
                                -5.304,
                                23.911
                            ],
                            [
                                -5.532,
                                24.077
                            ],
                            [
                                -6.105,
                                24.488
                            ],
                            [
                                -6.149,
                                24.519
                            ],
                            [
                                -6.683,
                                24.87
                            ],
                            [
                                -7.089,
                                25.129
                            ],
                            [
                                -7.265,
                                25.229
                            ],
                            [
                                -7.854,
                                25.555
                            ],
                            [
                                -8.172,
                                25.742
                            ],
                            [
                                -8.443,
                                25.879
                            ],
                            [
                                -9.032,
                                26.207
                            ],
                            [
                                -9.282,
                                26.36
                            ],
                            [
                                -9.622,
                                26.547
                            ],
                            [
                                -10.21,
                                26.909
                            ],
                            [
                                -10.321,
                                26.986
                            ],
                            [
                                -10.795,
                                27.31
                            ],
                            [
                                -11.194,
                                27.621
                            ],
                            [
                                -11.376,
                                27.775
                            ],
                            [
                                -11.886,
                                28.266
                            ],
                            [
                                -11.949,
                                28.338
                            ],
                            [
                                -12.399,
                                28.921
                            ],
                            [
                                -12.504,
                                29.105
                            ],
                            [
                                -12.754,
                                29.585
                            ],
                            [
                                -12.977,
                                30.258
                            ],
                            [
                                -13.007,
                                30.422
                            ],
                            [
                                -13.098,
                                30.941
                            ],
                            [
                                -13.123,
                                31.632
                            ],
                            [
                                -13.061,
                                32.333
                            ],
                            [
                                -12.924,
                                33.044
                            ],
                            [
                                -12.738,
                                33.765
                            ],
                            [
                                -12.537,
                                34.495
                            ],
                            [
                                -12.491,
                                34.688
                            ],
                            [
                                -12.36,
                                35.236
                            ],
                            [
                                -12.237,
                                35.985
                            ],
                            [
                                -12.182,
                                36.745
                            ],
                            [
                                -12.183,
                                36.859
                            ],
                            [
                                -12.186,
                                37.515
                            ],
                            [
                                -12.23,
                                38.296
                            ],
                            [
                                -12.284,
                                39.091
                            ],
                            [
                                -12.315,
                                39.899
                            ],
                            [
                                -12.295,
                                40.724
                            ],
                            [
                                -12.287,
                                40.824
                            ],
                            [
                                -12.217,
                                41.567
                            ],
                            [
                                -12.096,
                                42.427
                            ],
                            [
                                -11.969,
                                43.306
                            ],
                            [
                                -11.891,
                                44.203
                            ],
                            [
                                -11.927,
                                45.119
                            ],
                            [
                                -12.121,
                                46.002
                            ],
                            [
                                -12.137,
                                46.053
                            ],
                            [
                                -12.62,
                                47.005
                            ],
                            [
                                -12.729,
                                47.176
                            ],
                            [
                                -13.413,
                                47.978
                            ],
                            [
                                -13.429,
                                47.999
                            ],
                            [
                                -14.15,
                                48.835
                            ],
                            [
                                -14.276,
                                48.979
                            ],
                            [
                                -14.836,
                                50.024
                            ],
                            [
                                -15.062,
                                51.108
                            ],
                            [
                                -15.023,
                                52.241
                            ],
                            [
                                -14.784,
                                53.424
                            ],
                            [
                                -14.381,
                                54.665
                            ],
                            [
                                -13.831,
                                55.972
                            ],
                            [
                                -13.465,
                                56.71
                            ],
                            [
                                -13.139,
                                57.356
                            ],
                            [
                                -12.301,
                                58.831
                            ],
                            [
                                -11.28,
                                60.421
                            ],
                            [
                                -11.096,
                                60.683
                            ],
                            [
                                -9.983,
                                62.157
                            ],
                            [
                                -8.28,
                                64.091
                            ],
                            [
                                -8.2,
                                64.173
                            ],
                            [
                                -5.824,
                                66.323
                            ],
                            [
                                -4.674,
                                67.217
                            ],
                            [
                                -1.902,
                                69.063
                            ],
                            [
                                -0.107,
                                70.101
                            ],
                            [
                                6.418,
                                73.093
                            ],
                            [
                                6.792,
                                73.24
                            ],
                            [
                                23.196,
                                77.402
                            ],
                            [
                                23.6899,
                                77.5102
                            ],
                            [
                                28.8171,
                                76.1658
                            ],
                            [
                                33.0001,
                                74.729
                            ],
                            [
                                36.4401,
                                73.2235
                            ],
                            [
                                39.2968,
                                71.6662
                            ],
                            [
                                41.6934,
                                70.0693
                            ],
                            [
                                43.724,
                                68.4415
                            ],
                            [
                                45.4605,
                                66.7895
                            ],
                            [
                                46.9582,
                                65.1179
                            ],
                            [
                                48.26,
                                63.4306
                            ],
                            [
                                49.3994,
                                61.7303
                            ],
                            [
                                50.4031,
                                60.0193
                            ],
                            [
                                51.2923,
                                58.2993
                            ],
                            [
                                52.0839,
                                56.5717
                            ],
                            [
                                52.792,
                                54.8376
                            ],
                            [
                                53.428,
                                53.098
                            ],
                            [
                                54.0012,
                                51.3537
                            ],
                            [
                                54.5194,
                                49.6051
                            ],
                            [
                                54.9894,
                                47.853
                            ],
                            [
                                55.4166,
                                46.0977
                            ],
                            [
                                55.8058,
                                44.3396
                            ],
                            [
                                56.161,
                                42.579
                            ],
                            [
                                56.4855,
                                40.8163
                            ],
                            [
                                56.7825,
                                39.0515
                            ],
                            [
                                57.0545,
                                37.2851
                            ],
                            [
                                57.3037,
                                35.5171
                            ],
                            [
                                57.532,
                                33.7477
                            ],
                            [
                                57.7412,
                                31.9771
                            ],
                            [
                                57.9327,
                                30.2053
                            ],
                            [
                                58.1079,
                                28.4325
                            ],
                            [
                                58.2679,
                                26.6588
                            ],
                            [
                                58.4138,
                                24.8843
                            ],
                            [
                                58.5463,
                                23.1091
                            ],
                            [
                                58.6663,
                                21.3333
                            ],
                            [
                                58.7745,
                                19.5568
                            ],
                            [
                                58.8716,
                                17.7799
                            ],
                            [
                                58.9579,
                                16.0024
                            ],
                            [
                                59.034,
                                14.2246
                            ],
                            [
                                59.1002,
                                12.4464
                            ],
                            [
                                59.157,
                                10.668
                            ],
                            [
                                59.2045,
                                8.8892
                            ],
                            [
                                59.2431,
                                7.1103
                            ],
                            [
                                59.273,
                                5.3312
                            ],
                            [
                                59.2942,
                                3.552
                            ],
                            [
                                59.3068,
                                1.7727
                            ],
                            [
                                59.311,
                                -0.0066
                            ],
                            [
                                59.3068,
                                -1.786
                            ],
                            [
                                59.294,
                                -3.5652
                            ],
                            [
                                59.2728,
                                -5.3445
                            ],
                            [
                                59.123,
                                -5.094
                            ]
                        ]
                    ]
                },
                "type": "Feature",
                "properties": {
                    "obj_child_num": 0,
                    "obj_revision": 5,
                    "obj_id": 7514,
                    "obj_version": "",
                    "gain": 0,
                    "obj_name": "SES-4_EU01_WMP",
                    "obj_parentid": 1727
                }
            },
            {
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                59.123,
                                -5.094
                            ],
                            [
                                54.111,
                                -5.211
                            ],
                            [
                                49.535,
                                -5.385
                            ],
                            [
                                46.86,
                                -5.477
                            ],
                            [
                                46.501,
                                -5.492
                            ],
                            [
                                44.062,
                                -5.56
                            ],
                            [
                                41.962,
                                -5.595
                            ],
                            [
                                40.086,
                                -5.587
                            ],
                            [
                                38.367,
                                -5.513
                            ],
                            [
                                36.766,
                                -5.361
                            ],
                            [
                                36.552,
                                -5.329
                            ],
                            [
                                35.258,
                                -5.115
                            ],
                            [
                                33.832,
                                -4.802
                            ],
                            [
                                33.427,
                                -4.697
                            ],
                            [
                                32.477,
                                -4.41
                            ],
                            [
                                31.499,
                                -4.089
                            ],
                            [
                                31.189,
                                -3.967
                            ],
                            [
                                29.957,
                                -3.49
                            ],
                            [
                                28.789,
                                -2.982
                            ],
                            [
                                28.593,
                                -2.898
                            ],
                            [
                                27.666,
                                -2.441
                            ],
                            [
                                27.408,
                                -2.311
                            ],
                            [
                                26.585,
                                -1.792
                            ],
                            [
                                26.492,
                                -1.729
                            ],
                            [
                                25.96,
                                -1.151
                            ],
                            [
                                25.803,
                                -0.575
                            ],
                            [
                                26.023,
                                0
                            ],
                            [
                                26.454,
                                0.576
                            ],
                            [
                                26.55,
                                0.705
                            ],
                            [
                                26.839,
                                1.154
                            ],
                            [
                                27.025,
                                1.732
                            ],
                            [
                                27.007,
                                2.309
                            ],
                            [
                                26.814,
                                2.886
                            ],
                            [
                                26.671,
                                3.126
                            ],
                            [
                                26.467,
                                3.461
                            ],
                            [
                                26.001,
                                4.034
                            ],
                            [
                                25.766,
                                4.296
                            ],
                            [
                                25.484,
                                4.606
                            ],
                            [
                                24.983,
                                5.178
                            ],
                            [
                                24.894,
                                5.294
                            ],
                            [
                                24.498,
                                5.749
                            ],
                            [
                                24.058,
                                6.25
                            ],
                            [
                                23.979,
                                6.319
                            ],
                            [
                                23.311,
                                6.887
                            ],
                            [
                                23.22,
                                6.962
                            ],
                            [
                                22.372,
                                7.449
                            ],
                            [
                                21.556,
                                7.994
                            ],
                            [
                                21.536,
                                8.012
                            ],
                            [
                                21.228,
                                8.584
                            ],
                            [
                                21.169,
                                9.162
                            ],
                            [
                                21.208,
                                9.743
                            ],
                            [
                                21.243,
                                10.325
                            ],
                            [
                                21.228,
                                10.839
                            ],
                            [
                                21.231,
                                10.908
                            ],
                            [
                                21.202,
                                11.492
                            ],
                            [
                                21.14,
                                12.076
                            ],
                            [
                                21.076,
                                12.663
                            ],
                            [
                                21.044,
                                13.252
                            ],
                            [
                                21.055,
                                13.646
                            ],
                            [
                                21.065,
                                13.844
                            ],
                            [
                                21.132,
                                14.44
                            ],
                            [
                                21.206,
                                15.039
                            ],
                            [
                                21.257,
                                15.639
                            ],
                            [
                                21.25,
                                16.24
                            ],
                            [
                                21.171,
                                16.817
                            ],
                            [
                                21.167,
                                16.84
                            ],
                            [
                                20.94,
                                17.438
                            ],
                            [
                                20.677,
                                17.963
                            ],
                            [
                                20.623,
                                18.034
                            ],
                            [
                                20.135,
                                18.625
                            ],
                            [
                                20.059,
                                18.726
                            ],
                            [
                                19.358,
                                19.207
                            ],
                            [
                                19.349,
                                19.214
                            ],
                            [
                                18.58,
                                19.518
                            ],
                            [
                                17.772,
                                19.687
                            ],
                            [
                                17.518,
                                19.746
                            ],
                            [
                                16.998,
                                19.909
                            ],
                            [
                                16.258,
                                20.198
                            ],
                            [
                                16.063,
                                20.301
                            ],
                            [
                                15.603,
                                20.696
                            ],
                            [
                                15.386,
                                20.892
                            ],
                            [
                                15.09,
                                21.5
                            ],
                            [
                                15.51,
                                22.144
                            ],
                            [
                                16.23,
                                22.347
                            ],
                            [
                                17.161,
                                22.523
                            ],
                            [
                                18.184,
                                22.857
                            ],
                            [
                                18.249,
                                22.901
                            ],
                            [
                                18.652,
                                23.558
                            ],
                            [
                                18.58,
                                23.729
                            ],
                            [
                                18.268,
                                24.18
                            ],
                            [
                                17.955,
                                24.317
                            ],
                            [
                                17.125,
                                24.442
                            ],
                            [
                                16.211,
                                24.346
                            ],
                            [
                                15.226,
                                24.038
                            ],
                            [
                                15.206,
                                24.03
                            ],
                            [
                                14.365,
                                23.354
                            ],
                            [
                                13.975,
                                22.978
                            ],
                            [
                                13.56,
                                22.686
                            ],
                            [
                                12.992,
                                22.501
                            ],
                            [
                                12.117,
                                22.262
                            ],
                            [
                                11.262,
                                22.031
                            ],
                            [
                                11.038,
                                21.955
                            ],
                            [
                                10.417,
                                21.779
                            ],
                            [
                                9.564,
                                21.45
                            ],
                            [
                                9.201,
                                21.264
                            ],
                            [
                                8.696,
                                21.002
                            ],
                            [
                                8.099,
                                20.611
                            ],
                            [
                                7.807,
                                20.401
                            ],
                            [
                                7.271,
                                19.973
                            ],
                            [
                                6.909,
                                19.666
                            ],
                            [
                                6.51,
                                19.343
                            ],
                            [
                                6.053,
                                19
                            ],
                            [
                                5.579,
                                18.713
                            ],
                            [
                                5.266,
                                18.555
                            ],
                            [
                                4.533,
                                18.308
                            ],
                            [
                                3.836,
                                18.201
                            ],
                            [
                                3.172,
                                18.227
                            ],
                            [
                                2.538,
                                18.388
                            ],
                            [
                                2.081,
                                18.615
                            ],
                            [
                                1.934,
                                18.695
                            ],
                            [
                                1.36,
                                19.151
                            ],
                            [
                                1.318,
                                19.192
                            ],
                            [
                                0.804,
                                19.695
                            ],
                            [
                                0.726,
                                19.776
                            ],
                            [
                                0.249,
                                20.24
                            ],
                            [
                                0.118,
                                20.363
                            ],
                            [
                                -0.313,
                                20.734
                            ],
                            [
                                -0.596,
                                20.95
                            ],
                            [
                                -0.886,
                                21.162
                            ],
                            [
                                -1.467,
                                21.539
                            ],
                            [
                                -2.051,
                                21.891
                            ],
                            [
                                -2.467,
                                22.123
                            ],
                            [
                                -2.638,
                                22.219
                            ],
                            [
                                -3.224,
                                22.549
                            ],
                            [
                                -3.503,
                                22.713
                            ],
                            [
                                -3.807,
                                22.898
                            ],
                            [
                                -4.385,
                                23.272
                            ],
                            [
                                -4.44,
                                23.309
                            ],
                            [
                                -4.959,
                                23.665
                            ],
                            [
                                -5.304,
                                23.911
                            ],
                            [
                                -5.532,
                                24.077
                            ],
                            [
                                -6.105,
                                24.488
                            ],
                            [
                                -6.149,
                                24.519
                            ],
                            [
                                -6.683,
                                24.87
                            ],
                            [
                                -7.089,
                                25.129
                            ],
                            [
                                -7.265,
                                25.229
                            ],
                            [
                                -7.854,
                                25.555
                            ],
                            [
                                -8.172,
                                25.742
                            ],
                            [
                                -8.443,
                                25.879
                            ],
                            [
                                -9.032,
                                26.207
                            ],
                            [
                                -9.282,
                                26.36
                            ],
                            [
                                -9.622,
                                26.547
                            ],
                            [
                                -10.21,
                                26.909
                            ],
                            [
                                -10.321,
                                26.986
                            ],
                            [
                                -10.795,
                                27.31
                            ],
                            [
                                -11.194,
                                27.621
                            ],
                            [
                                -11.376,
                                27.775
                            ],
                            [
                                -11.886,
                                28.266
                            ],
                            [
                                -11.949,
                                28.338
                            ],
                            [
                                -12.399,
                                28.921
                            ],
                            [
                                -12.504,
                                29.105
                            ],
                            [
                                -12.754,
                                29.585
                            ],
                            [
                                -12.977,
                                30.258
                            ],
                            [
                                -13.007,
                                30.422
                            ],
                            [
                                -13.098,
                                30.941
                            ],
                            [
                                -13.123,
                                31.632
                            ],
                            [
                                -13.061,
                                32.333
                            ],
                            [
                                -12.924,
                                33.044
                            ],
                            [
                                -12.738,
                                33.765
                            ],
                            [
                                -12.537,
                                34.495
                            ],
                            [
                                -12.491,
                                34.688
                            ],
                            [
                                -12.36,
                                35.236
                            ],
                            [
                                -12.237,
                                35.985
                            ],
                            [
                                -12.182,
                                36.745
                            ],
                            [
                                -12.183,
                                36.859
                            ],
                            [
                                -12.186,
                                37.515
                            ],
                            [
                                -12.23,
                                38.296
                            ],
                            [
                                -12.284,
                                39.091
                            ],
                            [
                                -12.315,
                                39.899
                            ],
                            [
                                -12.295,
                                40.724
                            ],
                            [
                                -12.287,
                                40.824
                            ],
                            [
                                -12.217,
                                41.567
                            ],
                            [
                                -12.096,
                                42.427
                            ],
                            [
                                -11.969,
                                43.306
                            ],
                            [
                                -11.891,
                                44.203
                            ],
                            [
                                -11.927,
                                45.119
                            ],
                            [
                                -12.121,
                                46.002
                            ],
                            [
                                -12.137,
                                46.053
                            ],
                            [
                                -12.62,
                                47.005
                            ],
                            [
                                -12.729,
                                47.176
                            ],
                            [
                                -13.413,
                                47.978
                            ],
                            [
                                -13.429,
                                47.999
                            ],
                            [
                                -14.15,
                                48.835
                            ],
                            [
                                -14.276,
                                48.979
                            ],
                            [
                                -14.836,
                                50.024
                            ],
                            [
                                -15.062,
                                51.108
                            ],
                            [
                                -15.023,
                                52.241
                            ],
                            [
                                -14.784,
                                53.424
                            ],
                            [
                                -14.381,
                                54.665
                            ],
                            [
                                -13.831,
                                55.972
                            ],
                            [
                                -13.465,
                                56.71
                            ],
                            [
                                -13.139,
                                57.356
                            ],
                            [
                                -12.301,
                                58.831
                            ],
                            [
                                -11.28,
                                60.421
                            ],
                            [
                                -11.096,
                                60.683
                            ],
                            [
                                -9.983,
                                62.157
                            ],
                            [
                                -8.28,
                                64.091
                            ],
                            [
                                -8.2,
                                64.173
                            ],
                            [
                                -5.824,
                                66.323
                            ],
                            [
                                -4.674,
                                67.217
                            ],
                            [
                                -1.902,
                                69.063
                            ],
                            [
                                -0.107,
                                70.101
                            ],
                            [
                                6.418,
                                73.093
                            ],
                            [
                                6.792,
                                73.24
                            ],
                            [
                                23.196,
                                77.402
                            ],
                            [
                                23.6899,
                                77.5102
                            ],
                            [
                                28.8171,
                                76.1658
                            ],
                            [
                                33.0001,
                                74.729
                            ],
                            [
                                36.4401,
                                73.2235
                            ],
                            [
                                39.2968,
                                71.6662
                            ],
                            [
                                41.6934,
                                70.0693
                            ],
                            [
                                43.724,
                                68.4415
                            ],
                            [
                                45.4605,
                                66.7895
                            ],
                            [
                                46.9582,
                                65.1179
                            ],
                            [
                                48.26,
                                63.4306
                            ],
                            [
                                49.3994,
                                61.7303
                            ],
                            [
                                50.4031,
                                60.0193
                            ],
                            [
                                51.2923,
                                58.2993
                            ],
                            [
                                52.0839,
                                56.5717
                            ],
                            [
                                52.792,
                                54.8376
                            ],
                            [
                                53.428,
                                53.098
                            ],
                            [
                                54.0012,
                                51.3537
                            ],
                            [
                                54.5194,
                                49.6051
                            ],
                            [
                                54.9894,
                                47.853
                            ],
                            [
                                55.4166,
                                46.0977
                            ],
                            [
                                55.8058,
                                44.3396
                            ],
                            [
                                56.161,
                                42.579
                            ],
                            [
                                56.4855,
                                40.8163
                            ],
                            [
                                56.7825,
                                39.0515
                            ],
                            [
                                57.0545,
                                37.2851
                            ],
                            [
                                57.3037,
                                35.5171
                            ],
                            [
                                57.532,
                                33.7477
                            ],
                            [
                                57.7412,
                                31.9771
                            ],
                            [
                                57.9327,
                                30.2053
                            ],
                            [
                                58.1079,
                                28.4325
                            ],
                            [
                                58.2679,
                                26.6588
                            ],
                            [
                                58.4138,
                                24.8843
                            ],
                            [
                                58.5463,
                                23.1091
                            ],
                            [
                                58.6663,
                                21.3333
                            ],
                            [
                                58.7745,
                                19.5568
                            ],
                            [
                                58.8716,
                                17.7799
                            ],
                            [
                                58.9579,
                                16.0024
                            ],
                            [
                                59.034,
                                14.2246
                            ],
                            [
                                59.1002,
                                12.4464
                            ],
                            [
                                59.157,
                                10.668
                            ],
                            [
                                59.2045,
                                8.8892
                            ],
                            [
                                59.2431,
                                7.1103
                            ],
                            [
                                59.273,
                                5.3312
                            ],
                            [
                                59.2942,
                                3.552
                            ],
                            [
                                59.3068,
                                1.7727
                            ],
                            [
                                59.311,
                                -0.0066
                            ],
                            [
                                59.3068,
                                -1.786
                            ],
                            [
                                59.294,
                                -3.5652
                            ],
                            [
                                59.2728,
                                -5.3445
                            ],
                            [
                                59.123,
                                -5.094
                            ]
                        ]
                    ]
                },
                "type": "Feature",
                "properties": {
                    "obj_child_num": 0,
                    "obj_revision": 5,
                    "obj_id": 7514,
                    "obj_version": "",
                    "gain": 4500,
                    "obj_name": "SES-4_EU01_WMP",
                    "obj_parentid": 1727
                }
            },
            {
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                59.123,
                                -5.094
                            ],
                            [
                                54.111,
                                -5.211
                            ],
                            [
                                49.535,
                                -5.385
                            ],
                            [
                                46.86,
                                -5.477
                            ],
                            [
                                46.501,
                                -5.492
                            ],
                            [
                                44.062,
                                -5.56
                            ],
                            [
                                41.962,
                                -5.595
                            ],
                            [
                                40.086,
                                -5.587
                            ],
                            [
                                38.367,
                                -5.513
                            ],
                            [
                                36.766,
                                -5.361
                            ],
                            [
                                36.552,
                                -5.329
                            ],
                            [
                                35.258,
                                -5.115
                            ],
                            [
                                33.832,
                                -4.802
                            ],
                            [
                                33.427,
                                -4.697
                            ],
                            [
                                32.477,
                                -4.41
                            ],
                            [
                                31.499,
                                -4.089
                            ],
                            [
                                31.189,
                                -3.967
                            ],
                            [
                                29.957,
                                -3.49
                            ],
                            [
                                28.789,
                                -2.982
                            ],
                            [
                                28.593,
                                -2.898
                            ],
                            [
                                27.666,
                                -2.441
                            ],
                            [
                                27.408,
                                -2.311
                            ],
                            [
                                26.585,
                                -1.792
                            ],
                            [
                                26.492,
                                -1.729
                            ],
                            [
                                25.96,
                                -1.151
                            ],
                            [
                                25.803,
                                -0.575
                            ],
                            [
                                26.023,
                                0
                            ],
                            [
                                26.454,
                                0.576
                            ],
                            [
                                26.55,
                                0.705
                            ],
                            [
                                26.839,
                                1.154
                            ],
                            [
                                27.025,
                                1.732
                            ],
                            [
                                27.007,
                                2.309
                            ],
                            [
                                26.814,
                                2.886
                            ],
                            [
                                26.671,
                                3.126
                            ],
                            [
                                26.467,
                                3.461
                            ],
                            [
                                26.001,
                                4.034
                            ],
                            [
                                25.766,
                                4.296
                            ],
                            [
                                25.484,
                                4.606
                            ],
                            [
                                24.983,
                                5.178
                            ],
                            [
                                24.894,
                                5.294
                            ],
                            [
                                24.498,
                                5.749
                            ],
                            [
                                24.058,
                                6.25
                            ],
                            [
                                23.979,
                                6.319
                            ],
                            [
                                23.311,
                                6.887
                            ],
                            [
                                23.22,
                                6.962
                            ],
                            [
                                22.372,
                                7.449
                            ],
                            [
                                21.556,
                                7.994
                            ],
                            [
                                21.536,
                                8.012
                            ],
                            [
                                21.228,
                                8.584
                            ],
                            [
                                21.169,
                                9.162
                            ],
                            [
                                21.208,
                                9.743
                            ],
                            [
                                21.243,
                                10.325
                            ],
                            [
                                21.228,
                                10.839
                            ],
                            [
                                21.231,
                                10.908
                            ],
                            [
                                21.202,
                                11.492
                            ],
                            [
                                21.14,
                                12.076
                            ],
                            [
                                21.076,
                                12.663
                            ],
                            [
                                21.044,
                                13.252
                            ],
                            [
                                21.055,
                                13.646
                            ],
                            [
                                21.065,
                                13.844
                            ],
                            [
                                21.132,
                                14.44
                            ],
                            [
                                21.206,
                                15.039
                            ],
                            [
                                21.257,
                                15.639
                            ],
                            [
                                21.25,
                                16.24
                            ],
                            [
                                21.171,
                                16.817
                            ],
                            [
                                21.167,
                                16.84
                            ],
                            [
                                20.94,
                                17.438
                            ],
                            [
                                20.677,
                                17.963
                            ],
                            [
                                20.623,
                                18.034
                            ],
                            [
                                20.135,
                                18.625
                            ],
                            [
                                20.059,
                                18.726
                            ],
                            [
                                19.358,
                                19.207
                            ],
                            [
                                19.349,
                                19.214
                            ],
                            [
                                18.58,
                                19.518
                            ],
                            [
                                17.772,
                                19.687
                            ],
                            [
                                17.518,
                                19.746
                            ],
                            [
                                16.998,
                                19.909
                            ],
                            [
                                16.258,
                                20.198
                            ],
                            [
                                16.063,
                                20.301
                            ],
                            [
                                15.603,
                                20.696
                            ],
                            [
                                15.386,
                                20.892
                            ],
                            [
                                15.09,
                                21.5
                            ],
                            [
                                15.51,
                                22.144
                            ],
                            [
                                16.23,
                                22.347
                            ],
                            [
                                17.161,
                                22.523
                            ],
                            [
                                18.184,
                                22.857
                            ],
                            [
                                18.249,
                                22.901
                            ],
                            [
                                18.652,
                                23.558
                            ],
                            [
                                18.58,
                                23.729
                            ],
                            [
                                18.268,
                                24.18
                            ],
                            [
                                17.955,
                                24.317
                            ],
                            [
                                17.125,
                                24.442
                            ],
                            [
                                16.211,
                                24.346
                            ],
                            [
                                15.226,
                                24.038
                            ],
                            [
                                15.206,
                                24.03
                            ],
                            [
                                14.365,
                                23.354
                            ],
                            [
                                13.975,
                                22.978
                            ],
                            [
                                13.56,
                                22.686
                            ],
                            [
                                12.992,
                                22.501
                            ],
                            [
                                12.117,
                                22.262
                            ],
                            [
                                11.262,
                                22.031
                            ],
                            [
                                11.038,
                                21.955
                            ],
                            [
                                10.417,
                                21.779
                            ],
                            [
                                9.564,
                                21.45
                            ],
                            [
                                9.201,
                                21.264
                            ],
                            [
                                8.696,
                                21.002
                            ],
                            [
                                8.099,
                                20.611
                            ],
                            [
                                7.807,
                                20.401
                            ],
                            [
                                7.271,
                                19.973
                            ],
                            [
                                6.909,
                                19.666
                            ],
                            [
                                6.51,
                                19.343
                            ],
                            [
                                6.053,
                                19
                            ],
                            [
                                5.579,
                                18.713
                            ],
                            [
                                5.266,
                                18.555
                            ],
                            [
                                4.533,
                                18.308
                            ],
                            [
                                3.836,
                                18.201
                            ],
                            [
                                3.172,
                                18.227
                            ],
                            [
                                2.538,
                                18.388
                            ],
                            [
                                2.081,
                                18.615
                            ],
                            [
                                1.934,
                                18.695
                            ],
                            [
                                1.36,
                                19.151
                            ],
                            [
                                1.318,
                                19.192
                            ],
                            [
                                0.804,
                                19.695
                            ],
                            [
                                0.726,
                                19.776
                            ],
                            [
                                0.249,
                                20.24
                            ],
                            [
                                0.118,
                                20.363
                            ],
                            [
                                -0.313,
                                20.734
                            ],
                            [
                                -0.596,
                                20.95
                            ],
                            [
                                -0.886,
                                21.162
                            ],
                            [
                                -1.467,
                                21.539
                            ],
                            [
                                -2.051,
                                21.891
                            ],
                            [
                                -2.467,
                                22.123
                            ],
                            [
                                -2.638,
                                22.219
                            ],
                            [
                                -3.224,
                                22.549
                            ],
                            [
                                -3.503,
                                22.713
                            ],
                            [
                                -3.807,
                                22.898
                            ],
                            [
                                -4.385,
                                23.272
                            ],
                            [
                                -4.44,
                                23.309
                            ],
                            [
                                -4.959,
                                23.665
                            ],
                            [
                                -5.304,
                                23.911
                            ],
                            [
                                -5.532,
                                24.077
                            ],
                            [
                                -6.105,
                                24.488
                            ],
                            [
                                -6.149,
                                24.519
                            ],
                            [
                                -6.683,
                                24.87
                            ],
                            [
                                -7.089,
                                25.129
                            ],
                            [
                                -7.265,
                                25.229
                            ],
                            [
                                -7.854,
                                25.555
                            ],
                            [
                                -8.172,
                                25.742
                            ],
                            [
                                -8.443,
                                25.879
                            ],
                            [
                                -9.032,
                                26.207
                            ],
                            [
                                -9.282,
                                26.36
                            ],
                            [
                                -9.622,
                                26.547
                            ],
                            [
                                -10.21,
                                26.909
                            ],
                            [
                                -10.321,
                                26.986
                            ],
                            [
                                -10.795,
                                27.31
                            ],
                            [
                                -11.194,
                                27.621
                            ],
                            [
                                -11.376,
                                27.775
                            ],
                            [
                                -11.886,
                                28.266
                            ],
                            [
                                -11.949,
                                28.338
                            ],
                            [
                                -12.399,
                                28.921
                            ],
                            [
                                -12.504,
                                29.105
                            ],
                            [
                                -12.754,
                                29.585
                            ],
                            [
                                -12.977,
                                30.258
                            ],
                            [
                                -13.007,
                                30.422
                            ],
                            [
                                -13.098,
                                30.941
                            ],
                            [
                                -13.123,
                                31.632
                            ],
                            [
                                -13.061,
                                32.333
                            ],
                            [
                                -12.924,
                                33.044
                            ],
                            [
                                -12.738,
                                33.765
                            ],
                            [
                                -12.537,
                                34.495
                            ],
                            [
                                -12.491,
                                34.688
                            ],
                            [
                                -12.36,
                                35.236
                            ],
                            [
                                -12.237,
                                35.985
                            ],
                            [
                                -12.182,
                                36.745
                            ],
                            [
                                -12.183,
                                36.859
                            ],
                            [
                                -12.186,
                                37.515
                            ],
                            [
                                -12.23,
                                38.296
                            ],
                            [
                                -12.284,
                                39.091
                            ],
                            [
                                -12.315,
                                39.899
                            ],
                            [
                                -12.295,
                                40.724
                            ],
                            [
                                -12.287,
                                40.824
                            ],
                            [
                                -12.217,
                                41.567
                            ],
                            [
                                -12.096,
                                42.427
                            ],
                            [
                                -11.969,
                                43.306
                            ],
                            [
                                -11.891,
                                44.203
                            ],
                            [
                                -11.927,
                                45.119
                            ],
                            [
                                -12.121,
                                46.002
                            ],
                            [
                                -12.137,
                                46.053
                            ],
                            [
                                -12.62,
                                47.005
                            ],
                            [
                                -12.729,
                                47.176
                            ],
                            [
                                -13.413,
                                47.978
                            ],
                            [
                                -13.429,
                                47.999
                            ],
                            [
                                -14.15,
                                48.835
                            ],
                            [
                                -14.276,
                                48.979
                            ],
                            [
                                -14.836,
                                50.024
                            ],
                            [
                                -15.062,
                                51.108
                            ],
                            [
                                -15.023,
                                52.241
                            ],
                            [
                                -14.784,
                                53.424
                            ],
                            [
                                -14.381,
                                54.665
                            ],
                            [
                                -13.831,
                                55.972
                            ],
                            [
                                -13.465,
                                56.71
                            ],
                            [
                                -13.139,
                                57.356
                            ],
                            [
                                -12.301,
                                58.831
                            ],
                            [
                                -11.28,
                                60.421
                            ],
                            [
                                -11.096,
                                60.683
                            ],
                            [
                                -9.983,
                                62.157
                            ],
                            [
                                -8.28,
                                64.091
                            ],
                            [
                                -8.2,
                                64.173
                            ],
                            [
                                -5.824,
                                66.323
                            ],
                            [
                                -4.674,
                                67.217
                            ],
                            [
                                -1.902,
                                69.063
                            ],
                            [
                                -0.107,
                                70.101
                            ],
                            [
                                6.418,
                                73.093
                            ],
                            [
                                6.792,
                                73.24
                            ],
                            [
                                23.196,
                                77.402
                            ],
                            [
                                23.6899,
                                77.5102
                            ],
                            [
                                28.8171,
                                76.1658
                            ],
                            [
                                33.0001,
                                74.729
                            ],
                            [
                                36.4401,
                                73.2235
                            ],
                            [
                                39.2968,
                                71.6662
                            ],
                            [
                                41.6934,
                                70.0693
                            ],
                            [
                                43.724,
                                68.4415
                            ],
                            [
                                45.4605,
                                66.7895
                            ],
                            [
                                46.9582,
                                65.1179
                            ],
                            [
                                48.26,
                                63.4306
                            ],
                            [
                                49.3994,
                                61.7303
                            ],
                            [
                                50.4031,
                                60.0193
                            ],
                            [
                                51.2923,
                                58.2993
                            ],
                            [
                                52.0839,
                                56.5717
                            ],
                            [
                                52.792,
                                54.8376
                            ],
                            [
                                53.428,
                                53.098
                            ],
                            [
                                54.0012,
                                51.3537
                            ],
                            [
                                54.5194,
                                49.6051
                            ],
                            [
                                54.9894,
                                47.853
                            ],
                            [
                                55.4166,
                                46.0977
                            ],
                            [
                                55.8058,
                                44.3396
                            ],
                            [
                                56.161,
                                42.579
                            ],
                            [
                                56.4855,
                                40.8163
                            ],
                            [
                                56.7825,
                                39.0515
                            ],
                            [
                                57.0545,
                                37.2851
                            ],
                            [
                                57.3037,
                                35.5171
                            ],
                            [
                                57.532,
                                33.7477
                            ],
                            [
                                57.7412,
                                31.9771
                            ],
                            [
                                57.9327,
                                30.2053
                            ],
                            [
                                58.1079,
                                28.4325
                            ],
                            [
                                58.2679,
                                26.6588
                            ],
                            [
                                58.4138,
                                24.8843
                            ],
                            [
                                58.5463,
                                23.1091
                            ],
                            [
                                58.6663,
                                21.3333
                            ],
                            [
                                58.7745,
                                19.5568
                            ],
                            [
                                58.8716,
                                17.7799
                            ],
                            [
                                58.9579,
                                16.0024
                            ],
                            [
                                59.034,
                                14.2246
                            ],
                            [
                                59.1002,
                                12.4464
                            ],
                            [
                                59.157,
                                10.668
                            ],
                            [
                                59.2045,
                                8.8892
                            ],
                            [
                                59.2431,
                                7.1103
                            ],
                            [
                                59.273,
                                5.3312
                            ],
                            [
                                59.2942,
                                3.552
                            ],
                            [
                                59.3068,
                                1.7727
                            ],
                            [
                                59.311,
                                -0.0066
                            ],
                            [
                                59.3068,
                                -1.786
                            ],
                            [
                                59.294,
                                -3.5652
                            ],
                            [
                                59.2728,
                                -5.3445
                            ],
                            [
                                59.123,
                                -5.094
                            ]
                        ]
                    ]
                },
                "type": "Feature",
                "properties": {
                    "obj_child_num": 0,
                    "obj_revision": 6,
                    "obj_id": 7513,
                    "obj_version": "",
                    "gain": 0,
                    "obj_name": "SES-4_EU02_WMP",
                    "obj_parentid": 1727
                }
            },
            {
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                59.123,
                                -5.094
                            ],
                            [
                                54.111,
                                -5.211
                            ],
                            [
                                49.535,
                                -5.385
                            ],
                            [
                                46.86,
                                -5.477
                            ],
                            [
                                46.501,
                                -5.492
                            ],
                            [
                                44.062,
                                -5.56
                            ],
                            [
                                41.962,
                                -5.595
                            ],
                            [
                                40.086,
                                -5.587
                            ],
                            [
                                38.367,
                                -5.513
                            ],
                            [
                                36.766,
                                -5.361
                            ],
                            [
                                36.552,
                                -5.329
                            ],
                            [
                                35.258,
                                -5.115
                            ],
                            [
                                33.832,
                                -4.802
                            ],
                            [
                                33.427,
                                -4.697
                            ],
                            [
                                32.477,
                                -4.41
                            ],
                            [
                                31.499,
                                -4.089
                            ],
                            [
                                31.189,
                                -3.967
                            ],
                            [
                                29.957,
                                -3.49
                            ],
                            [
                                28.789,
                                -2.982
                            ],
                            [
                                28.593,
                                -2.898
                            ],
                            [
                                27.666,
                                -2.441
                            ],
                            [
                                27.408,
                                -2.311
                            ],
                            [
                                26.585,
                                -1.792
                            ],
                            [
                                26.492,
                                -1.729
                            ],
                            [
                                25.96,
                                -1.151
                            ],
                            [
                                25.803,
                                -0.575
                            ],
                            [
                                26.023,
                                0
                            ],
                            [
                                26.454,
                                0.576
                            ],
                            [
                                26.55,
                                0.705
                            ],
                            [
                                26.839,
                                1.154
                            ],
                            [
                                27.025,
                                1.732
                            ],
                            [
                                27.007,
                                2.309
                            ],
                            [
                                26.814,
                                2.886
                            ],
                            [
                                26.671,
                                3.126
                            ],
                            [
                                26.467,
                                3.461
                            ],
                            [
                                26.001,
                                4.034
                            ],
                            [
                                25.766,
                                4.296
                            ],
                            [
                                25.484,
                                4.606
                            ],
                            [
                                24.983,
                                5.178
                            ],
                            [
                                24.894,
                                5.294
                            ],
                            [
                                24.498,
                                5.749
                            ],
                            [
                                24.058,
                                6.25
                            ],
                            [
                                23.979,
                                6.319
                            ],
                            [
                                23.311,
                                6.887
                            ],
                            [
                                23.22,
                                6.962
                            ],
                            [
                                22.372,
                                7.449
                            ],
                            [
                                21.556,
                                7.994
                            ],
                            [
                                21.536,
                                8.012
                            ],
                            [
                                21.228,
                                8.584
                            ],
                            [
                                21.169,
                                9.162
                            ],
                            [
                                21.208,
                                9.743
                            ],
                            [
                                21.243,
                                10.325
                            ],
                            [
                                21.228,
                                10.839
                            ],
                            [
                                21.231,
                                10.908
                            ],
                            [
                                21.202,
                                11.492
                            ],
                            [
                                21.14,
                                12.076
                            ],
                            [
                                21.076,
                                12.663
                            ],
                            [
                                21.044,
                                13.252
                            ],
                            [
                                21.055,
                                13.646
                            ],
                            [
                                21.065,
                                13.844
                            ],
                            [
                                21.132,
                                14.44
                            ],
                            [
                                21.206,
                                15.039
                            ],
                            [
                                21.257,
                                15.639
                            ],
                            [
                                21.25,
                                16.24
                            ],
                            [
                                21.171,
                                16.817
                            ],
                            [
                                21.167,
                                16.84
                            ],
                            [
                                20.94,
                                17.438
                            ],
                            [
                                20.677,
                                17.963
                            ],
                            [
                                20.623,
                                18.034
                            ],
                            [
                                20.135,
                                18.625
                            ],
                            [
                                20.059,
                                18.726
                            ],
                            [
                                19.358,
                                19.207
                            ],
                            [
                                19.349,
                                19.214
                            ],
                            [
                                18.58,
                                19.518
                            ],
                            [
                                17.772,
                                19.687
                            ],
                            [
                                17.518,
                                19.746
                            ],
                            [
                                16.998,
                                19.909
                            ],
                            [
                                16.258,
                                20.198
                            ],
                            [
                                16.063,
                                20.301
                            ],
                            [
                                15.603,
                                20.696
                            ],
                            [
                                15.386,
                                20.892
                            ],
                            [
                                15.09,
                                21.5
                            ],
                            [
                                15.51,
                                22.144
                            ],
                            [
                                16.23,
                                22.347
                            ],
                            [
                                17.161,
                                22.523
                            ],
                            [
                                18.184,
                                22.857
                            ],
                            [
                                18.249,
                                22.901
                            ],
                            [
                                18.652,
                                23.558
                            ],
                            [
                                18.58,
                                23.729
                            ],
                            [
                                18.268,
                                24.18
                            ],
                            [
                                17.955,
                                24.317
                            ],
                            [
                                17.125,
                                24.442
                            ],
                            [
                                16.211,
                                24.346
                            ],
                            [
                                15.226,
                                24.038
                            ],
                            [
                                15.206,
                                24.03
                            ],
                            [
                                14.365,
                                23.354
                            ],
                            [
                                13.975,
                                22.978
                            ],
                            [
                                13.56,
                                22.686
                            ],
                            [
                                12.992,
                                22.501
                            ],
                            [
                                12.117,
                                22.262
                            ],
                            [
                                11.262,
                                22.031
                            ],
                            [
                                11.038,
                                21.955
                            ],
                            [
                                10.417,
                                21.779
                            ],
                            [
                                9.564,
                                21.45
                            ],
                            [
                                9.201,
                                21.264
                            ],
                            [
                                8.696,
                                21.002
                            ],
                            [
                                8.099,
                                20.611
                            ],
                            [
                                7.807,
                                20.401
                            ],
                            [
                                7.271,
                                19.973
                            ],
                            [
                                6.909,
                                19.666
                            ],
                            [
                                6.51,
                                19.343
                            ],
                            [
                                6.053,
                                19
                            ],
                            [
                                5.579,
                                18.713
                            ],
                            [
                                5.266,
                                18.555
                            ],
                            [
                                4.533,
                                18.308
                            ],
                            [
                                3.836,
                                18.201
                            ],
                            [
                                3.172,
                                18.227
                            ],
                            [
                                2.538,
                                18.388
                            ],
                            [
                                2.081,
                                18.615
                            ],
                            [
                                1.934,
                                18.695
                            ],
                            [
                                1.36,
                                19.151
                            ],
                            [
                                1.318,
                                19.192
                            ],
                            [
                                0.804,
                                19.695
                            ],
                            [
                                0.726,
                                19.776
                            ],
                            [
                                0.249,
                                20.24
                            ],
                            [
                                0.118,
                                20.363
                            ],
                            [
                                -0.313,
                                20.734
                            ],
                            [
                                -0.596,
                                20.95
                            ],
                            [
                                -0.886,
                                21.162
                            ],
                            [
                                -1.467,
                                21.539
                            ],
                            [
                                -2.051,
                                21.891
                            ],
                            [
                                -2.467,
                                22.123
                            ],
                            [
                                -2.638,
                                22.219
                            ],
                            [
                                -3.224,
                                22.549
                            ],
                            [
                                -3.503,
                                22.713
                            ],
                            [
                                -3.807,
                                22.898
                            ],
                            [
                                -4.385,
                                23.272
                            ],
                            [
                                -4.44,
                                23.309
                            ],
                            [
                                -4.959,
                                23.665
                            ],
                            [
                                -5.304,
                                23.911
                            ],
                            [
                                -5.532,
                                24.077
                            ],
                            [
                                -6.105,
                                24.488
                            ],
                            [
                                -6.149,
                                24.519
                            ],
                            [
                                -6.683,
                                24.87
                            ],
                            [
                                -7.089,
                                25.129
                            ],
                            [
                                -7.265,
                                25.229
                            ],
                            [
                                -7.854,
                                25.555
                            ],
                            [
                                -8.172,
                                25.742
                            ],
                            [
                                -8.443,
                                25.879
                            ],
                            [
                                -9.032,
                                26.207
                            ],
                            [
                                -9.282,
                                26.36
                            ],
                            [
                                -9.622,
                                26.547
                            ],
                            [
                                -10.21,
                                26.909
                            ],
                            [
                                -10.321,
                                26.986
                            ],
                            [
                                -10.795,
                                27.31
                            ],
                            [
                                -11.194,
                                27.621
                            ],
                            [
                                -11.376,
                                27.775
                            ],
                            [
                                -11.886,
                                28.266
                            ],
                            [
                                -11.949,
                                28.338
                            ],
                            [
                                -12.399,
                                28.921
                            ],
                            [
                                -12.504,
                                29.105
                            ],
                            [
                                -12.754,
                                29.585
                            ],
                            [
                                -12.977,
                                30.258
                            ],
                            [
                                -13.007,
                                30.422
                            ],
                            [
                                -13.098,
                                30.941
                            ],
                            [
                                -13.123,
                                31.632
                            ],
                            [
                                -13.061,
                                32.333
                            ],
                            [
                                -12.924,
                                33.044
                            ],
                            [
                                -12.738,
                                33.765
                            ],
                            [
                                -12.537,
                                34.495
                            ],
                            [
                                -12.491,
                                34.688
                            ],
                            [
                                -12.36,
                                35.236
                            ],
                            [
                                -12.237,
                                35.985
                            ],
                            [
                                -12.182,
                                36.745
                            ],
                            [
                                -12.183,
                                36.859
                            ],
                            [
                                -12.186,
                                37.515
                            ],
                            [
                                -12.23,
                                38.296
                            ],
                            [
                                -12.284,
                                39.091
                            ],
                            [
                                -12.315,
                                39.899
                            ],
                            [
                                -12.295,
                                40.724
                            ],
                            [
                                -12.287,
                                40.824
                            ],
                            [
                                -12.217,
                                41.567
                            ],
                            [
                                -12.096,
                                42.427
                            ],
                            [
                                -11.969,
                                43.306
                            ],
                            [
                                -11.891,
                                44.203
                            ],
                            [
                                -11.927,
                                45.119
                            ],
                            [
                                -12.121,
                                46.002
                            ],
                            [
                                -12.137,
                                46.053
                            ],
                            [
                                -12.62,
                                47.005
                            ],
                            [
                                -12.729,
                                47.176
                            ],
                            [
                                -13.413,
                                47.978
                            ],
                            [
                                -13.429,
                                47.999
                            ],
                            [
                                -14.15,
                                48.835
                            ],
                            [
                                -14.276,
                                48.979
                            ],
                            [
                                -14.836,
                                50.024
                            ],
                            [
                                -15.062,
                                51.108
                            ],
                            [
                                -15.023,
                                52.241
                            ],
                            [
                                -14.784,
                                53.424
                            ],
                            [
                                -14.381,
                                54.665
                            ],
                            [
                                -13.831,
                                55.972
                            ],
                            [
                                -13.465,
                                56.71
                            ],
                            [
                                -13.139,
                                57.356
                            ],
                            [
                                -12.301,
                                58.831
                            ],
                            [
                                -11.28,
                                60.421
                            ],
                            [
                                -11.096,
                                60.683
                            ],
                            [
                                -9.983,
                                62.157
                            ],
                            [
                                -8.28,
                                64.091
                            ],
                            [
                                -8.2,
                                64.173
                            ],
                            [
                                -5.824,
                                66.323
                            ],
                            [
                                -4.674,
                                67.217
                            ],
                            [
                                -1.902,
                                69.063
                            ],
                            [
                                -0.107,
                                70.101
                            ],
                            [
                                6.418,
                                73.093
                            ],
                            [
                                6.792,
                                73.24
                            ],
                            [
                                23.196,
                                77.402
                            ],
                            [
                                23.6899,
                                77.5102
                            ],
                            [
                                28.8171,
                                76.1658
                            ],
                            [
                                33.0001,
                                74.729
                            ],
                            [
                                36.4401,
                                73.2235
                            ],
                            [
                                39.2968,
                                71.6662
                            ],
                            [
                                41.6934,
                                70.0693
                            ],
                            [
                                43.724,
                                68.4415
                            ],
                            [
                                45.4605,
                                66.7895
                            ],
                            [
                                46.9582,
                                65.1179
                            ],
                            [
                                48.26,
                                63.4306
                            ],
                            [
                                49.3994,
                                61.7303
                            ],
                            [
                                50.4031,
                                60.0193
                            ],
                            [
                                51.2923,
                                58.2993
                            ],
                            [
                                52.0839,
                                56.5717
                            ],
                            [
                                52.792,
                                54.8376
                            ],
                            [
                                53.428,
                                53.098
                            ],
                            [
                                54.0012,
                                51.3537
                            ],
                            [
                                54.5194,
                                49.6051
                            ],
                            [
                                54.9894,
                                47.853
                            ],
                            [
                                55.4166,
                                46.0977
                            ],
                            [
                                55.8058,
                                44.3396
                            ],
                            [
                                56.161,
                                42.579
                            ],
                            [
                                56.4855,
                                40.8163
                            ],
                            [
                                56.7825,
                                39.0515
                            ],
                            [
                                57.0545,
                                37.2851
                            ],
                            [
                                57.3037,
                                35.5171
                            ],
                            [
                                57.532,
                                33.7477
                            ],
                            [
                                57.7412,
                                31.9771
                            ],
                            [
                                57.9327,
                                30.2053
                            ],
                            [
                                58.1079,
                                28.4325
                            ],
                            [
                                58.2679,
                                26.6588
                            ],
                            [
                                58.4138,
                                24.8843
                            ],
                            [
                                58.5463,
                                23.1091
                            ],
                            [
                                58.6663,
                                21.3333
                            ],
                            [
                                58.7745,
                                19.5568
                            ],
                            [
                                58.8716,
                                17.7799
                            ],
                            [
                                58.9579,
                                16.0024
                            ],
                            [
                                59.034,
                                14.2246
                            ],
                            [
                                59.1002,
                                12.4464
                            ],
                            [
                                59.157,
                                10.668
                            ],
                            [
                                59.2045,
                                8.8892
                            ],
                            [
                                59.2431,
                                7.1103
                            ],
                            [
                                59.273,
                                5.3312
                            ],
                            [
                                59.2942,
                                3.552
                            ],
                            [
                                59.3068,
                                1.7727
                            ],
                            [
                                59.311,
                                -0.0066
                            ],
                            [
                                59.3068,
                                -1.786
                            ],
                            [
                                59.294,
                                -3.5652
                            ],
                            [
                                59.2728,
                                -5.3445
                            ],
                            [
                                59.123,
                                -5.094
                            ]
                        ]
                    ]
                },
                "type": "Feature",
                "properties": {
                    "obj_child_num": 0,
                    "obj_revision": 6,
                    "obj_id": 7513,
                    "obj_version": "",
                    "gain": 4500,
                    "obj_name": "SES-4_EU02_WMP",
                    "obj_parentid": 1727
                }
            },
            {
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -98.6624,
                                -49.0902
                            ],
                            [
                                -95.0285,
                                -47.1705
                            ],
                            [
                                -95.0285,
                                -46.1605
                            ],
                            [
                                -93.0083,
                                -44.1403
                            ],
                            [
                                -85.5338,
                                -40.706
                            ],
                            [
                                -81.2914,
                                -37.8778
                            ],
                            [
                                -80.4834,
                                -34.2416
                            ],
                            [
                                -77.8572,
                                -28.9892
                            ],
                            [
                                -76.6451,
                                -24.9489
                            ],
                            [
                                -76.2411,
                                -21.3126
                            ],
                            [
                                -77.8572,
                                -19.2924
                            ],
                            [
                                -80.0794,
                                -18.2824
                            ],
                            [
                                -81.0894,
                                -18.0804
                            ],
                            [
                                -83.3116,
                                -15.4542
                            ],
                            [
                                -83.3116,
                                -14.2421
                            ],
                            [
                                -85.3317,
                                -12.4239
                            ],
                            [
                                -87.9579,
                                -9.7977
                            ],
                            [
                                -88.968,
                                -8.1816
                            ],
                            [
                                -88.968,
                                -6.9695
                            ],
                            [
                                -88.1599,
                                -4.9494
                            ],
                            [
                                -85.7358,
                                -2.1212
                            ],
                            [
                                -73.0088,
                                3.1312
                            ],
                            [
                                -68.3625,
                                4.9494
                            ],
                            [
                                -64.1202,
                                6.3635
                            ],
                            [
                                -63.1101,
                                8.9897
                            ],
                            [
                                -59.0698,
                                10.2018
                            ],
                            [
                                -54.0194,
                                9.3937
                            ],
                            [
                                -46.9489,
                                7.5756
                            ],
                            [
                                -44.1207,
                                4.7474
                            ],
                            [
                                -41.0904,
                                2.7272
                            ],
                            [
                                -36.2421,
                                2.3232
                            ],
                            [
                                -33.0098,
                                0.101
                            ],
                            [
                                -31.3937,
                                -2.9292
                            ],
                            [
                                -30.7877,
                                -9.3937
                            ],
                            [
                                -33.2118,
                                -14.6461
                            ],
                            [
                                -36.6461,
                                -22.3227
                            ],
                            [
                                -41.6965,
                                -30.4033
                            ],
                            [
                                -42.5045,
                                -32.0194
                            ],
                            [
                                -45.5348,
                                -33.0295
                            ],
                            [
                                -49.171,
                                -38.2819
                            ],
                            [
                                -50.3831,
                                -40.706
                            ],
                            [
                                -51.1912,
                                -44.7463
                            ],
                            [
                                -51.5952,
                                -51.6149
                            ],
                            [
                                -50.9892,
                                -57.0693
                            ],
                            [
                                -51.1912,
                                -62.7257
                            ],
                            [
                                -53.0093,
                                -65.5539
                            ],
                            [
                                -56.6456,
                                -67.5741
                            ],
                            [
                                -61.0899,
                                -66.968
                            ],
                            [
                                -65.3322,
                                -67.17
                            ],
                            [
                                -67.9584,
                                -64.9479
                            ],
                            [
                                -70.1806,
                                -61.5136
                            ],
                            [
                                -73.0088,
                                -60.0995
                            ],
                            [
                                -75.231,
                                -59.6955
                            ],
                            [
                                -80.0794,
                                -61.1096
                            ],
                            [
                                -84.5237,
                                -61.9176
                            ],
                            [
                                -89.17,
                                -62.3217
                            ],
                            [
                                -92.4905,
                                -63.105
                            ],
                            [
                                -93.602,
                                -61.4025
                            ],
                            [
                                -94.5822,
                                -59.6896
                            ],
                            [
                                -95.4514,
                                -57.968
                            ],
                            [
                                -96.226,
                                -56.2391
                            ],
                            [
                                -96.9195,
                                -54.5039
                            ],
                            [
                                -97.5427,
                                -52.7634
                            ],
                            [
                                -98.1047,
                                -51.0181
                            ],
                            [
                                -98.6133,
                                -49.2689
                            ],
                            [
                                -98.6624,
                                -49.0902
                            ]
                        ]
                    ]
                },
                "type": "Feature",
                "properties": {
                    "obj_child_num": 0,
                    "obj_revision": 7,
                    "obj_id": 7507,
                    "obj_version": "",
                    "gain": 0,
                    "obj_name": "SES-4_SC01_WMP",
                    "obj_parentid": 1727
                }
            },
            {
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -98.6624,
                                -49.0902
                            ],
                            [
                                -95.0285,
                                -47.1705
                            ],
                            [
                                -95.0285,
                                -46.1605
                            ],
                            [
                                -93.0083,
                                -44.1403
                            ],
                            [
                                -85.5338,
                                -40.706
                            ],
                            [
                                -81.2914,
                                -37.8778
                            ],
                            [
                                -80.4834,
                                -34.2416
                            ],
                            [
                                -77.8572,
                                -28.9892
                            ],
                            [
                                -76.6451,
                                -24.9489
                            ],
                            [
                                -76.2411,
                                -21.3126
                            ],
                            [
                                -77.8572,
                                -19.2924
                            ],
                            [
                                -80.0794,
                                -18.2824
                            ],
                            [
                                -81.0894,
                                -18.0804
                            ],
                            [
                                -83.3116,
                                -15.4542
                            ],
                            [
                                -83.3116,
                                -14.2421
                            ],
                            [
                                -85.3317,
                                -12.4239
                            ],
                            [
                                -87.9579,
                                -9.7977
                            ],
                            [
                                -88.968,
                                -8.1816
                            ],
                            [
                                -88.968,
                                -6.9695
                            ],
                            [
                                -88.1599,
                                -4.9494
                            ],
                            [
                                -85.7358,
                                -2.1212
                            ],
                            [
                                -73.0088,
                                3.1312
                            ],
                            [
                                -68.3625,
                                4.9494
                            ],
                            [
                                -64.1202,
                                6.3635
                            ],
                            [
                                -63.1101,
                                8.9897
                            ],
                            [
                                -59.0698,
                                10.2018
                            ],
                            [
                                -54.0194,
                                9.3937
                            ],
                            [
                                -46.9489,
                                7.5756
                            ],
                            [
                                -44.1207,
                                4.7474
                            ],
                            [
                                -41.0904,
                                2.7272
                            ],
                            [
                                -36.2421,
                                2.3232
                            ],
                            [
                                -33.0098,
                                0.101
                            ],
                            [
                                -31.3937,
                                -2.9292
                            ],
                            [
                                -30.7877,
                                -9.3937
                            ],
                            [
                                -33.2118,
                                -14.6461
                            ],
                            [
                                -36.6461,
                                -22.3227
                            ],
                            [
                                -41.6965,
                                -30.4033
                            ],
                            [
                                -42.5045,
                                -32.0194
                            ],
                            [
                                -45.5348,
                                -33.0295
                            ],
                            [
                                -49.171,
                                -38.2819
                            ],
                            [
                                -50.3831,
                                -40.706
                            ],
                            [
                                -51.1912,
                                -44.7463
                            ],
                            [
                                -51.5952,
                                -51.6149
                            ],
                            [
                                -50.9892,
                                -57.0693
                            ],
                            [
                                -51.1912,
                                -62.7257
                            ],
                            [
                                -53.0093,
                                -65.5539
                            ],
                            [
                                -56.6456,
                                -67.5741
                            ],
                            [
                                -61.0899,
                                -66.968
                            ],
                            [
                                -65.3322,
                                -67.17
                            ],
                            [
                                -67.9584,
                                -64.9479
                            ],
                            [
                                -70.1806,
                                -61.5136
                            ],
                            [
                                -73.0088,
                                -60.0995
                            ],
                            [
                                -75.231,
                                -59.6955
                            ],
                            [
                                -80.0794,
                                -61.1096
                            ],
                            [
                                -84.5237,
                                -61.9176
                            ],
                            [
                                -89.17,
                                -62.3217
                            ],
                            [
                                -92.4905,
                                -63.105
                            ],
                            [
                                -93.602,
                                -61.4025
                            ],
                            [
                                -94.5822,
                                -59.6896
                            ],
                            [
                                -95.4514,
                                -57.968
                            ],
                            [
                                -96.226,
                                -56.2391
                            ],
                            [
                                -96.9195,
                                -54.5039
                            ],
                            [
                                -97.5427,
                                -52.7634
                            ],
                            [
                                -98.1047,
                                -51.0181
                            ],
                            [
                                -98.6133,
                                -49.2689
                            ],
                            [
                                -98.6624,
                                -49.0902
                            ]
                        ]
                    ]
                },
                "type": "Feature",
                "properties": {
                    "obj_child_num": 0,
                    "obj_revision": 7,
                    "obj_id": 7507,
                    "obj_version": "",
                    "gain": 4500,
                    "obj_name": "SES-4_SC01_WMP",
                    "obj_parentid": 1727
                }
            },
            {
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -80.8898,
                                78.3881
                            ],
                            [
                                -77.7012,
                                77.591
                            ],
                            [
                                -71.4567,
                                75.4652
                            ],
                            [
                                -67.338,
                                73.8709
                            ],
                            [
                                -63.6179,
                                69.885
                            ],
                            [
                                -59.3663,
                                66.2978
                            ],
                            [
                                -56.7091,
                                62.9762
                            ],
                            [
                                -53.3875,
                                57.7946
                            ],
                            [
                                -50.7303,
                                55.4031
                            ],
                            [
                                -50.4646,
                                50.6201
                            ],
                            [
                                -49.0031,
                                42.7813
                            ],
                            [
                                -49.0031,
                                40.6555
                            ],
                            [
                                -49.136,
                                38.264
                            ],
                            [
                                -50.066,
                                34.8096
                            ],
                            [
                                -51.6603,
                                28.1666
                            ],
                            [
                                -51.2618,
                                24.7122
                            ],
                            [
                                -49.5346,
                                19.7963
                            ],
                            [
                                -47.9402,
                                17.9363
                            ],
                            [
                                -43.8215,
                                16.8734
                            ],
                            [
                                -39.7028,
                                17.1391
                            ],
                            [
                                -34.6541,
                                17.4048
                            ],
                            [
                                -30.6683,
                                18.4677
                            ],
                            [
                                -27.2139,
                                18.7334
                            ],
                            [
                                -24.1581,
                                18.3349
                            ],
                            [
                                -20.5708,
                                19.2649
                            ],
                            [
                                -18.8436,
                                20.8592
                            ],
                            [
                                -15.5221,
                                22.7193
                            ],
                            [
                                -11.1377,
                                25.2436
                            ],
                            [
                                -8.879,
                                28.1666
                            ],
                            [
                                -5.4246,
                                30.6909
                            ],
                            [
                                -1.0402,
                                32.1524
                            ],
                            [
                                3.4771,
                                33.481
                            ],
                            [
                                6.9315,
                                34.4111
                            ],
                            [
                                9.0572,
                                35.0754
                            ],
                            [
                                13.0431,
                                35.2082
                            ],
                            [
                                15.1689,
                                36.1383
                            ],
                            [
                                16.8961,
                                37.2012
                            ],
                            [
                                19.819,
                                39.1941
                            ],
                            [
                                21.5462,
                                40.1241
                            ],
                            [
                                24.602,
                                41.3199
                            ],
                            [
                                29.5179,
                                42.9142
                            ],
                            [
                                33.3709,
                                44.1099
                            ],
                            [
                                35.0981,
                                44.7742
                            ],
                            [
                                36.8252,
                                45.4386
                            ],
                            [
                                37.0555,
                                45.489
                            ],
                            [
                                36.642,
                                47.2453
                            ],
                            [
                                36.1874,
                                48.9986
                            ],
                            [
                                35.6865,
                                50.7485
                            ],
                            [
                                35.1332,
                                52.4943
                            ],
                            [
                                34.52,
                                54.2357
                            ],
                            [
                                33.838,
                                55.9717
                            ],
                            [
                                33.0768,
                                57.7017
                            ],
                            [
                                32.2231,
                                59.4245
                            ],
                            [
                                31.2613,
                                61.1388
                            ],
                            [
                                30.1715,
                                62.843
                            ],
                            [
                                28.9294,
                                64.5351
                            ],
                            [
                                27.5039,
                                66.2125
                            ],
                            [
                                25.8557,
                                67.8718
                            ],
                            [
                                23.9341,
                                69.5086
                            ],
                            [
                                21.6737,
                                71.117
                            ],
                            [
                                18.9887,
                                72.6893
                            ],
                            [
                                15.7668,
                                74.2143
                            ],
                            [
                                11.8618,
                                75.6772
                            ],
                            [
                                7.0869,
                                77.0568
                            ],
                            [
                                1.2157,
                                78.3236
                            ],
                            [
                                -5.9953,
                                79.4368
                            ],
                            [
                                -14.735,
                                80.3431
                            ],
                            [
                                -24.9932,
                                80.9801
                            ],
                            [
                                -36.3837,
                                81.2884
                            ],
                            [
                                -48.1091,
                                81.2332
                            ],
                            [
                                -59.2275,
                                80.8212
                            ],
                            [
                                -69.051,
                                80.0967
                            ],
                            [
                                -77.3208,
                                79.1221
                            ],
                            [
                                -80.8898,
                                78.3881
                            ]
                        ]
                    ]
                },
                "type": "Feature",
                "properties": {
                    "obj_child_num": 0,
                    "obj_revision": 5,
                    "obj_id": 7544,
                    "obj_version": "",
                    "gain": 0,
                    "obj_name": "SES-6_AE01_BTZ",
                    "obj_parentid": 2105
                }
            },
            {
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -80.8898,
                                78.3881
                            ],
                            [
                                -77.7012,
                                77.591
                            ],
                            [
                                -71.4567,
                                75.4652
                            ],
                            [
                                -67.338,
                                73.8709
                            ],
                            [
                                -63.6179,
                                69.885
                            ],
                            [
                                -59.3663,
                                66.2978
                            ],
                            [
                                -56.7091,
                                62.9762
                            ],
                            [
                                -53.3875,
                                57.7946
                            ],
                            [
                                -50.7303,
                                55.4031
                            ],
                            [
                                -50.4646,
                                50.6201
                            ],
                            [
                                -49.0031,
                                42.7813
                            ],
                            [
                                -49.0031,
                                40.6555
                            ],
                            [
                                -49.136,
                                38.264
                            ],
                            [
                                -50.066,
                                34.8096
                            ],
                            [
                                -51.6603,
                                28.1666
                            ],
                            [
                                -51.2618,
                                24.7122
                            ],
                            [
                                -49.5346,
                                19.7963
                            ],
                            [
                                -47.9402,
                                17.9363
                            ],
                            [
                                -43.8215,
                                16.8734
                            ],
                            [
                                -39.7028,
                                17.1391
                            ],
                            [
                                -34.6541,
                                17.4048
                            ],
                            [
                                -30.6683,
                                18.4677
                            ],
                            [
                                -27.2139,
                                18.7334
                            ],
                            [
                                -24.1581,
                                18.3349
                            ],
                            [
                                -20.5708,
                                19.2649
                            ],
                            [
                                -18.8436,
                                20.8592
                            ],
                            [
                                -15.5221,
                                22.7193
                            ],
                            [
                                -11.1377,
                                25.2436
                            ],
                            [
                                -8.879,
                                28.1666
                            ],
                            [
                                -5.4246,
                                30.6909
                            ],
                            [
                                -1.0402,
                                32.1524
                            ],
                            [
                                3.4771,
                                33.481
                            ],
                            [
                                6.9315,
                                34.4111
                            ],
                            [
                                9.0572,
                                35.0754
                            ],
                            [
                                13.0431,
                                35.2082
                            ],
                            [
                                15.1689,
                                36.1383
                            ],
                            [
                                16.8961,
                                37.2012
                            ],
                            [
                                19.819,
                                39.1941
                            ],
                            [
                                21.5462,
                                40.1241
                            ],
                            [
                                24.602,
                                41.3199
                            ],
                            [
                                29.5179,
                                42.9142
                            ],
                            [
                                33.3709,
                                44.1099
                            ],
                            [
                                35.0981,
                                44.7742
                            ],
                            [
                                36.8252,
                                45.4386
                            ],
                            [
                                37.0555,
                                45.489
                            ],
                            [
                                36.642,
                                47.2453
                            ],
                            [
                                36.1874,
                                48.9986
                            ],
                            [
                                35.6865,
                                50.7485
                            ],
                            [
                                35.1332,
                                52.4943
                            ],
                            [
                                34.52,
                                54.2357
                            ],
                            [
                                33.838,
                                55.9717
                            ],
                            [
                                33.0768,
                                57.7017
                            ],
                            [
                                32.2231,
                                59.4245
                            ],
                            [
                                31.2613,
                                61.1388
                            ],
                            [
                                30.1715,
                                62.843
                            ],
                            [
                                28.9294,
                                64.5351
                            ],
                            [
                                27.5039,
                                66.2125
                            ],
                            [
                                25.8557,
                                67.8718
                            ],
                            [
                                23.9341,
                                69.5086
                            ],
                            [
                                21.6737,
                                71.117
                            ],
                            [
                                18.9887,
                                72.6893
                            ],
                            [
                                15.7668,
                                74.2143
                            ],
                            [
                                11.8618,
                                75.6772
                            ],
                            [
                                7.0869,
                                77.0568
                            ],
                            [
                                1.2157,
                                78.3236
                            ],
                            [
                                -5.9953,
                                79.4368
                            ],
                            [
                                -14.735,
                                80.3431
                            ],
                            [
                                -24.9932,
                                80.9801
                            ],
                            [
                                -36.3837,
                                81.2884
                            ],
                            [
                                -48.1091,
                                81.2332
                            ],
                            [
                                -59.2275,
                                80.8212
                            ],
                            [
                                -69.051,
                                80.0967
                            ],
                            [
                                -77.3208,
                                79.1221
                            ],
                            [
                                -80.8898,
                                78.3881
                            ]
                        ]
                    ]
                },
                "type": "Feature",
                "properties": {
                    "obj_child_num": 0,
                    "obj_revision": 5,
                    "obj_id": 7544,
                    "obj_version": "",
                    "gain": 4500,
                    "obj_name": "SES-6_AE01_BTZ",
                    "obj_parentid": 2105
                }
            }
        ]
};