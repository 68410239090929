
import React, { useState, useEffect } from "react";
import DateRangeTimePicker from "../DateRangeTimePicker";
import DropDownCard from "../DropDownCard";
import { Grid } from "@mui/material";

import './AppBar.css';

const CustomAppBar = (props:any) => {
    const { handleVsat , handleLTE , handlePolygonColor, isVsatLteEnabled, isDaterangePickerEnabled, isFiltersEnabled, isSerachEnabled , isLocatorEnabled, isMultiSelectEnabled, multiSelectList, handleVesselSelection, isTimeSelectionEnabled, handleInterval, handleCarrierSelection, handleLayerSelection, carrierList, handleStartDate, handleEndDate, defaultStartDate, selctedDefaulcarrier, interValValue, selectedLayer, defaultEndDate, selectedDataSource} = props;

    console.log("selectedLayer", selectedLayer);

    const layersList = ['Call Performance','Fastest Speed'];
    const starLinkLayerList = ['Latency','Speed','Drop Percentage'];
    const intervalList = ['5m','15m','1h','2h','3h','6h','12h','1d','7d','30d'];
    const sourceList = ['CELL','VSAT','STARLINK'];
    let carrierListDetails = carrierList && carrierList.length > 0 ? carrierList: ["ALL"]
            

    const handleEndDatePicker = (date:any) => {
        handleEndDate(date);
    }

    const handleStartDatePicker =(date:any) => {
        handleStartDate(date);
    }

    const handleDataSource = (props:any) => {
        if(props && props === "VSAT") {
            handleVsat(true, "VSAT");
        } else if(props && props === "CELL") {
            handleVsat(false, "CELL");
        } else {
            handleVsat(false, "STARLINK")
        }
    }



   // {isMultiSelectEnabled && <MultiSelect multiSelectList={multiSelectList} handleVesselSelection={handleVesselSelection}/> }
//sm={6} md={3} lg={3}
    return (
        <Grid item container xs={12} lg={12} className="allMapFilters"   >
            {<Grid item  className="dataSourceFilter" sm={12} lg={1} > <DropDownCard className="dataSource" handleCarrierSelection={handleDataSource}  dropDownLabel="Data Source" dropDownList={sourceList} defaultValue={selectedDataSource}/></Grid>}
            <Grid item  lg={7.5} sm={12} className="mapFilters">
                {isFiltersEnabled && selectedDataSource !== "STARLINK" && <Grid  className="dropDownFilter" item sm={12} lg={1.5} > <DropDownCard  handleCarrierSelection={handleCarrierSelection} dropDownLabel="PROVIDER" dropDownList={carrierListDetails}  minWidth={150} defaultValue={selctedDefaulcarrier} /></Grid > }
                {isFiltersEnabled &&  selectedDataSource !== "STARLINK" ?  <Grid item className="layersFilterCont" sm={12} lg={1.5}><DropDownCard className="dropDownFilter layersFilter" handleCarrierSelection={handleLayerSelection}  dropDownLabel="LAYERS" dropDownList={layersList} minWidth={150} defaultValue={selectedLayer}/></Grid> : 
                <Grid item className="layersFilterCont" sm={12} lg={1.5}><DropDownCard className="dropDownFilter layersFilter" handleCarrierSelection={handleLayerSelection}  dropDownLabel="LAYERS" dropDownList={starLinkLayerList} minWidth={150} defaultValue={selectedLayer}/></Grid>}
            </Grid>
            <Grid item  lg={1} sm={12} className="vesselFilters">
                <Grid  className="dateAndInterval">
                    {/* {isDaterangePickerEnabled && <Grid item  className="dtConatiner"> <DateRangeTimePicker   handleStartDate={handleStartDate} handleEndDate={handleEndDate} defaultStartDate={defaultStartDate} defaultEndDate={defaultEndDate}/></Grid>} */}
                    {/* {isMultiSelectEnabled && <Grid item  sm={12}  lg={4}> <AutoComplete handleCarrierSelection={handleVesselSelection}  dropDownLabel="Vessels" dropDownList={multiSelectList} minWidth={240}/></Grid>} */}
                    {isTimeSelectionEnabled && <Grid item   sm={12} lg={12} > <DropDownCard handleCarrierSelection={handleInterval}  dropDownLabel="Interval" dropDownList={intervalList} defaultValue={interValValue}/></Grid> }
                    {/* {isLocatorEnabled && <MyLocationIcon className='myLocationIcon' />} */}
                </Grid>
            </Grid>
    </Grid >
    );
}

export default CustomAppBar;