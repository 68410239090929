import _ from "lodash";
import { api } from "../../server/request";
import { handleError, handleResponse } from "../../actions/responseUtil";
import { AccessNetwork, Action, Device, GeoConfig, User, WanProfile } from "./types";
import { toast } from "react-toastify";

export const ACTION_TYPES = {
    GET_GEO_CONFIGS: "geoConfigManagement/getGeoConfigs",
    CREATE_GEO_CONFIG: "geoConfigManagement/createGeoConfig",
    DELETE_GEO_CONFIG: "geoConfigManagement/deleteGeoConfig",
    UPDATE_GEO_CONFIG: "geoConfigManagement/updateGeoConfig",
    TOGGLE_GEO_CONFIG: "geoConfigManagement/toggleGeoConfig",
    SET_LOADING: "geoConfigManagement/setLoading",
    GET_TRAFFIC_POLICY_LIST_FROM_DEVICE_LIST: "geoConfigManagement/getDevicesTrafficPolicyList",
    GET_WAN_PROFILE_LIST_FROM_DEVICE_LIST: "geoConfigManagement/getDevicesWanProfileList",
    GET_ACCESS_NETWORK_LIST_FROM_DEVICE_LIST: "geoConfigManagement/getDevicesAccessNetworkList"
}

const initialState = {
    geoConfigs: [],
    totalGeoConfigs: 0,
    creating: false,
    deleting: false,
    updating: false,
    gettingGeoConfigs: false,
    trafficPolicies: [],
    wanProfles:[],
    accessNetworks:[]
}

export function geoConfigManagementReducer(state: any = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.GET_GEO_CONFIGS: {
            return {
                ...state,
                geoConfigs: action.payload?.data
            }
        }
        case ACTION_TYPES.UPDATE_GEO_CONFIG: {
            return {
                ...state,
                geoConfigs: state.geoConfigs.map((geoConfig: GeoConfig) => {
                    if (geoConfig.id === action.payload.id) {
                        return action.payload;
                    }
                    return geoConfig;
                })
            }
        }
        case ACTION_TYPES.DELETE_GEO_CONFIG: {
            return {
                ...state,
                geoConfigs: state.geoConfigs.filter((geoConfig: GeoConfig) => geoConfig.id !== action.payload.id)
            }
        }
        case ACTION_TYPES.TOGGLE_GEO_CONFIG: {
            return {
                ...state,
                geoConfigs: state.geoConfigs.map((geoConfig: GeoConfig) => {
                    if (geoConfig.id === action.payload.id) {
                        geoConfig.disabled = action.payload.disabled
                    }
                    return geoConfig;
                })
            }
        }
        case ACTION_TYPES.SET_LOADING: {
            return {
                ...state,
                ...action.payload
            }
        }
        case ACTION_TYPES.GET_TRAFFIC_POLICY_LIST_FROM_DEVICE_LIST: {
            let data = action.payload["data"];
            return {
                ...state,
                trafficPolicies: data.map((trafficPolicy)=> { return {id: trafficPolicy.id, name: trafficPolicy.name, common: trafficPolicy.common, device_id: trafficPolicy.device_id}})
            }
        }
        case ACTION_TYPES.GET_WAN_PROFILE_LIST_FROM_DEVICE_LIST: {
            let data = action.payload["data"];
            return {
                ...state,
                wanProfiles: data.map((wanProfile)=> { return {id: wanProfile.id, name: wanProfile.name, common: wanProfile.common, device_id: wanProfile.device_id}})
            }
        }
        case ACTION_TYPES.GET_ACCESS_NETWORK_LIST_FROM_DEVICE_LIST: {
            let data = action.payload["data"];
            return {
                ...state,
                accessNetworks: data.map((accessNetwork)=> { return {id: accessNetwork.id, name: accessNetwork.name, common: accessNetwork.common, device_id: accessNetwork.device_id}})
            }
        }
        default:
            return state
    }
}

export interface getGeoConfigsParameters {
    dpId: string;
    searchText: string;
    sortBy: string;
    sortOrder: string;
    limit: number;
    offset: number;
}

export const getGeoConfigs = (parameters: getGeoConfigsParameters) => (dispatch) => {
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingGeoConfigs: true } });
    let _parameters: any = { ...parameters };
    const data = {
        query: `GET_SC_GEO_CONFIG_RULES`,
        named: true,
        format: 'json',
        parameters: _parameters
    }
    api
        .postRequestOut(`/store/pg/query`, data)
        .then((res) => {
            handleResponse(res, dispatch, ACTION_TYPES.GET_GEO_CONFIGS)
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingGeoConfigs: false } });
        })
        .catch((err) => {
            handleError(err, dispatch);
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingGeoConfigs: false } });
        });
};

interface createGeoConfigParameters {
    dpId: string;
    geoConfig: GeoConfig;
}

export const createGeoConfig = (parameters: createGeoConfigParameters) => async (dispatch) => {
    const { dpId, geoConfig } = parameters;

    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { creating: true } });

    let URL = `/v1/dps/${dpId}/geo_config/create_rule`;
    try {
        let res = await api.siteController.post(URL, geoConfig);
        if (res && res?.success == true) {
            toast.success("Geo config created successfully", {
                position: toast.POSITION.BOTTOM_LEFT,
            });
        } else if (res && res.success == false) {
            toast.error(res.errors[0].message || "Failed to create geo config", {
                position: toast.POSITION.BOTTOM_LEFT,
            });
        }
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { creating: false } });
    } catch (error) {
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { creating: false } });
    }
};

export interface updateGeoConfigParameters {
    dpId: string;
    geoConfig: GeoConfig;
}

export const updateGeoConfig = (parameters: updateGeoConfigParameters) => async (dispatch) => {
    const { geoConfig, dpId } = parameters;
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { updating: true } });

    let URL = `/v1/dps/${dpId}/geo_config/${geoConfig.id}/update_rule`;
    try {
        let payload: any = {
            disable: false,
            ...geoConfig
        }
        let res = await api.siteController.put(URL, payload);
        if (res && res?.success == true) {
            toast.success("Geo config updated successfully", {
                position: toast.POSITION.BOTTOM_LEFT,
            });
        } else if (res && res.success == false) {
            toast.error(res.errors[0].message || "Failed to update geo config", {
                position: toast.POSITION.BOTTOM_LEFT,
            });
        }
        dispatch({ type: ACTION_TYPES.UPDATE_GEO_CONFIG, payload: geoConfig });
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { updating: false } });
    } catch (error) {
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { updating: false } });
    }
}

interface deleteGeoConfigParameters {
    dpId: string;
    geoConfig: GeoConfig;
}

export const deleteGeoConfig = (parameters: deleteGeoConfigParameters) => async (dispatch) => {
    const { dpId, geoConfig } = parameters;
    const { id } = geoConfig;
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { deleting: true } });

    let URL = `/v1/dps/${dpId}/geo_config/${id}/delete_rule`;
    try {
        let res = await api.siteController.delete(URL, { id: id });
        if (res && res?.success == true) {
            toast.success("Geo config deleted successfully", {
                position: toast.POSITION.BOTTOM_LEFT,
            });
        } else if (res && res.success == false) {
            toast.error(res.errors[0].message || "Failed to delete geo config", {
                position: toast.POSITION.BOTTOM_LEFT,
            });
        }
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { deleting: false } });
        if (res?.success) {
            dispatch({ type: ACTION_TYPES.DELETE_GEO_CONFIG, payload: geoConfig })
        }
    } catch (error) {
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { deleting: false } });
    }
};

interface toggleGeoConfigParameters {
    geoConfig: GeoConfig;
}

export const toggleGeoConfig = (parameters: toggleGeoConfigParameters) => async (dispatch) => {
    // const { geoConfig } = parameters;
    // const { site_id, device_id, id, disabled } = geoConfig;
    // dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { updating: true } });

    // let URL = `/client/v1/sites/${site_id}/devices/${device_id}/geo_configs/${id}`;
    // try {
    //     dispatch({ type: ACTION_TYPES.TOGGLE_GEO_CONFIG, payload: { id: id, disabled: !disabled } });
    //     let res = await api.siteController.put(URL, { disabled: !disabled });
    //     if (res && res?.success == true) {
    //         toast.success("Geo config updated successfully", {
    //             position: toast.POSITION.BOTTOM_LEFT,
    //         });
    //     } else if (res && res.success == false) {
    //         toast.error(res.errors[0].message || "Failed to update geo config", {
    //             position: toast.POSITION.BOTTOM_LEFT,
    //         });
    //     }
    //     dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { updating: false } });
    // } catch (error) {
    //     dispatch({ type: ACTION_TYPES.TOGGLE_GEO_CONFIG, payload: { id: id, disabled: disabled } });
    //     dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { updating: false } });
    // }
}

export const clearData = () => (dispatch) => {
    dispatch({
        type: ACTION_TYPES.GET_GEO_CONFIGS, payload: {
            data: {
                rows: [[null, null]]
            }
        }
    });
    dispatch({
        type: ACTION_TYPES.SET_LOADING, payload: {
            gettingGeoConfigs: false, gettingCurrentUsage: false,
            creating: false, deleting: false, updating: false
        }
    })
}

export const getTrafficPolicyDataFromDeviceList = (payload) => (dispatch) => {
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: true });
    api
      .postRequestOut(`/store/pg/query`, payload)
      .then((res) => {
        dispatch({ type:ACTION_TYPES.SET_LOADING, payload: false })
        return handleResponse(res, dispatch, ACTION_TYPES.GET_TRAFFIC_POLICY_LIST_FROM_DEVICE_LIST)
      })
      .catch((err) => handleError(err, dispatch));
}

export const getWanProfileDataFromDeviceList = (payload) => (dispatch) => {
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: true });
    api
      .postRequestOut(`/store/pg/query`, payload)
      .then((res) => {
        dispatch({ type:ACTION_TYPES.SET_LOADING, payload: false })
        return handleResponse(res, dispatch, ACTION_TYPES.GET_WAN_PROFILE_LIST_FROM_DEVICE_LIST)
      })
      .catch((err) => handleError(err, dispatch));
}

export const getAccessNetworkDataFromDeviceList = (payload) => (dispatch) => {
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: true });
    api
      .postRequestOut(`/store/pg/query`, payload)
      .then((res) => {
        dispatch({ type:ACTION_TYPES.SET_LOADING, payload: false })
        return handleResponse(res, dispatch, ACTION_TYPES.GET_ACCESS_NETWORK_LIST_FROM_DEVICE_LIST)
      })
      .catch((err) => handleError(err, dispatch));
}