import { useState, useEffect } from "react";
import MapLeaflet from "../../components/Map/MapLeafLet";
import '../../components/Map/Map.css';
import CustomAppBar from '../../components/AppBar';
import CustomSlider from '../../components/Slider'
import { connect, useDispatch } from 'react-redux';
import { fetchVesseVoyagePath, fetchCarrierData, fetchCarrierDataInnerLayer, fetchVesselCurrentLocation, fetchUniquecarrier, fetchSelectedVesseVoyagePath, fetchVsatCoverage, fetchVsatCoverageInnerLayer, fetchUniqueVsatcarrier, setAllMapErrors, fetchEdgeEvents } from "../../actions/fetchData";
import ChartDetails from './vesselChart';
import HexDetails from './hexRightPaneDetails';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import Paper from '@mui/material/Paper';
import './TowerVesselDetails.css';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Grid } from "@mui/material";
import moment from "moment";
import { getDecodeURI, getEncodedURI, getSingleRecordChartLable, getDateRangeLablePerVessel } from "../../util/util";
import { useHistory, useLocation } from "react-router-dom";
import Checkbox from '../../components/checkBox/checkBox';
import {beamNames,kmlNames} from '../../components/Map/BeamData';
import TowerJson from '../../components/Map/towers.json'
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ReactDOM from "react-dom";
import {cloneDeep} from "lodash";
import { SET_ENV } from "../../actions/actionType";
import { type } from "os";
import { getSelectedPathVesselVoyage, getVesselLastLocation, getVesselVoyage, getLTEOperators,getVSATOperators } from "../../actions/singleStoreActions";
import { carrierGeoJson250sqkm,carrierGeoJson5sqkm,StarLinkCoverageWithFour,StartlinkCoverageWithThree,vsatCoverage } from "../../actions/cdnAction";
import {fetchVesselKpiDataSuccess, fetchVesselDataRateSuccess, fetchVesselDataRTTSuccess, fetchVesselCurrentLocationSuccess} from "../../actions/action";

const TowerVesselDetails = (props: any) => {

    const { newSummaryStartDate, newSummaryEndDate, userTimezone, siteChartsSiteInterfacesLoading, siteChartsThroughputLoading, siteChartsLinkStatusLoading, siteChartsSiteDataRateLoading, siteChartsSINRLoading, siteChartsLatencyJitterLoading, siteChartsRSSILoading, vesselList, selectedVessel, selectedOu, userOu, handleApiLoading, voaygeLoading, k4Ids, handleLastLocSelectedVessel, vesselLastLocLoading, apiErrorHandling, appEnv,edgeEventsData,authorizedVesselList, carrierData, carrierInnerLayerData, vsatCoverageData,isSpecialPrrmEnabled, starLinkData, starLinkSpeedData, carriersListLoading, carrierDataLoading, carrierInnerLayerDataLoading, vsatCoverageDataLoading, starLinkDataLoading, starLinkSpeedDataLoading, speedHistoDataLoading, rssiHistoDataLoading, sinrHistoDataLoading, vsatCarrierListLoading, starLinkLatencyHistoDetailsLoading, starLinkSpeedHistoDetailsLoading, starLinkDropPercentageHistoDetailsLoading, selctedVoyageDataLoading, getEdgeEventsLoading, getEdgeEventsFilterLoading, getEdgeEventsChartsLoading  } = props;

    let history = useHistory();
    let location = useLocation();
    const [towerMap, setTowerMap] = useState<any>("");
    const [connectivity , setConnectivity] = useState([]);

    useEffect(() => {
        if(appEnv?.includes("dev")) {
            dispatch({type:SET_ENV,item:"DEV"})
        } else if(appEnv?.includes("stage")) {
            dispatch({type:SET_ENV,item:"STAGE"})
        } else if(appEnv?.includes("prod")) {
            dispatch({type:SET_ENV,item:"PROD"})
        } else if(appEnv?.includes("local")){
            dispatch({type:SET_ENV,item:"local"})
        }
    },[])

    useEffect(() => {
        let hashMap = new Map();
        if(TowerJson?.features) {
            TowerJson?.features.forEach((item, i) => {
                let jsonId: string = item?.properties?.id;
                hashMap.set(jsonId,item);
            })
            setTowerMap(hashMap);
        }
    },[])
   
    let vesseld = selectedVessel["id"];
    let vesselName = selectedVessel["vessel-name"];
    const [ouName, setOuName] = useState('');
    const dispatch = useDispatch();
    const [currentVessel, setCurrentVessel] = useState("");
    const [vesselStartTime, setVesselStartTime] = useState("");
    const [vesselEndTime, setVesselEndTime] = useState("");
    const [isPopUpOpen, setPopUpOpen] = useState(false);
    const [isHexPopUpOpen, setHexPopUpOpen] = useState(false);
    const [isChartClosed, setIsChartClosed] = useState(true);
    const [outerPopUpContent, setOuterPopUpContent] = useState<any>("Loading...");
    const [selectedCarrier, setSelectedCarrier] = useState("ALL");
    const [selectedLayer, setSelectedLayer] = useState("")
    const [popUpItem, setPopUpItem] = useState<any>(null);
    const [polygonColor, setPolygonColor] = useState('#0000ff');
    const [interval, setInterval] = useState('5m');
    const [popUpActiveButton, setPopUpActiveButton] = useState(0);
    //const [vesselList, setVesselList] = useState<string[]>([]);
    const [isLayersSelOpen, setLayersSelOpen] = useState(false);
    const [uniqueCarrierList, setUniqueCarrierList] = useState<any>([]);
    const [uniqueVsatCarrierList, setUniqueVsatCarrierList] = useState<any>([]);
    const [isOffLayer, setIsOffLayer] = useState("Turn off All Layers")
    const [vesselHoverContent, setVesselHoverContent] = useState<any>("Loading...");
    const [outerHexOpacity, setOuterHexOpacity] = useState(5);
    const [innerHexOpacity, setInnerHexOpacity] = useState(5);
    const [layerBtnx, setLayerBtnx] = useState(0);
    const [layerBtny, setLayerBtny] = useState(0);
    const [selectedLayersList, setSelectedLayersList] = useState<any>({ "Vessel Location": true, "Vessel Track": true,"Network":true,"Performance":false,"Vessel Voyage": true,"Events": false,  "250km": true, "5km": true, "SES": false, "Viasat": false });
    const [allPopupState, setAllPopupState] = useState({});
    const [hexContent, setHexContent] = useState(null);
    const [selctedPathStart, setSelectedPathStart] = useState("");
    const [slectedPathEnd, setSelectedPathEnd] = useState("");
    const [selectedH3, setSelectedH3] = useState("");
    const [isErrorPopUp, setIsErrorPopUp] = useState(false);
    const [isVsatDataSource, setVsatDataSource] = useState(false);
    const [uniqueContoursName, setUnicontoursName] = useState<any>(beamNames);
    const [uniqueKmlName, setUniqueKmlName] = useState<any>(kmlNames);
    const [ contorsOpacity , setContorsOpacity ] = useState(3);
    const [selectedDataSource, setSelectedDataSource] = useState("STARLINK");
    const [ vesselTrackTimeOut, setVesselTrackTimeout] = useState(true);
    const [isChartWindowEnabled, setIsChartWindowEnabled] = useState(false);
    const [hexResolution, setHexResolution] = useState(0);

    useEffect(() => {
        if(location) {
            let params = getDecodeURI(location?.search);
            processQueryUrl();
        }
       
    }, []);

    useEffect(() => {
        setPopUpOpen(false);
        setHexPopUpOpen(false);
        setSelectedCarrier("ALL")
        let params = getDecodeURI(location?.search);
        params.network = "ALL";
        doNavigate(params);
    }, [isVsatDataSource])

    useEffect(() => {
        setHexPopUpOpen(false);
    },[selectedDataSource])

    useEffect(() => {
        let params = getDecodeURI(location?.search);
        if( params.dataSource === "VSAT") {
            params.layer = "Call Performance"
            doNavigate(params);
            setVsatDataSource(true);
            setSelectedDataSource("VSAT");
            setSelectedCarrier("ALL");
            setSelectedLayer("Call Performance")
           
        } else if( params.dataSource === "STARLINK") {
            params.dataSource = "STARLINK";
            params.layer = "Latency"
            doNavigate(params);
            setVsatDataSource(false);
            setSelectedDataSource("STARLINK");
            setSelectedCarrier("NA");
            setSelectedLayer("Latency")
        }  else if(params.dataSource === "CELL") {
            params.dataSource = "CELL";
            params.layer = "Call Performance"
            doNavigate(params);
            setVsatDataSource(false);
            setSelectedDataSource("CELL");
            setSelectedCarrier("ALL");
            setSelectedLayer("Call Performance")
        } else {
            params.dataSource = "STARLINK";
            params.layer = "Latency"
            doNavigate(params);
            setVsatDataSource(false);
            setSelectedDataSource("STARLINK");
            setSelectedCarrier("NA");
            setSelectedLayer("Latency")
        }
        
    },[])

    const doNavigate = (params: any) => {
        history.push({ pathname: "/vessel-map", search: `?${getEncodedURI(params)}` });
    }

    let towerData = TowerJson?.features;

    useEffect(() => {
        if (Object.keys(apiErrorHandling).length > 0) {
            setIsErrorPopUp(true)
            // calll api here to send it to backend
            console.log("apiErrorHandling");
            console.log(JSON.stringify(apiErrorHandling));
            dispatch(setAllMapErrors(JSON.stringify(apiErrorHandling)));
            setTimeout(() => {
                setIsErrorPopUp(false);
            }, 4000);
        }

    }, [apiErrorHandling])

    
    const [endDate, setEndDate] = useState(moment().utc(false).toDate())
    const [startDate, setStartDate] = useState(moment().utc(false).subtract(3, 'days').toDate());

    let vesselNamesList: any = [];

    const handleStartDate = (date: any) => {
        // let updatedStartDate = new Date(date);
        setStartDate(date?.toISOString());
        let params = getDecodeURI(location?.search);
        params.startDate = moment(date).valueOf();
        params.interval = 'custom'
        doNavigate(params);
    }

    const handleEndDate = (date: any) => {
        // let updatedEndDate = new Date(date);
        setEndDate(date?.toISOString());
        let params = getDecodeURI(location?.search);
        params.endDate = moment(date).valueOf();
        params.interval = 'custom'
        doNavigate(params);
    }

    useEffect(() => {
        if (!isVsatDataSource) {
            dispatch(getLTEOperators());
        } else {
            dispatch(getVSATOperators());
        }
    }, [isVsatDataSource]);

    useEffect(() => {
        if (selectedDataSource === "CELL" && selectedLayer!== "") {
            dispatch(carrierGeoJson250sqkm("CELL",selectedCarrier.split(' ').join('_'), 5, selectedLayer.split(' ').join('_')));
            dispatch(carrierGeoJson5sqkm("CELL",selectedCarrier.split(' ').join('_'), 7, selectedLayer.split(' ').join('_')));
        } else if(selectedDataSource === "VSAT") {
            dispatch(vsatCoverage("VSAT",selectedCarrier.split(' ').join('_'), 5, selectedLayer.split(' ').join('_')));
        } else {
            if(selectedDataSource === "STARLINK" && selectedLayer !== "") {
                dispatch(StartlinkCoverageWithThree("Starlink",3,selectedLayer.split(' ').join('_')));
                dispatch(StarLinkCoverageWithFour("Starlink",4,selectedLayer.split(' ').join('_')));
                // dispatch(StartlinkSpeedCoverage());
            }
        }
    }, [selectedCarrier, selectedLayer, isVsatDataSource,selectedDataSource]);

    useEffect(() => {
        console.log("vesselLastLocLoading, voaygeLoading]", vesselLastLocLoading, voaygeLoading)
        handleApiLoading(vesselLastLocLoading || voaygeLoading || carriersListLoading || carrierDataLoading || carrierInnerLayerDataLoading || vsatCoverageDataLoading || starLinkDataLoading || starLinkSpeedDataLoading || speedHistoDataLoading ||
            rssiHistoDataLoading || sinrHistoDataLoading || vsatCarrierListLoading || starLinkLatencyHistoDetailsLoading || starLinkSpeedHistoDetailsLoading || starLinkDropPercentageHistoDetailsLoading || selctedVoyageDataLoading || siteChartsSiteInterfacesLoading ||
            siteChartsThroughputLoading || siteChartsLinkStatusLoading || siteChartsSiteDataRateLoading || siteChartsSINRLoading || siteChartsLatencyJitterLoading || siteChartsRSSILoading || getEdgeEventsLoading || getEdgeEventsFilterLoading || getEdgeEventsChartsLoading);
    }, [vesselLastLocLoading, voaygeLoading, carriersListLoading, carrierDataLoading, carrierInnerLayerDataLoading, vsatCoverageDataLoading, starLinkDataLoading, starLinkSpeedDataLoading, speedHistoDataLoading, rssiHistoDataLoading, sinrHistoDataLoading, vsatCarrierListLoading,
        starLinkLatencyHistoDetailsLoading, starLinkSpeedHistoDetailsLoading, starLinkDropPercentageHistoDetailsLoading, selctedVoyageDataLoading, siteChartsSiteInterfacesLoading, siteChartsThroughputLoading, siteChartsLinkStatusLoading, siteChartsSiteDataRateLoading, siteChartsSINRLoading,
        siteChartsLatencyJitterLoading, siteChartsRSSILoading, getEdgeEventsLoading, getEdgeEventsFilterLoading, getEdgeEventsChartsLoading]);

    // useEffect(() => {
    //     if(vesseld) {
    //         dispatch(fetchEdgeEvents(startDate,endDate,vesseld,"","","",""))
    //      }
    // },[vesseld])

    const handleInterval = (interval: any) => {
        isPopUpOpen && setPopUpOpen(false);
        setInterval(interval);
        // let params = getDecodeURI(location?.search);
        // params.interval = interval;
        // doNavigate(params);
    }

    const handleTowerOnhover = (props: any) => {
        return (
            <div>
                <div><b>Tower Details</b></div>
                <Table className="towerTable">
                    <TableRow className="towerTabRowHeader">
                        <TableCell align="left" className="towerTabHeader">Tech</TableCell>
                        <TableCell align="left" className="towerTabHeader">MCC</TableCell>
                        <TableCell align="left" className="towerTabHeader">MNC</TableCell>
                        <TableCell align="left" className="towerTabHeader">Cell ID</TableCell>
                        <TableCell align="left" className="towerTabHeader">LAC</TableCell>
                    </TableRow>
                    <TableRow className="towerTabValRow">
                        <TableCell align="left">{props?.tech?.includes("umts") ? "UMTS" : "CELL"}</TableCell>
                        <TableCell align="left">{props?.MCC}</TableCell>
                        <TableCell align="left">{props?.MNC}</TableCell>
                        <TableCell align="left">{props?.Cell}</TableCell>
                        <TableCell align="left">{props?.LAC}</TableCell>

                    </TableRow>
                </Table>
                
            </div>
        );
    }

    const getVesselTowerConectivity = (nearestTowerDetails:any) => {
        setConnectivity([]);
        if(nearestTowerDetails && nearestTowerDetails.length > 0) {
          let towerConnect =   nearestTowerDetails.map((vesselConnects:any) => {
                let startCord = vesselConnects?.cordinates;
                let TowerId = vesselConnects?.towerid;
                let towerObj = towerMap.get(TowerId);
                if(towerObj) {
                    return [ [`${startCord[0]}`,`${startCord[1]}`],[towerObj?.geometry?.coordinates[1],towerObj?.geometry?.coordinates[0]]]
                } 
                
            })
            setConnectivity(towerConnect);
        }
    }

    const handleVesselClick = (vesselStartTime: any, vesselEndTime: any, nearestTowerDetails: any, isWindowEnabled:boolean) => {
        Promise.resolve().then(() => {
            ReactDOM.unstable_batchedUpdates(() => {
                if(vesselStartTime && vesselEndTime) {
                    getVesselTowerConectivity(nearestTowerDetails)
                    setVesselStartTime(vesselStartTime);
                    setVesselEndTime(vesselEndTime);
                    setIsChartWindowEnabled(isWindowEnabled);
                }
            });
          });
    }

    const handleLastLocVesselClick = (selectedVessel: any) => {
        handleLastLocSelectedVessel(selectedVessel)
    }

    const handleVesselOnHover = (vesselStartTime: any, vesselEndTime: any, selectedVessel: any, lat: any, long: any, nearestTowerDetails:any, isEdge:any,item:any, isEdgeEve:any) => {
        let vesselPopUpContent: JSX.Element = !isEdge ? <>
            <div className="vesselHoverName">{selectedVessel}</div>
            <div>Start - {getSingleRecordChartLable(moment(vesselStartTime).valueOf(), userTimezone)}</div>
            <div>End - {getSingleRecordChartLable(moment(vesselEndTime).valueOf(), userTimezone)}</div>
            <div className='vesselHoverTab'>
                {nearestTowerDetails && nearestTowerDetails.length > 0 ? <Table className="towerTable">
                    <TableRow className="">
                    <TableCell align="left" className="towerTabHeader">Tech</TableCell>
                        <TableCell align="left" className="towerTabHeader">MCC</TableCell>
                        <TableCell align="left" className="towerTabHeader">MNC</TableCell>
                        <TableCell align="left" className="towerTabHeader">Cell ID</TableCell>
                    
                    </TableRow>
             
                 
                    {nearestTowerDetails && nearestTowerDetails.map((item:any) => {
                     return   <TableRow className="towerTabValRow">
                        <TableCell align="left">{item?.tech?.includes("umts") ? "UMTS" : "CELL"}</TableCell>
                        <TableCell align="left">{item?.MCC}</TableCell>
                        <TableCell align="left">{item?.MNC}</TableCell>
                        <TableCell align="left">{item?.cell_id}</TableCell>
                   

                    </TableRow>}) }
                </Table>: <div>No TowerData</div>}
                
            </div>
        </> :
            <> {isEdgeEve &&
                <>
                    <div className="vesselHoverName">{item?.name}</div>
                    <div>Time - {getSingleRecordChartLable(moment(vesselStartTime).valueOf(), userTimezone)}</div>
                </>
            }</>

        return vesselPopUpContent;
    }

    const handleCarrierSelection = (network: any) => {
        isHexPopUpOpen && setHexPopUpOpen(false);
        isLayersSelOpen && setLayersSelOpen(false);
        setSelectedCarrier(network);
        let params = getDecodeURI(location?.search);
        params.network = encodeURIComponent(network);
        doNavigate(params);
    }
    const handleLayerSelection = (selectedLyr: any) => {
        isHexPopUpOpen && setHexPopUpOpen(false);
        setSelectedLayer(selectedLyr);
        let params = getDecodeURI(location?.search);
        params.layer = selectedLyr;
        doNavigate(params);
    }

    const handleInnerHexogonClicks = (item: any) => {
        setPopUpItem(null);
        setPopUpItem(item);
    }


    // const towerData = props?.towerDetails?.getTowers;
    const vesselNames = props?.vesselData?.getVoyageVessels;
    let voyageDataDetails = props?.voyageData?.data?.data?.rows;
    const vesselData = props?.voyageData?.getVesselPath?.data;
    const selctedVesselData = props?.selctedVoyageData?.data?.data?.rows;
    // const carrierData = props?.carrierData?.geojson;
    // const carrierInnerLayer = props?.carrierInnerLayerData?.geojson;
    const vesselCurrLocation = props?.vesselCurrentLocationDetails;

    const uniqueCarrieList = props?.carriersList?.data?.data?.operator?.data;
    // const vsatCoverageData = props?.vsatCoverageData?.geojson;
    const vsatCovergaeInnerLayerData = props?.vsatCoverageInnerLayerData?.geojson;
    const uniqueVstCarrierList = props?.vsatCarrierList?.data?.data?.service_provider?.data;

    if (uniqueCarrierList.length === 0 && uniqueCarrieList !== undefined && uniqueCarrieList.length > 0) {
        let defaultList = ["ALL"];
        let uniqueList = uniqueCarrieList.sort();
        uniqueList = defaultList.concat(uniqueList);
        setUniqueCarrierList(uniqueList);
    }

    if (uniqueVsatCarrierList.length === 0 && uniqueVstCarrierList !== undefined && uniqueVstCarrierList.length > 0) {
        let defaultList = ["ALL"];
        let uniqueList = uniqueVstCarrierList.sort();
        uniqueList = defaultList.concat(uniqueList);
        setUniqueVsatCarrierList(uniqueList);
    }

    const vesselLocationDetails: string[] = vesselCurrLocation !== undefined && vesselCurrLocation.length > 0 &&
        vesselCurrLocation.map((item: any) => {
            if (item[2] === null || item[3] === null) {
                return;
            }
            let lastSeenTime = moment.utc(item[1], 'YYYY-MM-DD HH:mm:ss.SSS +-HHmm').toISOString();
            return { "long": item[3], "lat": item[2], "name": item[5], "heading": null, "locationId": item[0], "lastSeen": lastSeenTime, "status": item[7]}
        })

    const vesselPathDetails = voyageDataDetails !== undefined && voyageDataDetails.map((item: any, i: any) => {
        let cordinates:any = [];
        cordinates.push(item[2]);
        cordinates.push(item[3]);
        cordinates.push(item[4]);
    
        if (cordinates === undefined || cordinates[0] === null || cordinates[1] === null) {
        } else {
            return cordinates;
        }
    });

    const vesselPathPoints = vesselData !== undefined  && cloneDeep(vesselData).map((item: any, i: any) => {
        let cordinates:any = [];
        cordinates.push(item[2]);
        cordinates.push(item[3]);
        cordinates.push(item[4]);
        cordinates[2] =  Math.trunc(item?.linkbwe);
        if (cordinates === undefined || cordinates[0] === null || cordinates[1] === null) {
        } else {
            return cordinates;
        }
    });

    let selectedPathDetails = [];
    if (selctedPathStart !== "" && slectedPathEnd !== "") {
        selectedPathDetails =selctedVesselData !== undefined  && selctedVesselData.map((item: any, i: any) => {
            let cordinates:any = [];
            cordinates.push(item[2]);
            cordinates.push(item[3]);
            if (cordinates === undefined || cordinates[0] === null || cordinates[1] === null) {
            } else {
                return cordinates;
            }
        });
    }


    const vesselTrack = vesselPathDetails && vesselPathDetails.filter((item: any) => item !== undefined);
    let selectedTrack = [];
    if (selctedPathStart !== "" && slectedPathEnd !== "") {
        selectedTrack = selectedPathDetails && selectedPathDetails.filter((item: any) => item !== undefined);
    }

    const vesselTrackPointsData = vesselPathPoints && vesselPathPoints.filter((item: any) => item !== undefined);
    


    vesselNames !== undefined && vesselNames.map((vnames: any) => {
        vesselNamesList.push(vnames?.name);
    })
    // if (selectedVessel !== "" ) {
    //     setCurrentVessel(selectedVessel);
    // }
    // const handleVesselSelection = (props: any) => {
    //     if (props) {
    //         SetCurrentVessel(props);
    //         isPopUpOpen && setPopUpOpen(false);
    //     }
    // }

    // useEffect(() => {
    //     if (vesseld && startDate && endDate) {
    //         isPopUpOpen && setPopUpOpen(false);
    //         dispatch(fetchVesseVoyagePath(vesseld, startDate, endDate, interval));
    //     }
    // }, [vesseld, interval, startDate, endDate]);

    useEffect(() => {
        if (vesseld && newSummaryStartDate && newSummaryEndDate) {
            isPopUpOpen && setPopUpOpen(false);
            dispatch(fetchVesselKpiDataSuccess({}))
            dispatch(fetchVesselDataRateSuccess({}))
            dispatch(fetchVesselDataRTTSuccess({}))
            dispatch(getVesselVoyage(vesseld, newSummaryStartDate, newSummaryEndDate, interval))
        }
    },[vesseld, interval, newSummaryStartDate, newSummaryEndDate])

    useEffect(() => {
        setIsChartClosed(false);
    }, [vesseld]);

    const handleChartOnClose = () => {
        setSelectedPathEnd("");
        setSelectedPathStart("");
        isPopUpOpen && setPopUpOpen(false);
        setIsChartClosed(false);
    }

    const handleHexOnClose = () => {
        isHexPopUpOpen && setHexPopUpOpen(false);
    }

    const handleChartOpen = (value: boolean) => {
        setPopUpOpen(value);
        setIsChartClosed(value);
        isHexPopUpOpen && setHexPopUpOpen(false);
        isLayersSelOpen && setLayersSelOpen(false);
    }

    const handlePopUpButtonClick = (activeIndex: any) => {
        setPopUpActiveButton(activeIndex);
    }

    const Legend = (props: any) => {
        const { legendColor, legendText } = props;
        return (
            <>
                <Box
                    sx={{
                        width: 25,
                        height: 20,
                        marginTop: '2px',

                        backgroundColor: legendColor,

                    }}
                />

            </>
        )
    }

    useEffect(() => {
        let params = getDecodeURI(location.search);
        let incominOuName = params?.ouName ? params.ouName : '';
        setOuName(incominOuName)
        if (k4Ids && k4Ids.length > 0) {
            dispatch(getVesselLastLocation(k4Ids, incominOuName, [], authorizedVesselList));
        } else {
            dispatch(fetchVesselCurrentLocationSuccess([]))
        }
    }, [k4Ids]);

    useEffect(() => {
        setVesselTrackTimeout(true);
        setTimeout(() => {
            setVesselTrackTimeout(false)
        }, 12000);
        let vesseld = selectedVessel["id"];
        let params = getDecodeURI(location.search);
        let incominOuName = params?.ouName ? params.ouName : '';
        if (vesseld) {
            dispatch(getVesselLastLocation([vesseld], incominOuName, [], selectedVessel));
        } else if(incominOuName == ouName) {
            dispatch(getVesselLastLocation(k4Ids, incominOuName, [], authorizedVesselList));
        }
    },[selectedVessel])
    
    const handleLayerButton = () => {
        isLayersSelOpen ? setLayersSelOpen(false) : setLayersSelOpen(true);
    }

    const rssiLegendList = !isVsatDataSource ? ['-90', '-85', '-75', '-65', '-58', '-53', '-48', '-45 (RSSI in dBm)'] : ['-10', '-5', '0', '5', '10', '15', '20', '25(SINR avg dB)'];
    const rateLegendList = !isVsatDataSource ? ['1', '3', '8', '13', '25', '40', '50', '75 (Mbps)'] : ['0','15','30','45','60','75','90','100(speed in %)'];
    const LatencyList = ['60','80','120','140','200','300','400','500 (ms)'];
    const starLinkLossList = ['1', '3','7','15','30','50','70','90 (%)']
    const legendColorList = ['#EA3A24', '#FA9100', '#FFD11D', '#FFFFCC', '#B9E0A8', '#88ce7d', '#37b345', '#0183D7'];
    const starlinkColorList = ['#0183D7','#37b345','#88ce7d','#B9E0A8','#FFFFCC','#FFD11D','#FA9100','#EA3A24'];
    const handleLayerItemClick = (layerName: string) => {
        let LayerList = { ...selectedLayersList };
        LayerList[layerName] = !LayerList[layerName];
        setSelectedLayersList(LayerList);
    }

    const handleTurnOfAllLayers = () => {
        let LayerList = { ...selectedLayersList };
        if (isOffLayer === "Turn off All Layers") {
            Object.keys(LayerList).map((item) => {
                LayerList[item] = false;
                setIsOffLayer("Turn On All Layers");
            })
            setSelectedLayersList(LayerList);
        }
        if (isOffLayer === "Turn On All Layers") {
            Object.keys(LayerList).map((item) => {
                if(item === "SES") {
                    if(selectedDataSource === 'VSAT') {
                        LayerList[item] = true; 
                    } 
                } else {
                    LayerList[item] = true;
                    setIsOffLayer("Turn off All Layers");
                }
                

            })
            setSelectedLayersList(LayerList);
        }

    }

    const handleOuterHexSlideValue = (slideValue: any) => {
        setOuterHexOpacity(slideValue)
    }

    const handleInnerHexSlideValue = (slideValue: any) => {
        setInnerHexOpacity(slideValue);
    }


    useEffect(() => {
        let layers = document.getElementById("layersSel");
        let layerBtn = layers?.getBoundingClientRect();
        setLayerBtnx(layerBtn?.x ? layerBtn?.x - 370 : 0);
        setLayerBtny(layerBtn?.y ? layerBtn?.y - 50 : 0)

    }, [isLayersSelOpen, isPopUpOpen])

    useEffect(() => {
        let layers = document.getElementById("layersSel");
        let layerBtn = layers?.getBoundingClientRect();
        setLayerBtnx(layerBtn?.x ? layerBtn?.x - 370 : 0);
        setLayerBtny(layerBtn?.y ? layerBtn?.y - 76 : 0)

    }, [isLayersSelOpen, isHexPopUpOpen])

    const handleHexClick = (item: any, resolution: any) => {
        isLayersSelOpen && setLayersSelOpen(false);
        setHexResolution(resolution);
        setIsChartClosed(false);
        setPopUpOpen(false);
        setHexPopUpOpen(true);
        setHexContent(item);
    }

    const handleSelctedTrack = (start: any, end: any) => {
        setSelectedPathStart(start);
        setSelectedPathEnd(end);
    }

    useEffect(() => {
        if (vesseld && selctedPathStart !== "" && selctedPathStart !== undefined && slectedPathEnd !== "" && slectedPathEnd !== undefined) {
            dispatch(getSelectedPathVesselVoyage(vesseld, selctedPathStart, slectedPathEnd, interval));
        } else {

        }
    }, [vesseld, selctedPathStart, slectedPathEnd, interval]);

    const h3Index = (h3Index: any) => {
        setSelectedH3(h3Index);
    }

    const getLgValue = (isHex: any, isPop: any) => {
        if (isHex) {
            return 7;
        } else if (isPop) {
            return 4;
        } else {
            return 12
        }
    }
    const handleVsat = (isVsat: any,source: string) => {
        if(source === "STARLINK") {
            let params = getDecodeURI(location?.search);
            params.dataSource = "STARLINK";
            params.layer= "Latency"
            doNavigate(params);
            setSelectedDataSource("STARLINK")
            setSelectedLayer("Latency")
            setSelectedCarrier("NA");
            return 
        }
        setVsatDataSource(isVsat);
        setSelectedCarrier("ALL");
        if(isVsat) {
            let params = getDecodeURI(location?.search);
            params.dataSource = "VSAT";
            params.layer= "Call Performance"
            doNavigate(params);
            setSelectedDataSource("VSAT")
            setSelectedLayer("Call Performance")
        } else {
            let params = getDecodeURI(location?.search);
            params.dataSource = "CELL";
            params.layer= "Call Performance"
            doNavigate(params);
            setSelectedDataSource("CELL")
            setSelectedLayer("Call Performance")
        }
    }

    const handleContorList = (item:any) => {
        let contorsList = {...uniqueContoursName};
        contorsList[item] = !contorsList[item];
        setUnicontoursName(contorsList);
    }

    const handleKmlList = (item:any) => {
        let kmlList = {...uniqueKmlName};
        kmlList[item] = !kmlList[item];
        setUniqueKmlName(kmlList);
    }

    const handleAllContors = (props:any) => {
        setUnicontoursName(props);
    }

    const handleAllKmlList = (props:any) => {
        setUniqueKmlName(props);
    }

    const handleContorsOpacityVal = (opacity:any) => {
        setContorsOpacity(opacity)
    }
    const handleKmlOpacity = (opacity:any) => {

    }

    const processQueryUrl = () => {
        let _q = getDecodeURI(location?.search);
        setSelectedDataSource(_q.dataSource && '' !== _q.dataSource.trim() ? _q.dataSource : "STARLINK" );
        setInterval('5m');
        selectedDataSource !== "STARLINK" ? setSelectedLayer(_q.layer && '' !==_q.layer.trim() ? _q.layer :"Call Performance"): setSelectedLayer(_q.layer && '' !==_q.layer.trim() ? _q.layer :"Latency");
        setSelectedCarrier(_q.network && '' !==_q.network.trim() ? decodeURIComponent(_q.network) : "ALL");
        let incominStarDate =(_q.startDate && '' !== _q.startDate  && _q.startDate > 1) ? (new Date(parseInt(_q.startDate))): null;
        let incominEndDate =(_q.endDate && '' !== _q.endDate  && _q.endDate > 1) ? (new Date(parseInt(_q.endDate))): null;
        incominStarDate && setStartDate(incominStarDate);
        incominEndDate && setEndDate(incominEndDate);
        if(selectedDataSource === "STARLINK") {
            setSelectedCarrier("NA");
        }
    }

    const getStarLinkColorandlegeds = (footerKey: string) => {
        switch (footerKey) {
            case "Drop Percentage": return  <>
            <div className='mapLegends'>
                {legendColorList?.map((item, i) => {
                    return <Legend className="coloredLegendBox" legendColor={starlinkColorList[i]} />;
                })
                }
            </div>
            <div className="startLegendLabels">
                {starLinkLossList?.map((item) => {
                    return <div className="legendLabel">{item}</div>
                })
                }
            </div>
        </>;
            case "Speed": return <>
            <div className='mapLegends'>
                {starlinkColorList?.map((item, i) => {
                    return <Legend className="coloredLegendBox" legendColor={legendColorList[i]} />;
                })
                }
            </div>
            <div className="startLegendLabelsSpeed">
                {rateLegendList?.map((item) => {
                    return <div className="legendLabel">{item}</div>
                })
                }
            </div>
        </>;
            default: return <>
                <div className='mapLegends'>
                    {legendColorList?.map((item, i) => {
                        return <Legend className="coloredLegendBox" legendColor={starlinkColorList[i]} />;
                    })
                    }
                </div>
                <div className="startLegendLabels">
                    {LatencyList?.map((item) => {
                        return <div className="legendLabel">{item}</div>
                    })
                    }
                </div>
            </>;
        }
    }


    return (
        <Grid item container lg={12} className="coverageMapContainer" >
            {<CustomAppBar className="customHeaderBar"
                isFiltersEnabled={true}
                isDaterangePickerEnabled={true}
                defaultStartDate={newSummaryStartDate}
                defaultEndDate={newSummaryEndDate}
                handleStartDate={handleStartDate}
                handleEndDate={handleEndDate}
                isMultiSelectEnabled={true}
                multiSelectList={vesselNamesList}
                isTimeSelectionEnabled={true}
                handleInterval={handleInterval}
                handleCarrierSelection={handleCarrierSelection}
                handleLayerSelection={handleLayerSelection}
                carrierList={!isVsatDataSource ? uniqueCarrierList : uniqueVsatCarrierList}
                handleVsat={handleVsat}
                selctedDefaulcarrier = {selectedCarrier}
                interValValue= {interval}
                selectedLayer={selectedLayer}
                selectedDataSource={selectedDataSource}
                userTimezone={userTimezone}
            />
            }
            {/* <Drawyer /> */}

            {isLayersSelOpen && < div className="layersSelectection" style={{ top: layerBtny, left: layerBtnx }}>
                <div className="layerSub">Map Layers</div>
                <div className="layerSubHead">Vessels</div>
                {Object.keys(selectedLayersList).map((item, i) => {
                       
                        if ( i === 2 || i === 3 || i === 4 || i === 5) {
                        
                            //onClick={() => handleLayerItemClick(item)}
                        return ( selectedLayersList['Vessel Track'] && <div><ListItem  onClick={() => handleLayerItemClick(item)} >
                        <ListItemIcon sx={{ pl: 4 }} className="visibilityIcon">
                            {selectedLayersList[item] ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </ListItemIcon>
                        <ListItemText  className="layerSelLabel" primary={item} />
                    </ListItem>
                  
                    </div>);
                    } 
                //     if ( i === 4 || i === 5 ) {
                //         return
                //         //onClick={() => handleLayerItemClick(item)}
                // //     return ( <div><ListItem  onClick={() => handleLayerItemClick(item)} >
                // //     <ListItemIcon sx={i === 5 ?{pl: 4}:{}} className="visibilityIcon">
                // //         {selectedLayersList[item] ? <VisibilityIcon /> : <VisibilityOffIcon />}
                // //     </ListItemIcon>
                // //     <ListItemText  className="layerSelLabel" primary={item} />
                // // </ListItem>
              
                // // </div>);
                // } 
                    if (i === 6 ) {
                        if(selectedDataSource === "STARLINK") {
                            return !isVsatDataSource ? (<><div className="layerSubHead">STARLINK</div><ListItem onClick={() => handleLayerItemClick(item)} >
                            <ListItemIcon className="visibilityIcon">
                                {selectedLayersList[item] ? <VisibilityIcon type="button" /> : <VisibilityOffIcon />}
                            </ListItemIcon>
                            <ListItemText className="layerSelLabel">GeoFence 12393km<sup>2</sup></ListItemText>
                        </ListItem>
                            <ListItem className="customSlider">
                                <CustomSlider handleSlideValue={handleOuterHexSlideValue} defaultValue={outerHexOpacity}></CustomSlider></ListItem></>
                        ): null;
                        }
                        
                        return !isVsatDataSource ? (<><div className="layerSubHead">CELL</div><ListItem onClick={() => handleLayerItemClick(item)} >
                            <ListItemIcon className="visibilityIcon">
                                {selectedLayersList[item] ? <VisibilityIcon type="button" /> : <VisibilityOffIcon />}
                            </ListItemIcon>
                            <ListItemText className="layerSelLabel">GeoFence {item}<sup>2</sup></ListItemText>
                        </ListItem>
                            <ListItem className="customSlider">
                                <CustomSlider handleSlideValue={handleOuterHexSlideValue} defaultValue={outerHexOpacity}></CustomSlider></ListItem></>
                        ): null;

                    } 
                    if (i === 7 ) {
                        if(selectedDataSource === "STARLINK") { 
                            return  !isVsatDataSource ? (<><ListItem onClick={() => handleLayerItemClick(item)} >
                            <ListItemIcon className="visibilityIcon">
                                {selectedLayersList[item] ? <VisibilityIcon type="button" /> : <VisibilityOffIcon />}
                            </ListItemIcon>
                            <ListItemText className="layerSelLabel geofence">GeoFence 1770km<sup>2</sup></ListItemText>
                        </ListItem>
                            <ListItem className="customSlider">
                                <CustomSlider handleSlideValue={handleInnerHexSlideValue} defaultValue={innerHexOpacity}></CustomSlider></ListItem></>
                        ): null;
                        
                        }
                        return  !isVsatDataSource ? (<><ListItem onClick={() => handleLayerItemClick(item)} >
                            <ListItemIcon className="visibilityIcon">
                                {selectedLayersList[item] ? <VisibilityIcon type="button" /> : <VisibilityOffIcon />}
                            </ListItemIcon>
                            <ListItemText className="layerSelLabel geofence">GeoFence {item}<sup>2</sup></ListItemText>
                        </ListItem>
                            <ListItem className="customSlider">
                                <CustomSlider handleSlideValue={handleInnerHexSlideValue} defaultValue={innerHexOpacity}></CustomSlider></ListItem></>
                        ): null;
                    }
                    if(i === 8 ) {
                        return isVsatDataSource?<><div className="layerSubHead">VSAT</div><ListItem onClick={() => handleLayerItemClick(item)} >
                        <ListItemIcon className="visibilityIcon">
                            {selectedLayersList[item] ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </ListItemIcon>
                        <ListItemText className="layerSelLabel" primary={item} />
                    </ListItem>
                    {selectedLayersList["SES"] && selectedDataSource === "VSAT" && <Checkbox uniqueContoursName={uniqueContoursName} handleContorsOpacityVal={handleContorsOpacityVal} handleContorlist={handleContorList} handleAllContors={handleAllContors} isCustomSliderEnabled={true}/> }
                    </>: null
                    }
                    if(i === 9 ) {
                        return isVsatDataSource && isSpecialPrrmEnabled ?<><ListItem onClick={() => handleLayerItemClick(item)} >
                        <ListItemIcon className="visibilityIcon">
                            {selectedLayersList[item] ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </ListItemIcon>
                        <ListItemText className="layerSelLabel" primary={item} />
                    </ListItem>
                    {selectedLayersList["Viasat"] && selectedDataSource === "VSAT" && <Checkbox uniqueContoursName={uniqueKmlName}  handleContorlist={handleKmlList} handleContorsOpacityVal={handleKmlOpacity} handleAllContors={handleAllKmlList} isCustomSliderEnabled={false}/> }
                    </>: null
                    }
                    return (<ListItem onClick={() => handleLayerItemClick(item)} >
                        <ListItemIcon className="visibilityIcon">
                            {selectedLayersList[item] ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </ListItemIcon>
                        <ListItemText className="layerSelLabel" primary={item} />
                    </ListItem>);
                })}
                <div className="allLayerLink" onClick={() => handleTurnOfAllLayers()}>{isOffLayer}</div>

            </div>}
            <Grid item container lg={12} className="mapChartContainer">
                <Grid className="mapsBox" item container lg={getLgValue(isHexPopUpOpen, isPopUpOpen)} >
                    <MapLeaflet
                        polyColor={polygonColor}
                        isVsatLteEnabled={false}
                        isVesselEnabled={selectedLayersList["Vessel Voyage"]}
                        isTowerEnabled={selectedLayersList["Towers"]}
                        isLineEnabled={false}
                        towerData={towerData}
                        handleTowerOnhover={handleTowerOnhover}
                        handleVesselClick={handleVesselClick}
                        handleVesselOnHover={handleVesselOnHover}
                        isPlayBackEnabled={false}
                        vesselData={voyageDataDetails}
                        vesselTrack={vesselTrack}
                        selectedTrack={selectedPathDetails}
                        isVesselTrackEnabled={selectedLayersList["Vessel Track"]}
                        // lineData={lineData}
                        selectedvessels={vesselName}
                        selctedVesselId={vesseld}
                        handleChartOpen={handleChartOpen}
                        isChartClosed={isChartClosed}
                        isInnerhexClick={true}
                        handleInnerHexogonClicks={handleInnerHexogonClicks}
                        isOuterHex={selectedLayersList["250km"]}
                        handleOuterHexClick={handleInnerHexogonClicks}
                        outerHexData={carrierData}
                        innerHexData={carrierInnerLayerData}
                        isInnerHex={selectedLayersList["5km"]}
                        outerPopUpContent={outerPopUpContent}
                        vesselCurrentLocation={vesselLocationDetails}
                        isAllVesselLocation={selectedLayersList["Vessel Location"]}
                        selectedLayer={selectedLayer}
                        vesselHoverContent={vesselHoverContent}
                        isMultiIcons={false}
                        mapHeight="90vh"
                        outerHexOpacity={outerHexOpacity}
                        innerHexOpacity={innerHexOpacity}
                        isPopUpOpen={isPopUpOpen}
                        handleLayerButton={handleLayerButton}
                        handleHexClick={handleHexClick}
                        selectedCarrier={selectedCarrier}
                        h3Index={h3Index}
                        isVesselLastLocClickEnabled={true}
                        handleLastLocVesselClick={handleLastLocVesselClick}
                        isVsatDataSource={isVsatDataSource}
                        vsatCoverageData={vsatCoverageData}
                        vsatCovergaeInnerLayerData={vsatCovergaeInnerLayerData}
                        isBeamsEnabled={selectedLayersList["SES"]}
                        uniqueContoursName={uniqueContoursName}
                        contorsOpacity={contorsOpacity}
                        selectedDataSource={selectedDataSource}
                        connectivity={connectivity}
                        vesselTrackPointsData={vesselTrackPointsData}
                        vesselPointsEnabled={selectedLayersList['Performance']}
                        isVesselNetworkEnabled={selectedLayersList['Network']}
                        isEdgeEventsEnabled={selectedLayersList['Events']}
                        edgeEventsData={edgeEventsData?.getEdgeEvents}
                        defaultStartDate={newSummaryStartDate}
                        defaultEndDate={newSummaryEndDate}
                        isKmlEnabled={selectedLayersList["Viasat"]}
                        kmlLayersDetails={uniqueKmlName}
                        isSpecialPrrmEnabled={isSpecialPrrmEnabled}
                        starLinkData={starLinkData}
                        starLinkCoverageFour={starLinkSpeedData}
                        userTimezone={userTimezone}
                    />

                </Grid>
                {isPopUpOpen &&
                    <Grid className="rightContainer" item container lg={8}>
                        <ChartDetails
                            vesselStartTime={newSummaryStartDate}
                            vesselEndTime={newSummaryEndDate}
                            hoveredStart={vesselStartTime}
                            hoveredEnd={vesselEndTime}
                            selctedVessel={vesseld}
                            vesselName={vesselName}
                            handleChartOnClose={handleChartOnClose}
                            handleSelctedTrack={handleSelctedTrack}
                            isWindowEnabled={isChartWindowEnabled} />

                    </Grid>
                }

                {isHexPopUpOpen &&
                    <Grid className="rightContainer" item container lg={5}>
                        <HexDetails
                            handleChartOnClose={handleHexOnClose}
                            hexContent={hexContent}
                            selectedCarrier={selectedCarrier}
                            selctedIndex={selectedH3}
                            isVsatDataSource={isVsatDataSource}
                            selectedDataSource={selectedDataSource}
                            hexResolution={hexResolution}
                            selectedLayer={selectedLayer}
                        />
                    </Grid>
                }

            </Grid>
            {vesselName && (vesselPathDetails === false) && !voaygeLoading && vesselTrackTimeOut &&  <div className="errorCardForTrack">
                <span className="errorWord">{`No voyage data available for Vessel - ${vesselName} in this interval ${getDateRangeLablePerVessel(moment(newSummaryStartDate).valueOf(), userTimezone)} - ${getDateRangeLablePerVessel(moment(newSummaryEndDate).valueOf(), userTimezone)} `}</span> 
            </div>
            }
            {Object.keys(apiErrorHandling).length > 0 && isErrorPopUp && <div className="errorCard">
                <span className="errorWord">Uh - oh!.</span> Map hits an unexpected problem while trying to service your request.
                Please try again after some time. We are looking to the issue.
            </div>
            }
            {<Box className="legendContainer">
                {/* <CssBaseline /> */}
                <Paper className='legendCard'
                    sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
                    elevation={3}
                >
                    <BottomNavigation className="footerContent">
                    {selectedDataSource !== "STARLINK" ? <>
                        <div className='mapLegends'>
                            {(selectedLayer === 'Call Performance') ? rssiLegendList?.map((item, i) => {
                                return <Legend className="coloredLegendBox" legendColor={legendColorList[i]} />;
                            }) :
                                rateLegendList?.map((item, i) => {
                                    return <Legend className="coloredLegendBox" legendColor={legendColorList[i]} />;
                                })
                            }

                        </div>
                        <div className="legendLabels">
                            {(selectedLayer === 'Call Performance') ? rssiLegendList?.map((item) => {
                                return <div className="legendLabel">{item}</div>
                            }) :
                                rateLegendList?.map((item, i) => {
                                    return <div className="legendLabel">{item}</div>
                                })
                            }
                        </div>
                       </>: 
                            getStarLinkColorandlegeds(selectedLayer)
                       }
                    </BottomNavigation>
                    <BottomNavigation className="vesselTrackIndCont">
                        <div className="vesselTrackHead">Vessel Track</div>
                        <div className="vesselTrackInd">
                            <span className="dotLegend" style={{background:"#00FF00"}}></span>
                            <span className="legendLabel">CELL</span>
                            <span className="dotLegend" style={{background:"#0000FF"}}></span>
                            <span className="legendLabel">VSAT</span>
                            <span className="dotLegend" style={{background:"#ff9900"}}></span>
                            <span className="legendLabel">Starlink</span>
                            <span className="dotLegend" style={{background:"#A020F0"}}></span>
                            <span className="legendLabel">Multiple</span>
                            <span className="dotLegend" style={{background:"#FF0000"}}></span>
                            <span className="legendLabel">No Network</span>
                            <span className="dotLegend" style={{background:"#000000"}}></span>
                            <span className="legendLabel">No Data</span>
                            <span className="dotLegend" style={{background:"#00FFFF"}}></span>
                            <span className="legendLabel">Selected Track</span>
                        
                        </div>
                    </BottomNavigation>
                </Paper>
            </Box>}


        </Grid>
    );
}

const mapStateToProps = (state: any) => ({
    newSummaryStartDate: state.authReducer.newSummaryStartDate,
    newSummaryEndDate: state.authReducer.newSummaryEndDate,
    towerDetails: state?.products?.data,
    vesselData: state?.products?.vesselData,
    voyageData: state?.products?.voyageData,
    selctedVoyageData: state?.products?.selctedVoyageData,
    kpiData: state?.products?.kpiData,
    carrierData: state?.products?.carrierData,
    carrierInnerLayerData: state?.products?.carrierInnerLayerData,
    vesselCurrentLocationDetails: state?.products?.vesselCurrentLocationDetails,
    carriersList: state?.products?.carriersList,
    voaygeLoading: state?.products?.voaygeLoading,
    vesselLastLocLoading: state?.products?.vesselLastLocLoading,
    apiErrorHandling: state?.products?.apiErrorHandling,
    vsatCoverageData: state?.products?.vsatCoverageData,
    vsatCoverageInnerLayerData: state?.products?.vsatCoverageInnerLayerData,
    edgeEventsData: state?.products?.edgeEventsData,
    vsatCarrierList: state?.products?.vsatCarrierList,
    starLinkData: state?.products?.starLinkData,
    starLinkSpeedData: state?.products?.starLinkSpeedData,
    carriersListLoading: state?.products?.carriersListLoading,
    carrierDataLoading: state?.products?.carrierDataLoading,
    carrierInnerLayerDataLoading: state?.products?.carrierInnerLayerDataLoading,
    vsatCoverageDataLoading: state?.products?.vsatCoverageDataLoading,
    starLinkDataLoading: state?.products?.starLinkDataLoading,
    starLinkSpeedDataLoading: state?.products?.starLinkSpeedDataLoading,
    speedHistoDataLoading: state?.products?.speedHistoDataLoading,
    rssiHistoDataLoading: state?.products?.rssiHistoDataLoading,
    sinrHistoDataLoading: state?.products?.sinrHistoDataLoading,
    vsatCarrierListLoading: state?.products?.vsatCarrierListLoading,
    starLinkLatencyHistoDetailsLoading: state?.products?.starLinkLatencyHistoDetailsLoading,
    starLinkSpeedHistoDetailsLoading: state?.products?.starLinkSpeedHistoDetailsLoading,
    starLinkDropPercentageHistoDetailsLoading: state?.products?.starLinkDropPercentageHistoDetailsLoading,
    selctedVoyageDataLoading: state?.products?.selctedVoyageDataLoading,

    siteChartsSiteInterfacesLoading: state?.authReducer?.siteChartsSiteInterfacesLoading,
    siteChartsThroughputLoading: state?.authReducer?.siteChartsThroughputLoading,
    siteChartsLinkStatusLoading: state?.authReducer?.siteChartsLinkStatusLoading,
    siteChartsSiteDataRateLoading: state?.authReducer?.siteChartsSiteDataRateLoading,
    siteChartsSINRLoading: state?.authReducer?.siteChartsSINRLoading,
    siteChartsLatencyJitterLoading: state?.authReducer?.siteChartsLatencyJitterLoading,
    siteChartsRSSILoading: state?.authReducer?.siteChartsRSSILoading,
    getEdgeEventsLoading: state?.authReducer?.getEdgeEventsLoading,
    getEdgeEventsFilterLoading: state?.authReducer?.getEdgeEventsFilterLoading,
    getEdgeEventsChartsLoading: state?.authReducer?.loading
});

const TowerVesselDetailsWithData = connect(
    mapStateToProps,
)(TowerVesselDetails);

export default TowerVesselDetailsWithData;
