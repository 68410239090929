import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import './MapchartStyle.css';
import BasicSelct from '../../components/smallDropdown'

const getOptions = (props:any) =>  {

    const {chartData,titleAllignment, catogoriesData} = props;
    const { categoriesName,chartTitleText,seriesName} = props?.options;
return {
  chart: {
    type: "column"
  },
  xAxis: {
    name: categoriesName,
    categories: catogoriesData
  },
  yAxis: {
      title:false,
  },
  title: {
    text: chartTitleText,
    align: titleAllignment
  },
  plotOptions: {
    column: {
      pointPadding: 0,
      borderWidth: 0,
      groupPadding: 0,
      shadow: true,
    
    }
  },
  credits: {
    enabled: false
  },
  series: [
    {
      name: seriesName,
      data: chartData
    }
  ]
}
};


const Histogram = ((props:any) => {
  const {chartType, handleHistoInterval , isIntevalEnaled} = props;
    return (
  <div className="histogramChart">
    {isIntevalEnaled && <div className="resetButtonContainer"><div className="chartReset interval" ><BasicSelct chartType={chartType} handleHistoInterval={handleHistoInterval}/></div></div>}
    <HighchartsReact highcharts={Highcharts} options={getOptions(props)} />
  </div>);
});

export default Histogram;

